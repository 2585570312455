import { disruptionsClassifier } from 'shared';

export const mapConfig = {
	attribution:
		'&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
	center: [48.7835562, 9.1814453],
	zoom: 17,
	minZoom: 12,
	maxZoom: 18,
	requireCtrl: false,
	// requireCtrl: 1280,
	minZoomLevel: {
		pois: 4,
		positions: 2,
		sharing: 0,
		stops: 2,
		tipps: 0,
		parkAndRide: 0,
		cityticket: 10.6,
		disruptions: 12,
	},
};

export const colors = {
	orange: '#fc631e', // 252, 99, 30

	white: '#fff',
	black: '#000',

	grayblue: '#98AAB3',
	graydark: '#6C7A80',

	graylight: '#e6e6e6', // 230, 230, 230
	graysuperlight: '#f6f6f6',

	cyandark: '#097fac', // 9, 127, 172
	purple: '#8269ad', // 130, 105, 173
	violetred: '#c24688', // 194, 70, 136
	green: '#008484', // 0, 132, 132
	red: '#a61728', //#a61728
	danger: '#CE281F',

	bus: '#C31924',
	rbahn: '#8F908F',
	sbahn: '#53B330',
	ubahn: '#0BA1E2',

	disruptionBlue: '#007aff',
	disruptionRed: '#c33',
};

export const fonts = {
	default: 'Lineto circular book, Helvetica Neue, Helvetica, Arial, sans-serif',
	bold: 'Lineto circular bold, Helvetica Neue, Helvetica, Arial, sans-serif',
	black: 'Lineto circular black, Helvetica Neue, Helvetica, Arial, sans-serif',

	weightdefault: 400,
	weightbold: 700,
	weightblack: 400,
};

export const boxshadow = {
	default:
		'0px 4px 1.6px -2px rgba(0, 0, 0, 0.04), 0px -4px 12px -2px rgba(0, 0, 0, 0.06), 0px 4px 6px -2px rgba(0, 0, 0, 0.08), 0px 6px 12px -2px rgba(0, 0, 0, 0.4)',

	tooltip: '0 2px 10px rgba(0, 0, 0, 0.25)',
	categorymenu: '0 6px 10px rgba(0, 0, 0, 0.25)',
	menutileicon: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
	strong: `
		0 2px 4px 0 rgba(0, 0, 0, 0.3),
		0 2px 8px 0 rgba(0, 0, 0, 0.15),
		0 2px 16px 0 rgba(0, 0, 0, 0.1)`,

	hover:
		'0px 4px 3px 0px rgba(0, 0, 0, 0.04), 0px -4px 16px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.08), 0px 12px 16px 0px rgba(0, 0, 0, 0.04)',
	focus:
		'0px 4px 5px 0px rgba(0, 0, 0, 0.06), 0px -4px 20px 0px rgba(0, 0, 0, 0.08), 0px 4px 16px 0px rgba(0, 0, 0, 0.1), 0px 12px 24px 0px rgba(0, 0, 0, 0.07)',
};

export const searchCategories = {
	stops: {
		label: 'Öffentlicher Nahverkehr / P+R',
		customLabels: [
			'Haltestellen',
			'Haltestellen und Bahnhöfe',
			'ÖPNV',
			'Busse & Bahnen',
			'Haltestellen und Park & Ride',
		],
		subTypes: ['stops-rbahn', 'stops-sbahn', 'stops-stadtbahn', 'stops-bus'],
		filter: {
			stops: {
				Haltestellen: 2,
				Linien: 2,
				// 'R-Bahn': 2,
				// 'S-Bahn': 2,
				// Stadtbahn: 2,
				// Bus: 2,
				'R-Bahn': 2, // 0
				'S-Bahn': 2, // 1
				Stadtbahn: 2, // 3
				Bus: 2, // 5
			},
			parkAndRide: {
				'Park & Ride': 2,
			},
			filterType: 'stops',
			markerType: 'stop',
		},
		icon: 'stops',
	},
	'stops-rbahn': {
		filter: {
			stops: {
				'R-Bahn': 2,
			},
		},
	},
	'stops-sbahn': {
		filter: {
			stops: {
				'S-Bahn': 2,
			},
		},
	},
	'stops-stadtbahn': {
		filter: {
			stops: {
				Stadtbahn: 2,
			},
		},
	},
	'stops-bus': {
		filter: {
			stops: {
				Bus: 2,
			},
		},
	},
	sharing: {
		label: 'Sharing-Angebote',
		customLabels: ['Bike- & Carsharing', 'Car- & Bikesharing'],
		subTypes: [],
		filter: {
			sharing: {},
			filterType: 'sharing',
			markerType: 'sharing',
		},
		icon: 'sharing',
	},
	tipps: {
		label: 'Ausflugstipps',
		filter: {
			tipps: {
				Ausflugstipps: 2,
			},
			filterType: 'tipps',
			markerType: 'tip',
		},
		icon: 'tipps',
	},
	pois: {
		label: 'Interessante Orte',
		customLabels: ['POIs'],
		subTypes: [],
		filter: {
			pois: {},
			filterType: 'pois',
			markerType: 'poi',
		},
		icon: 'pois',
	},
	cityticket: {
		label: 'StadtTicket',
		subTypes: [],
		filter: {
			cityticket: {
				StadtTicket: 2,
			},
			filterType: 'cityticket',
			markerType: 'city',
		},
		icon: 'citytickets',
	},
	parkAndRide: {
		label: 'Park & Ride',
		customLabels: ['Park and Ride'],
		subTypes: [],
		filter: {
			parkAndRide: {
				'Park & Ride': 2,
			},
			filterType: 'parkAndRide',
			markerType: 'pr',
		},
		icon: 'parkride',
	},
	disruptionsStörung: {
		label: 'Meldungen (Störung)',
		customLabels: ['Meldungen'],
		subTypes: [],
		filter: {
			disruptions: {
				[disruptionsClassifier.CLASSES.Störung]: 2,
				[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen]: 0,
				[disruptionsClassifier.CLASSES.Aufzüge]: 0,
			},
			filterType: 'disruptionsStörung',
			markerType: 'disruptions',
		},
		icon: 'disruptions',
	},
	disruptionsBaustellenUndUmleitungen: {
		label: 'Meldungen (Baustellen und Umleitungen)',
		customLabels: ['Meldungen'],
		subTypes: [],
		filter: {
			disruptions: {
				[disruptionsClassifier.CLASSES.Störung]: 0,
				[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen]: 2,
				[disruptionsClassifier.CLASSES.Aufzüge]: 0,
			},
			filterType: 'disruptionsBaustellenUndUmleitungen',
			markerType: 'disruptions',
		},
		icon: 'disruptions',
	},
	disruptionsAufzüge: {
		label: 'Meldungen (Aufzüge)',
		customLabels: ['Meldungen'],
		subTypes: [],
		filter: {
			disruptions: {
				[disruptionsClassifier.CLASSES.Störung]: 0,
				[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen]: 0,
				[disruptionsClassifier.CLASSES.Aufzüge]: 2,
			},
			filterType: 'disruptionsAufzüge',
			markerType: 'disruptions',
		},
		icon: 'disruptions',
	},
	disruptions: {
		label: 'Meldungen',
		customLabels: ['Meldungen'],
		subTypes: [],
		filter: {
			disruptions: {
				[disruptionsClassifier.CLASSES.Störung]: 2,
				[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen]: 2,
				[disruptionsClassifier.CLASSES.Aufzüge]: 2,
			},
			filterType: 'disruptions',
			markerType: 'disruptions',
		},
		icon: 'disruptions',
	},
	disruptionsAll: {
		label: 'Meldungen (Alle)',
		filter: {
			disruptions: {
				[disruptionsClassifier.CLASSES.Störung]: 2,
				[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen]: 2,
				[disruptionsClassifier.CLASSES.Aufzüge]: 2,
				date: {
					// From today at 0am
					from: new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						new Date().getDate(),
						0,
						0,
						0,
					),
					// To somewhere very far in the future: 01.01.9999, at 0:00:00am
					to: new Date(9999, 1, 1, 0, 0, 0),
				},
			},
			filterType: 'disruptionsAll',
			markerType: 'disruptions',
		},
		icon: 'disruptions',
	},
};

export const poiCategories = {
	Bildungseinrichtungen: 'B',
	Freizeit: 'C',
	'Krankenhäuser & Kliniken': 'F',
	Kultur: 'G',
	Sehenswürdigkeiten: 'H',
	'Verbände & Vereine': 'J',
	'Essen & Trinken': 'K',
	'Rund ums Rad': 'M',
};

export const stopMeans = {
	Bus: '3',
	Stadtbahn: '1',
	'S-Bahn': '2',
	'R-Bahn': '6',
};

export const sharingMappings = {
	stella: 'Stella',
	stadtmobil: 'Stadtmobil',
	'Call a Bike': 'RegioRad',
	CALL_A_BIKE: 'RegioRad',
	Car2Go: 'ShareNow',
	car2go: 'ShareNow',
};

export const sharingTitleMappings = {
	car2go: 'ShareNow',
	Car2Go: 'ShareNow',
};

export function nameToIcon(name, keepDash = false) {
	let icon = name
		.replace(/&/g, '-')
		.replace(/ä/g, 'ae')
		.replace(/ü/g, 'ue')
		.replace(/ö/g, 'oe')
		.replace(/ /g, '-')
		.replace(/---/g, '-');
	if (!keepDash) {
		icon = icon.replace(/-/g, '');
	}
	return icon.toLowerCase();
}

export function addSharingOperator(operator) {
	let icon;
	if (operator === 'ebikestation powered by nextbike') {
		icon = 'nextbike';
	} else {
		icon = nameToIcon(operator);
	}
	const operatorName = `sharing-${icon}`;

	searchCategories[operatorName] = {
		label: operator,
		filter: {
			sharing: {},
			filterType: operatorName,
			markerType: 'sharing',
		},
		icon,
	};

	if (operator === 'ShareNow') {
		searchCategories[operatorName].customLabels = ['Car2Go'];
	}

	searchCategories.sharing.subTypes.forEach((category) => {
		const categoryIcon = nameToIcon(category);
		searchCategories[operatorName].filter.sharing[category] = 1;
		searchCategories[`sharing-${categoryIcon}`].filter.sharing[operator] = 1;
	});

	searchCategories.sharing.subTypes.push(operator);
	searchCategories.sharing.filter.sharing[operator] = 2;
	searchCategories[operatorName].filter.sharing[operator] = 2;

	if (icon === 'nextbike') {
		searchCategories[operatorName].customLabels = [icon];
	}
}

Object.keys(poiCategories).forEach((poiCategory) => {
	const categoryName = nameToIcon(poiCategory);
	searchCategories[`pois-${categoryName}`] = {
		label: poiCategory,
		filter: {
			pois: {},
			filterType: `pois-${categoryName}`,
			markerType: 'poi',
		},
		icon: nameToIcon(poiCategory),
	};

	searchCategories.pois.subTypes.forEach((category) => {
		const filterName = nameToIcon(category);
		searchCategories[`pois-${filterName}`].filter.pois[poiCategory] = 1;
		searchCategories[`pois-${categoryName}`].filter.pois[category] = 1;
	});

	searchCategories.pois.subTypes.push(poiCategory);

	searchCategories.pois.filter.pois[poiCategory] = 2;
	searchCategories[`pois-${categoryName}`].filter.pois[poiCategory] = 2;
});

searchCategories.stops.subTypes.forEach((category) => {
	searchCategories[category].label = 'Öffentlicher Nahverkehr';
	const { filter } = searchCategories[category];
	Object.keys(searchCategories.stops.filter.stops).forEach((key) => {
		if (typeof filter.stops[key] === 'undefined') {
			filter.stops[key] = 1;
		}
	});
	filter.stops.Haltestellen = 2;
	filter.filterType = category;
	filter.markerType = 'stop';
	searchCategories[category].icon = 'stops';
});
