import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconProps } from 'shared';

import media from '../../utils/Breakpoints';
import { colors, boxshadow } from '../../utils/config';

const StyledCategoryItemVehicleDetail = styled.div`
	position: relative;
	width: 100%;
	border: 2px solid transparent;
	border-radius: 5px;
	background-color: ${colors.white};
	margin-bottom: 8px;
	padding: 8px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;

	${media.md`
		width: 460px;
		box-shadow: ${boxshadow.default};
	`}
`;

const Iconwrapper = styled.div`
	position: absolute;
	top: 22px;
	right: 26px;
	width: 48px;
	height: 48px;
`;

const TextWrapper = styled.div`
	position: relative;
	max-width: 290px;
	margin-left: 56px;

	${media.md`
		margin-left: 96px;
	`}
`;

const BackButton = styled.div`
	position: absolute;
	top: 14px;
	left: -64px;
	display: block;
	width: 62px;
	height: 62px;
	background-image: url("data:image/svg+xml,%3Csvg width='15' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.377 2.63L2.13 12.878l10.246 10.246' stroke='%23097fac' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: center;

	&:hover {
		cursor: pointer;
	}

	${media.md`
		left: -88px;
	`}
`;

const Title = styled.h2`
	font-size: 21px;
	line-height: 27px;
	margin-top: 24px;
`;

const Subtitle = styled.p`
	font-size: 15px;
	line-height: 19px;
	color: ${colors.black};
`;

const CardBody = styled.div`
	margin-top: 48px;
	margin-left: 56px;
	padding-right: 16px;
	padding-bottom: 32px;

	${media.md`
		margin-left: 96px;
	`}
`;

const Address1 = styled.p`
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 18px;
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 14px;
		height: 18px;
		position: absolute;
		top: 0;
		left: -36px;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.95 12.477L7 17.389l-4.95-4.912c-2.733-2.764-2.733-7.252 0-10.015a6.95 6.95 0 019.9 0c2.733 2.763 2.733 7.251 0 10.015z' fill='%23BDC8D7' fill-rule='nonzero'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
	}
`;

const ExternalLink = styled.a`
	position: relative;
	display: block;
	text-decoration: none;
	color: ${colors.cyandark};
	margin-top: 56px;

	&::before {
		content: '';
		display: block;
		width: 18px;
		height: 18px;
		position: absolute;
		top: 1px;
		left: -36px;
		background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-width='2' stroke='%23BDC8D7' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.467 17H17V1H1v8.533'/%3E%3Cpath d='M10 13.25V8H4.75M10 8l-7 7'/%3E%3C/g%3E%3C/svg%3E");
		background-repeat: no-repeat;
	}
`;

export default function CategoryItemVehicleDetail({
	id,
	type,
	title,
	subtitle,
	icon,
	address,
	vehicletypes,
	linkname,
	uri,
	onClose,
}) {
	return (
		<StyledCategoryItemVehicleDetail type={type} data-id={id}>
			<Iconwrapper>
				<svg width="100%" height="100%" viewBox={IconProps[icon].viewBox}>
					<path d={IconProps[icon].path} fill={colors.grayblue} />
				</svg>
			</Iconwrapper>
			<TextWrapper>
				<BackButton onClick={onClose} />
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</TextWrapper>
			<CardBody>
				<Address1>{address}</Address1>
				<p>{vehicletypes}</p>
				<ExternalLink href={uri} target="_blank" rel="noopener noreferrer">
					{linkname}
				</ExternalLink>
			</CardBody>
		</StyledCategoryItemVehicleDetail>
	);
}

CategoryItemVehicleDetail.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	type: PropTypes.string,
	subtitle: PropTypes.string,
	address: PropTypes.node,
	vehicletypes: PropTypes.node,
	linkname: PropTypes.string,
	uri: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};
