import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';

const MarkerContext = createContext(null);
export default MarkerContext;

export class Provider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filters: null,
			marker: null,
			stopMarkers: [],
			sharingMarkers: [],
			disruptionsMarkers: [],
			poiMarkers: [],
			tipMarkers: [],
			parkAndRideMarkers: [],
			vehicleMarkers: [],
			popup: null,
			lines: null,
		};
	}

	/**
	 * define all setter as methods, defining functions creates always a new function
	 */
	setFilters = (newFilters, callback) => this.setState({ filters: newFilters }, callback);

	setMarker = (newMarker, callback) => this.setState({ marker: newMarker }, callback);

	setStopMarkers = newStopMarkers => this.setState({ stopMarkers: newStopMarkers });

	setDisruptionsMarkers = newDisruptionsMarkers =>
		this.setState({ disruptionsMarkers: newDisruptionsMarkers });

	setSharingMarkers = newSharingMarkers => this.setState({ sharingMarkers: newSharingMarkers });

	setPoiMarkers = newPoiMarkers => this.setState({ poiMarkers: newPoiMarkers });

	setTipMarkers = newTipMarkers => this.setState({ tipMarkers: newTipMarkers });

	setParkAndRideMarkers = newPRMarkers => this.setState({ parkAndRideMarkers: newPRMarkers });

	setVehicleMarkers = newVehicleMarkers => this.setState({ vehicleMarkers: newVehicleMarkers });

	/**
	 * @param {number[] | function(number[]): number[]} arg
	 * @param {function} callback
	 * @returns {void}
	 */
	setLines = (arg, callback) => {
		this.setState(
			prev => ({ lines: typeof arg === 'function' ? arg(prev.lines) : arg }),
			callback,
		);
	};

	setContext = (updates, callback) => this.setState(updates, callback);

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.filters !== this.state.filters) {
			return true;
		}
		if (nextState.marker !== this.state.marker) {
			return true;
		}
		if (nextState.lines !== this.state.lines) {
			return true;
		}
		const types = [
			'stopMarkers',
			'disruptionsMarkers',
			'sharingMarkers',
			'poiMarkers',
			'tipMarkers',
			'parkAndRideMarkers',
			'vehicleMarkers',
		];
		const typeCount = types.length;
		// loop checks if changes in marker elements were made and updates the context
		for (let i = 0; i < typeCount; i += 1) {
			// check if next or previous state for types were null and are now set
			if (
				(this.state[types[i]] === null && nextState[types[i]] !== null) ||
				(this.state[types[i]] !== null && nextState[types[i]] === null)
			) {
				return true;
			}
			// if states aren't null
			// first check if arrays have different lengths
			// then check if first element id is different
			// then check if last element id is different
			if (this.state[types[i]] !== null && nextState[types[i]] !== null) {
				if (
					this.state[types[i]].length !== nextState[types[i]].length ||
					(this.state[types[i]].length > 0 &&
						(this.state[types[i]][0].id !== nextState[types[i]][0].id ||
							this.state[types[i]][this.state[types[i]].length - 1].id !==
							nextState[types[i]][nextState[types[i]].length - 1].id))
				) {
					return true;
				}
				// else check if vvsUpdate flag is set on first element
				if (this.state[types[i]].length > 0 && nextState[types[i]][0].vvsUpdate === true) {
					// reset flag after one trigger
					nextState[types[i]][0].vvsUpdate = false;
					return true;
				}
			}
		}

		if (JSON.stringify(nextState.popup) !== JSON.stringify(this.state.popup)) {
			return true;
		}
		// if no catches above are made the state is not updating
		return false;
	}

	render() {
		const {
			filters,
			marker,
			stopMarkers,
			disruptionsMarkers,
			sharingMarkers,
			poiMarkers,
			tipMarkers,
			parkAndRideMarkers,
			vehicleMarkers,
			popup,
			lines,
		} = this.state;

		return (
			<MarkerContext.Provider
				value={{
					filters,
					setFilters: this.setFilters,
					marker,
					setMarker: this.setMarker,
					stopMarkers,
					setStopMarkers: this.setStopMarkers,
					disruptionsMarkers,
					setDisruptionsMarkers: this.setDisruptionsMarkers,
					sharingMarkers,
					setSharingMarkers: this.setSharingMarkers,
					poiMarkers,
					setPoiMarkers: this.setPoiMarkers,
					tipMarkers,
					setTipMarkers: this.setTipMarkers,
					parkAndRideMarkers,
					setParkAndRideMarkers: this.setParkAndRideMarkers,
					vehicleMarkers,
					setVehicleMarkers: this.setVehicleMarkers,
					popup,
					lines,
					setLines: this.setLines,
					openPopup: this.openPopup,
					setContext: this.setContext,
				}}
			>
				{this.props.children}
			</MarkerContext.Provider>
		);
	}
}

Provider.propTypes = {
	children: PropTypes.node.isRequired,
};
