import { getBottomLeft, getTopRight } from 'ol/src/extent';
import { toLonLat } from 'ol/src/proj';

import { fetchers, createMarker } from 'shared';

import { mapConfig } from '../../../utils/config';
import renderTooltip from './Tooltips/renderTooltip';
import renderTooltipSelected from './Tooltips/renderTooltipSelected';

function addPrMarker(pr, state, map, setClickedMarker, prMarker) {
	const prAttrs = {};
	pr.attrs.forEach(attr => {
		prAttrs[attr.name] = attr.value;
	});
	let isSelected = prMarker?.id === pr.id;
	if (isSelected) {
		state = 3;
	}
	let title = `${pr.desc}${pr.locality ? ', ' : ''}${pr.locality}`;
	const marker = createMarker(pr, {
		type: `pr`,
		vvsId: pr.id,
		title: title,
		locationtype: 'station',
		state: state,
		hovered: state === 3 ? true : false,
		vvsOperator: 'pr',
		operator: 'pr',
		canHover: true,
		canClick: true,
		onHover: () => {
			renderTooltip('pr', marker, map, pr, 'tooltip', state, [0, 0], null, null);
		},
		onClick: () => {
			renderTooltipSelected(marker, map, [0, 0], setClickedMarker);
		},
	});

	marker.vvsType = 'pr';
	marker.vvsId = pr.id;
	marker.vvsState = state;
	marker.vvsTitle = title;
	marker.type = 'pr';
	if (state === 3) {
		renderTooltip('pr', marker, map, pr, 'tooltip-selected', 2, [0, 0], null, null);
	}
	return marker;
}

let parkAndRideFilter;
let lastBottomLeft = [-1, -1];
let lastTopRight = [-1, -1];
let lastPrMarker;

export default async (
	map,
	filter = null,
	setParkAndRideMarkers = () => {},
	prMarker,
	setClickedMarker = () => {},
) => {
	const extent = map.getView().calculateExtent();
	const bottomLeft = toLonLat(getBottomLeft(extent));
	const topRight = toLonLat(getTopRight(extent));

	//check if any params or filters or markers were changed
	//if not don't fetch again and don't set the updated flag
	if (
		prMarker === lastPrMarker &&
		lastTopRight[0] === topRight[0] &&
		lastBottomLeft[0] === bottomLeft[0] &&
		lastTopRight[1] === topRight[1] &&
		lastBottomLeft[1] === bottomLeft[1] &&
		filter === parkAndRideFilter
	) {
		return;
	}
	parkAndRideFilter = filter;
	lastTopRight = topRight;
	lastBottomLeft = bottomLeft;
	lastPrMarker = prMarker;

	//check if zoomed out
	if (map.getView().getZoom() < mapConfig.minZoomLevel.parkAndRide + mapConfig.minZoom) {
		let markersArray = [];
		//check if selected marker isn't null and already generated marker
		//if true keep selected marker on map
		if (lastPrMarker && lastPrMarker.vvsId) {
			markersArray.push(lastPrMarker);
		}
		setParkAndRideMarkers(markersArray);
		return;
	}

	const parkObjects = await fetchers.fetchParkObjects(
		bottomLeft[1],
		bottomLeft[0],
		topRight[1],
		topRight[0],
	);

	if (parkAndRideFilter === 0) {
		setParkAndRideMarkers([]);
		return;
	}

	const parkAndRideMarkers = [];

	parkObjects.forEach(pr => {
		let state = 2;
		let newMarker = addPrMarker(pr, state, map, setClickedMarker, lastPrMarker);
		if (newMarker) parkAndRideMarkers.push(newMarker);
	});
	if (parkAndRideMarkers.length > 0) parkAndRideMarkers[0].vvsUpdate = true;
	setParkAndRideMarkers(parkAndRideMarkers);
};
