import styled from 'styled-components';

const ModalContent = styled.div`
	p + p {
		margin-top: 1rem;
	}

	span {
		white-space: nowrap;
	}
`;

export default ModalContent;
