import styled, { css } from 'styled-components';

export const ImageWrapper = styled.div`
	grid-column: 2 / 3;
	grid-row: 1 / 3;
`;

export const ImageLink = styled.a``;

export const Image = styled.img`
	max-width: 100%;
	height: auto;
	max-height: 80vh;
	transition: transform 0.5s ease;

	${({ radius }) =>
		radius &&
		css`
			border-radius: ${radius};
		`}
`;
