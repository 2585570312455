import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';
import LoadingBar from './LoadingBar';
import Gradient from './Gradient';

const TextBlockLoadingIndicator = ({ className, rows }) => {
	let index = 0;
	const bars = [];
	while (index < rows) {
		index += 1;
		if (index === rows) {
			bars.push(<LoadingBar key={`loading-text-last-${index}`} width="80%" />);
		} else {
			bars.push(<LoadingBar key={`loading-text-${index}`} />);
		}
	}

	return (
		<Wrapper className={className}>
			<LoadingBar headline />
			{bars}
			<Gradient />
		</Wrapper>
	);
};

TextBlockLoadingIndicator.propTypes = {
	className: PropTypes.string,
	rows: PropTypes.number,
};

TextBlockLoadingIndicator.defaultProps = {
	className: '',
	rows: 3,
};

export default TextBlockLoadingIndicator;
