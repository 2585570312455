/**
 * Executes a callback function only when the 'Enter' key is pressed.
 * @param {Function} callback - The callback function to be executed.
 * @returns {Function} - A function that checks if the key pressed is 'Enter' and calls the provided callback.
 */
export function onlyOnEnter(callback) {
	return e => {
		if (e.key === 'Enter') callback(e);
	};
}
