import styled, { css } from 'styled-components';

import wheelchair from '../../assets/icons/wheelchair.svg';
import busstop from '../../assets/icons/busstop.svg';
import checkedIcon from '../../assets/icons/checkmark.svg';
import uncheckedIcon from '../../assets/icons/unchecked.svg';

const Attributes = styled.ul`
	display: flow-root;
	grid-column: 1 / 2;
	list-style-type: none;
	margin: 1rem 0;
	overflow: auto;
	padding: 0 10px 0 30px;
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
	}
`;

export const AccessibilityAttributes = styled(Attributes)`
	grid-row: 1 / 2;

	&::before {
		background-image: url(${wheelchair});
		background-size: 20px 20px;
	}
`;

export const EquipmentAttributes = styled(Attributes)`
	grid-row: 2 / 3;

	&::before {
		background-image: url(${busstop});
		background-size: 22px 16px;
		width: 22px;
		height: 16px;
	}
`;

export const InfoItem = styled.li`
	background-color: #fff;
	border: 1px solid #4f5b5f;
	border-radius: 50%;
	color: #4f5b5f;
	float: right;
	font-size: 14px;
	line-height: 22px;
	margin: 0 10px 5px;
	position: relative;
	text-align: center;
	width: 22px;
	height: 22px;
	z-index: 1;

	&:active,
	&:focus,
	&:hover {
		border-color: #000;
		color: #000;
		cursor: pointer;
	}
`;

export const Attribute = styled.li`
	line-height: 1;
	margin: 0 0 0.5rem;
	padding-left: 16px;
	position: relative;

	${({ checked }) =>
		checked
			? css`
					color: #000;
			  `
			: css`
					color: #999;
			  `}

	&::before {
		background-size: 8px 8px;
		content: '';
		display: block;
		position: absolute;
		top: 5px;
		left: 0;
		width: 8px;
		height: 8px;
	}

	${({ checked }) =>
		checked
			? css`
					&::before {
						background-image: url(${checkedIcon});
					}
			  `
			: css`
					&::before {
						background-image: url(${uncheckedIcon});
					}
			  `}
`;
