import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import media from '../../utils/Breakpoints';
import { colors } from '../../utils/config';

import { ReactComponent as LogoEU } from '../../assets/logo_eu.svg';
import { ReactComponent as LogoBW } from '../../assets/logo_bw.svg';

const FooterButtonWrapper = styled.div`
	display: block;
	position: fixed;
	right: 43px;
	bottom: 3px;

	@media (min-width: 480px) {
		bottom: 6px;
		border-radius: 4px;
		background-color: ${colors.white};
		padding: 8px;
	}

	&:focus,
	&:hover {
		cursor: pointer;
	}
`;

const TextLink = styled.div`
	text-decoration: underline;
	font-size: 9px;
`;

const LogoWrapper = styled.div`
	display: none;

	@media (min-width: 480px) {
		display: block;

		svg {
			height: 24px;
			width: auto;
			margin-right: 8px;
		}
	}
`;

export default function FooterButton({ onClick }) {
	return (
		<FooterButtonWrapper onClick={onClick}>
			<LogoWrapper>
				<LogoEU />
				<LogoBW />
			</LogoWrapper>
			<TextLink>Infos zur Förderung</TextLink>
		</FooterButtonWrapper>
	);
}

FooterButton.propTypes = {
	onClick: PropTypes.func,
};
