import styled from 'styled-components';

export const H3 = styled.h3`
	p + & {
		margin-top: 24px;
	}
`;

export const H4 = styled.h4`
	p + & {
		margin-top: 24px;
	}
`;
