import { searchCategories, nameToIcon } from '../../../utils/config';

export default (marker, markerContext) => {
	try {
		const selectedMarker = marker;
		selectedMarker.type = 'sharing';
		selectedMarker.name = marker.vvsTitle;
		selectedMarker.id = marker.vvsId;
		const icon = nameToIcon(marker.vvsOperator);
		const filterCategory = searchCategories[`sharing-${icon}`] || searchCategories.sharing;
		markerContext.setContext({
			marker: selectedMarker,
			filters: filterCategory.filter,
		});

		document.querySelector('#searchinput').blur();
	} catch (e) {
		// Do nothing on errors
		// console.log('error', e);
	}
};
