import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { boxshadow, colors } from '../../utils/config';

const Content = styled.div`
	background-color: ${colors.white};
	box-shadow: ${boxshadow.tooltip};
	margin: auto 0;
	padding: 24px;
	position: relative;
	min-width: 200px;
	max-width: 100%;

	${media.md`
		padding: 32px;
	`}

	&:hover {
		cursor: default;
	}
`;

export default Content;
