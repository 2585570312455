import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button, { ButtonVariants } from '../../Button';
import { TEXT } from '../text';

const StyledResultHeader = styled.header`
	display: grid;
	grid-template-columns: 1fr min-content;
	align-items: center;
	padding-bottom: var(--size_x05, 8px);

	h3 {
		color: var(--true-black, #000);
		font-size: var(--size_x1, 16px);
		font-weight: 500;
		letter-spacing: 0.32px;
	}

	button svg {
		width: var(--size_x15, 24px);
		height: var(--size_x15, 24px);

		* {
			transition: transform 250ms ease-in-out;
			transform-origin: 50% 50%;
		}
		.rotate {
			transform: rotate(90deg);
		}
	}
`;

export function ResultHeader({ count, isCollapsed, onToggle }) {
	return (
		<StyledResultHeader>
			<h3>
				{count} {TEXT.RESULT_DETAIL.HEADER.TITLE}
			</h3>
			<Button
				type="button"
				onClick={onToggle}
				variant={ButtonVariants.ICON}
				aria-label={TEXT.RESULT_DETAIL.HEADER.TOGGLE_LABEL}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						id="p1"
						d="M4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13Z"
						fill="black"
					/>
					<path
						id="p2"
						className={isCollapsed ? `` : `rotate`}
						d="M11 4L11 20C11 20.55 11.45 21 12 21C12.55 21 13 20.55 13 20L13 4C13 3.45 12.55 3 12 3C11.45 3 11 3.45 11 4Z"
						fill="black"
					/>
				</svg>
			</Button>
		</StyledResultHeader>
	);
}

ResultHeader.propTypes = {
	count: PropTypes.number.isRequired,
	isCollapsed: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
};
