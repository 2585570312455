import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconProps } from 'shared';

import media from '../../utils/Breakpoints';
import { colors } from '../../utils/config';


const StyledIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	border-radius: 18px;
	background: center center no-repeat ${colors.graylight};
	background-size: 50%;

	${media.lg`
		width: 46px;
		height: 46px;
		border-radius: 23px;
	`}

	svg {
		width: 32px;
		height: 32px;

		${media.lg`
			width: 36px;
			height: 36px;
		`}

		path {
			fill: ${colors.graydark};
		}
	}

	${props =>
		props.active &&
		`
		svg path {
			fill: #fff;
		}
	`}
`;

const Icon = props => {
	const { icon, color, width, height, active } = props;
	return (
		<StyledIcon active={active} icon={icon}>
			<svg width={width} height={height} viewBox={IconProps[icon].viewBox}>
				<path d={IconProps[icon].path} fill={color} />
			</svg>
		</StyledIcon>
	);
};

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	color: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	active: PropTypes.bool,
};

Icon.defaultProps = {
	color: '#737C8A',
	width: 36,
	height: 36,
};

export default Icon;
