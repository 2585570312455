import { searchCategories, nameToIcon } from '../../../utils/config';

export default (marker, markerContext) => {
	try {
		const selectedMarker = marker;
		selectedMarker.type = 'poi';
		let filters;
		const poiCategory = nameToIcon(marker.category);
		if (searchCategories[`pois-${poiCategory}`]) {
			filters = searchCategories[`pois-${poiCategory}`].filter;
		} else {
			filters = searchCategories.pois.filter;
		}

		markerContext.setContext({
			marker: selectedMarker,
			filters,
		});

		document.querySelector('#searchinput').blur();
	} catch (e) {
		// Do nothing on errors
	}
};
