import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

import DepartureItem from '../DepartureItem';

const StyledDepartureTable = styled.div`
	width: 100%;
	margin-bottom: 8px;

	${media.lg`
		width: 460px;
		margin-bottom: 16px;
	`}
`;

export default function DepartureTable({ items }) {
	return (
		<StyledDepartureTable>
			{items.map((item, i) => (
				<DepartureItem
					key={item.id}
					timeplanned={item.timeplanned}
					timereal={item.timereal}
					icon={item.icon}
					title={item.title}
					subtitle={item.subtitle}
					link={item.link}
					locationinfo={item.locationinfo}
					isRealtimeControlled={item.isRealtimeControlled}
					isCancelled={item.isCancelled}
					lastitem={items.length === i + 1}
				/>
			))}
		</StyledDepartureTable>
	);
}

DepartureTable.propTypes = {
	items: PropTypes.array,
};
