import React from 'react';
import ReactSelect, { components } from 'react-select';
import styled from 'styled-components';

import CheckedIcon from '../../assets/icons/checked.svg';
import UncheckedIcon from '../../assets/icons/unchecked.svg';
import { TEXT } from '../text';

export const StyledSelectImage = styled.img`
	width: var(--size_x15, 24px);
	height: var(--size_x15, 24px);
	cursor: pointer;
`;

export const StyledReactSelect = styled(ReactSelect)`
	margin: 0;

	[class$='placeholder'] {
		color: var(--TrueBlack_02, #00000033);
	}
`;

export const StyledOption = styled(components.Option)`
	&.styled-option {
		display: flex;
		gap: var(--size_x05, 8px);
		align-items: center;
		background-color: transparent;
		color: var(--TrueBlack, #000);

		&:active,
		&:hover {
			background-color: var(--GreyShade3, #d9d9d9);
		}
	}
`;

// the extra class is here for a weird bug in the builded app
// this class needs to overwrite default styling
// in the build process sometimes class names from the react-select vanished this is the solution
const StyledControl = styled(components.Control)`
	&.styled-control {
		/* max-width: calc(100% - 40px); */
		border: var(--border, 1px solid #d9d9d9);
		background-color: var(--GreyShade1_02, #fafafa33);
		cursor: pointer;
	}

	> ${StyledSelectImage} {
		margin-left: var(--size_x075, 12px);
	}
`;

export const StyledSingleValue = styled(components.SingleValue)`
	&.styled-single {
		display: flex;
		gap: var(--size_x05, 8px);
		align-items: center;
		padding-top: var(--size_x025, 20px);
		padding-bottom: var(--size_x025, 20px);
	}
	&.styled-multi {
		gap: var(--size_x05, 8px);
		align-items: center;
		padding-top: var(--size_x025, 20px);
		padding-bottom: var(--size_x025, 20px);
		display: inline;
	}
`;

// stlying this doesn't work in build version
export const StyledValueContainer = styled(components.ValueContainer)`
	&.value-wrapper {
		overflow: hidden;
		max-width: 140px;
		text-overflow: ellipsis;
		max-height: 40px;
		display: inline;
		text-align: left;
		text-justify: center;

		@media (min-width: 1280px) {
			max-width: 170px;
		}
	}
`;

/**
 * @see https://react-select.com/components#defining-components
 */
/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
export function Control({ children, ...props }) {
	return (
		<StyledControl {...props} className="styled-control">
			{children}
		</StyledControl>
	);
}

export function Option({ children, ...props }) {
	return (
		<StyledOption {...props} isSelected className="styled-option">
			<StyledSelectImage
				src={props.isSelected ? CheckedIcon : UncheckedIcon}
				alt={props.isSelected ? TEXT.SELECT.CHECKED_ALT : TEXT.SELECT.UNCHECKED_ALT}
			/>
			{props.data.icon && <StyledSelectImage src={props.data.icon} alt={props.data.label} />}
			{children}
		</StyledOption>
	);
}

export function ValueContainer({ children, ...props }) {
	if (props.isMulti) {
		const value = props.getValue();
		return (
			<StyledValueContainer {...props} className={'value-wrapper'}>
				{/* render children for correct functionality but disable them */}
				<div style={{ width: '0px', height: '0px', overflow: 'hidden' }}>{children}</div>
				{value.map((v, index) => (
					<StyledSingleValue key={v.key || v.value} {...props} className={'styled-multi'}>
						<span>
							{v.valueLabel || v.label}
							{index < value.length - 1 && value.length > 1 ? ',' : ''}
						</span>
					</StyledSingleValue>
				))}
				{value.length === 0 && <span>{TEXT.SELECT.NO_SELECTION}</span>}
			</StyledValueContainer>
		);
	}

	return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
}

export function SingleValue({ children, ...props }) {
	return (
		<StyledSingleValue {...props} className={'styled-single'}>
			{props.data.icon && <StyledSelectImage src={props.data.icon} alt={props.data.label} />}
			<span>{children}</span>
		</StyledSingleValue>
	);
}
/* eslint-enable react/prop-types, react/jsx-props-no-spreading */

export function IndicatorSeparator() {
	return null;
}

// this is a component for react-select
// standardly it has a styling which conflicts with our styling so return null
export function IndicatorsContainer() {
	return null;
}

export function ClearIndicator() {
	return null;
}
