import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { disruptionsClassifier } from 'shared';
import media from '../../utils/Breakpoints';
import { colors, boxshadow } from '../../utils/config';

import MenuItem from './menuitem';

const StyledCategoryMenu = styled.div`
	width: 96%;
	width: calc(100% - 16px);
	height: ${props => (props.menuExtended ? 'auto' : '72px')};
	margin-left: auto;
	margin-right: auto;
	border-radius: 5px;
	border: 1px solid ${colors.graylight};
	background-color: ${colors.white};
	box-shadow: ${boxshadow.categorymenu};
	margin-bottom: 8px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	${media.iphone`
		max-width: 358px;
	`}

	${media.lg`
		width: 460px;
		max-width: none;
		height: ${props => (props.menuExtended ? 'auto' : '96px')};
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(0.752);
		transform-origin: top left;
	}

	${props =>
		props.dockedAtSearchbar &&
		`
		position: fixed;
		top: 55px;
		margin-left: 8px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		z-index: 30;
	`}

	${props =>
		props.dockedAtSearchbar &&
		media.lg`
		top: 83px;
		margin-left: 16px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		${props =>
			props.dockedAtSearchbar &&
			`
			top: 66px;
			margin-left: 16px;
		`}
	}

	${props =>
		props.dockedAtSearchbar &&
		media.xl`
		top: 99px;
		margin-left: 32px;
	`}
`;

/**
 * Decides which disruption filters should be toggled on or off
 *
 * @param {*} toggle - Array of disruption filter keys that should be toggled
 * @param {*} disruptionFilters - Current disruption filters
 * @returns updated disruption filters
 */
function toggleDisruptionFilters(toggle, disruptionFilters) {
	/* First check special cases, later fall back to default behavior */

	// SPECIAL CASE 1: If currently all disruption filters are active and the user clicks on one of the type filters,
	// all filters should be deactivated, except the type filter that was clicked.

	const TYPES = [
		disruptionsClassifier.CLASSES.Störung,
		disruptionsClassifier.CLASSES.BaustellenUndUmleitungen,
		disruptionsClassifier.CLASSES.Aufzüge,
	];

	// Check if all filters are active
	const allFiltersActive = Object.entries(disruptionFilters).every(
		// All filters should be active, date filter is ignored
		([key, value]) => !TYPES.includes(key) || value === 2,
	);
	// Check if the toggle array contains exactly one type filter
	const toggleContainsExactlyOneTypeFilter = toggle.length === 1 && TYPES.includes(toggle[0]);
	// If all filters are active and the toggle array contains exactly one type filter,
	// deactivate all filters except the type filter that was clicked
	if (allFiltersActive && toggleContainsExactlyOneTypeFilter) {
		const updatedEntries = Object.entries(disruptionFilters).map(([key, value]) => {
			// Set toggled entry to active
			if (key === toggle[0]) return [key, 2];
			// Set all other entries to inactive
			if (TYPES.includes(key)) {
				return [key, 0];
			}
			// Ignore elements that are not a disruptions class. This is the case for the date filter.
			return [key, value];
		});
		return Object.fromEntries(updatedEntries);
	}

	/* Default logic */
	// Iterate over all disruption filters and toggle the ones that are in the toggle array
	const updatedEntries = Object.entries(disruptionFilters).map(([key, value]) => {
		// If the current filter is in the toggle array of affected filters, toggle it
		if (toggle.includes(key)) {
			// If the current filter is currently active, set it to inactive (0), otherwise set it to active (2)
			return [key, value === 2 ? 0 : 2];
		}
		return [key, value];
	});

	// Return updated disruption filters as object
	return Object.fromEntries(updatedEntries);
}

function isActive(type, filters, item, activeMenuItem) {
	if (type === 'disruptions') {
		if (item.id === 1) {
			const to = filters.disruptions.date?.to;
			return to && to.getFullYear?.() !== 9999;
		}
		if (item.id === 2) {
			return Object.values(filters.disruptions).every(value => !!value);
		}
		if (item.id === 3) return !!filters.disruptions[disruptionsClassifier.CLASSES.Störung];
		if (item.id === 4)
			return !!filters.disruptions[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen];
		if (item.id === 5) return !!filters.disruptions[disruptionsClassifier.CLASSES.Aufzüge];
	}
	return item.titlefilter === activeMenuItem;
}

function CategoryMenu({
	items,
	type,
	dockedAtSearchbar,
	setFilters,
	activeMenuItem,
	filters,
	'data-tabindex': dataTabindex,
}) {
	const [menuExtended, setMenuExtended] = useState(false);

	let listLimit = 6;
	if (menuExtended) {
		listLimit = 100;
	}

	return (
		<StyledCategoryMenu
			type={type}
			dockedAtSearchbar={dockedAtSearchbar}
			menuExtended={menuExtended}
			activeMenuItem={activeMenuItem}
		>
			{items.slice(0, listLimit).map((item, i) => (
				<MenuItem
					key={item.id}
					title={item.title}
					subtitle={item.subtitle}
					icon={item.icon}
					buttonType={item.buttonType}
					data-tabindex={dataTabindex + i}
					image={item.image}
					type={type}
					active={isActive(type, filters, item, activeMenuItem)}
					visuallyActive={
						// Only use visuallyActive for disruptions and not for the all filter button and the date picker
						type === 'disruptions' && ![1, 2].includes(item.id)
							? // Should be visually active, if the current filter button is active, but not all filters are active
							  isActive(type, filters, item, activeMenuItem) &&
							  !isActive(
									type,
									filters,
									items.find(
										({ id }) => id === 2, // id = 2 is the all filter button
									),
									activeMenuItem,
							  )
							: undefined
					}
					onClick={() => {
						if (item.isShowMore) {
							setMenuExtended(!menuExtended);
						} else if (type === 'disruptions') {
							const nextFilters = {
								...filters,
								...item?.filter,
								disruptions: {
									...toggleDisruptionFilters(item.toggle, filters.disruptions),
									...item?.filter?.disruptions,
								},
							};
							setFilters(nextFilters);
						} else if (item.buttonType !== 'datePicker') {
							// Set filters on click, but only if the button is not a datePicker, which only filters onChange
							setFilters(item.filter);
							// this.setState({
							// 	activeItem: item.title,
							// });
						}
						if (typeof _paq !== 'undefined' && typeof item.trackData !== 'undefined') {
							// eslint-disable-next-line no-underscore-dangle
							window._paq.push(['trackEvent', ...item.trackData]);
						}
					}}
					onChange={event => {
						// If the menu item is a datePicker, update filter with selected date
						if (item.buttonType === 'datePicker') {
							// Call items custom filter update handler to know the updated filter
							const updatedFilter = item.handleFilterUpdate(
								item.filter,
								event.target.value,
							);
							setFilters(updatedFilter);
						}
					}}
				/>
			))}
		</StyledCategoryMenu>
	);
}

CategoryMenu.propTypes = {
	items: PropTypes.array,
	type: PropTypes.oneOf(['oepnv', 'sharing', 'places', 'tips', 'disruptions']),
	dockedAtSearchbar: PropTypes.bool,
	activeMenuItem: PropTypes.string,
	setFilters: PropTypes.func,
	filters: PropTypes.shape({}),
	'data-tabindex': PropTypes.number,
};

CategoryMenu.defaultProps = {
	setFilters: () => {},
};

export default CategoryMenu;
