import React from 'react';
import PropTypes from 'prop-types';
import { htmlEntities } from '../../utils/StringHelpers';

const BoldSearchWords = ({ label, searchRegex }) => (
	<span
		/* eslint-disable-next-line react/no-danger */
		dangerouslySetInnerHTML={{
			__html: htmlEntities(label).replace(searchRegex, x => `<strong>${x}</strong>`),
		}}
	/>
);

BoldSearchWords.propTypes = {
	label: PropTypes.string.isRequired,
	searchRegex: PropTypes.object.isRequired,
};

export default BoldSearchWords;
