import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { fonts, colors } from '../../utils/config';

import IconAddress from './icons/address.svg';
import IconStation from './icons/stop.svg';
import IconStations from './icons/stops.svg';
import IconPoi from './icons/poi.svg';
import IconParkAndRide from './icons/parkride.svg';
//TODO check if correct
import IconCityTicket from './icons/stadtticket.svg';
import IconAddressTip from './icons/address-tip.svg';
import IconArrowRight from './icons/arrow-right.svg';
import IconArrowRightBlue from './icons/arrow-right--blue.svg';

const StyledSearchResultListItem = styled.li`
	list-style-type: none;
	width: 100%;
	max-width: 458px;
	height: 64px;
	background-color: #fff;
	padding: 14px 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${colors.graylight};
	background: url(${IconArrowRight}) right 24px center no-repeat transparent;

	&.active {
		background-color: ${colors.graylight};
	}

	&:hover,
	&:focus {
		cursor: pointer;
		background-image: url(${IconArrowRightBlue});
	}

	${media.lg`
		height: 72px;
		padding: 14px 24px;
		background-position: right 32px center;
	`}
`;

const TextWrapper = styled.div`
	overflow: hidden;
	width: 70%;
	max-height: 54px;

	${media.lg`
		max-height: 60px;
		width: 75%;
	`}
`;

const Title = styled.h2`
	font-family: ${fonts.default};
	font-weight: normal;
	font-size: 16px;
	line-height: 1.185;
	color: ${colors.black};

	${media.lg`
		font-size: 18px;
	`}

	strong {
		color: ${colors.black};
	}
`;

const Subtitle = styled.p`
	font-size: 16px;
	line-height: 1.185;
	color: ${colors.graydark};

	${media.lg`
		font-size: 18px;
	`}
`;

const SearchResultListItemIcon = styled.i`
	display: block;
	width: 32px;
	height: 32px;
	font-style: normal;
	margin-right: 16px;
	background-size: 32px;
	background: center center no-repeat transparent;

	${props => {
		if (props.icon === 'stop') {
			return `
				background-image: url(${IconStation});
			`;
		}
		if (props.icon === 'stops') {
			return `
				background-image: url(${IconStations});
			`;
		}
		if (props.icon === 'poi') {
			return `
				background-image: url(${IconPoi});
			`;
		}
		if (props.icon === 'tip') {
			return `
				background-image: url(${IconAddressTip});
			`;
		}
		if (props.icon === 'parkride') {
			return `
				background-image: url(${IconParkAndRide});
			`;
		}
		if (props.icon === 'citytickets') {
			return `
				background-image: url(${IconCityTicket});
			`;
		}
		return `
			background-image: url(${IconAddress});
		`;
	}}

	${media.lg`
		margin-right: 32px;
	`}
`;

export default function SearchResultListItem({
	title,
	subtitle,
	icon,
	active,
	onClick,
	onMouseEnter,
}) {
	return (
		<StyledSearchResultListItem
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			className={active ? 'active' : ''}
		>
			<SearchResultListItemIcon icon={icon} />
			<TextWrapper>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</TextWrapper>
		</StyledSearchResultListItem>
	);
}

SearchResultListItem.propTypes = {
	title: PropTypes.element.isRequired,
	subtitle: PropTypes.string,
	icon: PropTypes.oneOf([
		'stop',
		'poi',
		'address',
		'street',
		'singlehouse',
		'stops',
		'pois',
		'tipps',
		'bildungseinrichtungen',
		'freizeit',
		'krankenhaeuserkliniken',
		'kultur',
		'sehenswuerdigkeiten',
		'verbaendevereine',
		'essentrinken',
		'rundumsrad',
		'suburb',
		'sharing',
		'nextbike',
		'stella',
		'stadtmobil',
		'car2go',
		'sharenow',
		'flinkster',
		'tip',
		'parkride',
		'citytickets',
	]),
	active: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
};

SearchResultListItem.defaultProps = {
	onClick: () => {},
	onMouseEnter: () => {},
};
