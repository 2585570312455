/*
	Source: https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
*/
import { css } from 'styled-components';
import sizes from './sizes';

// iterate through the sizes and create a media template
export default Object.keys(sizes).reduce((accumulator, label) => {
	const acc = accumulator;
	acc[label] = (...args) => css`
		@media (min-width: ${sizes[label]}px) {
			${css(...args)}
		}
	`;

	return acc;
}, {});
