import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icons, RealTime } from 'shared';

import media from '../../utils/Breakpoints';
import { fonts, colors } from '../../utils/config';

const StyledDepartureItem = styled.div`
	height: 72px;
	width: 100%;
	max-width: 460px;
	border-bottom: ${props => (props.lastitem ? '0' : `1px solid ${colors.graylight}`)};
`;

const Item = styled.div`
	display: flex;
	align-items: center;
`;

const TimeWrapper = styled.div`
	flex: 0 0 68px;
	padding-left: 20px;
	position: relative;
	margin-right: 8px;

	${media.lg`
		flex: 0 0 80px;
		padding-left: 24px;
	`}

	> svg {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 16px;
	}
`;

const Timeplanned = styled.span`
	display: block;
`;

const Timereal = styled.span`
	display: block;
	color: ${colors.orange};
`;

const IconWrapper = styled.div`
	flex: 0 0 28px;
	height: 28px;
	margin-right: 8px;

	${media.lg`
		margin-right: 24px;
	`}

	svg {
		width: 100%;
		height: auto;
	}
`;

const TextWrapper = styled.div`
	flex: 1 0 120px;
	margin-right: 12px;

	${media.lg`
		flex: 1 0 150px;
		margin-right: 24px;
	`}
`;

const Title = styled.h2`
	font-family: ${fonts.default};
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
`;

const Subtitle = styled.p`
	font-size: 15px;
	line-height: 19px;
	color: ${colors.black};
`;

// const ExternalLink = styled.a`
// 	display: flex;
// 	flex: 0 0 48px;
// 	height: 72px;
// 	justify-content: center;
// 	align-items: center;
// `;

const LocationInfo = styled.div`
	display: flex;
	flex: 0 0 48px;
	height: 72px;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export default function DepartureItem({
	timeplanned,
	timereal,
	icon,
	title,
	subtitle,
	locationinfo,
	isRealtimeControlled,
	isCancelled,
	lastitem,
}) {
	let iconType = '';
	let timerealItem = '';

	if (Icons[icon]) {
		const Icon = Icons[icon];
		iconType = <Icon />;
	}

	if (isCancelled) {
		timerealItem = <Timereal>Ausf.</Timereal>;
	} else if (timereal && timereal !== '' && timeplanned !== timereal) {
		timerealItem = <Timereal>{timereal}</Timereal>;
	}

	return (
		<StyledDepartureItem lastitem={lastitem}>
			<Item>
				<TimeWrapper>
					{isRealtimeControlled && <RealTime />}
					<Timeplanned>{timeplanned}</Timeplanned>
					{timerealItem}
				</TimeWrapper>
				<IconWrapper>{iconType}</IconWrapper>
				<TextWrapper>
					<Title>{title}</Title>
					<Subtitle>{subtitle}</Subtitle>
				</TextWrapper>
				<LocationInfo>{locationinfo}</LocationInfo>
			</Item>
		</StyledDepartureItem>
	);
}

DepartureItem.propTypes = {
	timeplanned: PropTypes.string.isRequired,
	timereal: PropTypes.string,
	icon: PropTypes.oneOf(['bus', 'sbahn', 'ubahn', 'zacke', 'seilbahn', 'rbahn', 'taxi', ''])
		.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	locationinfo: PropTypes.string,
	lastitem: PropTypes.bool,
	isRealtimeControlled: PropTypes.bool,
};
