export function getDmUriWithDeeplink(dm, line) {
	const deeplink = {
		dateTime: {
			date: 'now',
			dateFormat: '',
			time: 'now',
			timeFormat: '',
			useRealTime: true,
			isDeparture: true,
			timeCut: ['01:00', '04:00'],
			timeCutInterval: 1,
		},
		odvs: {
			dm, // startId
		},
		efaParams: {
			deleteAssignedStops: 1,
			line, // lineId
		},
	};

	return `https://www3.vvs.de/mng/#!/XSLT_DM_REQUEST@lines?language=de&deeplink=${JSON.stringify(
		deeplink,
	)}`;
}

export function getVvsUriWithDeeplink(orig, dest) {
	const deeplink = {
		dateTime: {
			date: 'now',
			dateFormat: '',
			time: 'now',
			timeFormat: '',
			useRealTime: true,
			isDeparture: true,
		},
		efaParams: {
			illumTransfer: 'on',
			itOptionsActive: '1',
			language: 'de',
			locationServerActive: '1',
			noElevationProfile: '1',
			noElevationSummary: '1',
			outputOptionsActive: '1',
			securityOptionsActive: '1',
			useElevationData: '1',
			useRealtime: '1',
			tryToFindLocalityStops: '1',
			w_objPrefAl: '12',
			w_regPrefAm: '1',
			searchLimitMinutes: '360',
		},
		odvs: {
			orig,
			dest,
		},
	};

	return `https://www3.vvs.de/mng/#!/XSLT_TRIP_REQUEST2@result?language=de&deeplink=${JSON.stringify(deeplink)}`;
}
