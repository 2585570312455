import { searchCategories } from '../../../utils/config';

export default (marker, markerContext, isCityMode) => {
	try {
		const selectedMarker = marker;
		let filters = searchCategories.stops.filter;
		if (isCityMode) {
			selectedMarker.type = 'city';
			selectedMarker.vvsType = 'city';
			selectedMarker.set('type', 'city');
			filters = searchCategories.cityticket.filter;
		}
		markerContext.setContext({
			marker: selectedMarker,
			filters,
		});

		if (typeof _paq !== 'undefined') {
			// eslint-disable-next-line no-underscore-dangle
			window._paq.push([
				'trackEvent',
				'LivKarte', // Category
				'haltestelle', // Action
				'Nahverkehr', // Name
				selectedMarker.name, // Value
			]);
		}

		document.querySelector('#searchinput').blur();
	} catch (e) {
		// Do nothing on errors
	}
};
