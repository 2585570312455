import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LoadingWrapper from '../TextBlockLoadingIndicator/Wrapper';
import Gradient from '../TextBlockLoadingIndicator/Gradient';

import Wrapper from './Wrapper';
import { H3, H4 } from './Headlines';
import Item from './Item';
import Content from './Content';
import { AccessibilityAttributes, Attribute, EquipmentAttributes, InfoItem } from './Attributes';
import { ImageWrapper, ImageLink, Image } from './Image';
import Modal from '../Modal';
import Navigation from './Navigation';
import Button from './Button';
import ModalContent from './ModalContent';

export const labels = {
	barrierfreeAccess: 'Barrierefreier Zugang',
	highBoard: 'Hochbord',
	maneuveringSpace: 'Manövrierfläche',
	tactileGuidingElements: 'Taktile Leitelemente',
	seatings: 'Sitzmöglichkeiten',
	weatherProtection: 'Wetterschutz / Überdachung',
};

export const accessibilityProps = Object.keys(labels);
const accessibilityTypes = accessibilityProps.slice(0, 4);
const equipmentTypes = accessibilityProps.slice(4, 6);

export default function AccessibilityList({ items }) {
	const [activeItem, setActiveItem] = useState(null);
	const [showFullImage, setShowFullImage] = useState(false);
	const [index, setIndex] = useState(0);
	const [showInfo, setShowInfo] = useState(false);

	useEffect(() => {
		if (activeItem !== null) {
			const imageLoaded = () => {
				setShowFullImage(true);
			};
			const img = new window.Image();
			img.addEventListener('load', imageLoaded, false);
			img.src = activeItem.image.full;

			return () => {
				img.removeEventListener('load', imageLoaded, false);
			};
		}

		return () => {};
	}, [activeItem]);

	if (items.length === 0) {
		// No items found, show nothing
		return <></>;
	}

	const item = items[index];

	// Items found, show them
	return (
		<Wrapper>
			<H3>Bus-Steige</H3>
			<Item>
				<H4>{item.name}</H4>
				<Content>
					{accessibilityTypes.some(type => item[type] !== null) && (
						<AccessibilityAttributes>
							<InfoItem
								title="Weitere Informationen zur Barrierefreiheit"
								onClick={() => setShowInfo(true)}
								tabIndex="0"
							>
								?
							</InfoItem>
							{accessibilityTypes.map(type => {
								if (typeof item[type] !== 'boolean') {
									return false;
								}
								return (
									<Attribute
										key={`${item.globalId}-${type}`}
										role="checkbox"
										aria-checked={item[type] ? 'true' : 'false'}
										checked={item[type]}
									>
										{labels[type]}
									</Attribute>
								);
							})}
						</AccessibilityAttributes>
					)}
					{equipmentTypes.some(type => item[type] !== null) && (
						<EquipmentAttributes>
							{equipmentTypes.map(type => {
								if (typeof item[type] !== 'boolean') {
									return false;
								}
								return (
									<Attribute
										key={`${item.globalId}-${type}`}
										checked={item[type]}
									>
										{labels[type]}
									</Attribute>
								);
							})}
						</EquipmentAttributes>
					)}
					{item.image.thumb && item.image.full && (
						<ImageWrapper>
							<ImageLink
								href={item.image.full}
								onClick={e => {
									e.preventDefault();
									// Do sth in lightbox or sth.
									setActiveItem(item);
								}}
							>
								<Image
									src={item.image.thumb}
									alt={`Steig ${item.name} – Gesamtübersicht (Vorschaubild)`}
									width={120}
									height={160}
									radius="3px"
								/>
							</ImageLink>
						</ImageWrapper>
					)}
				</Content>
			</Item>
			{items.length > 1 && (
				<Navigation
					current={index + 1}
					length={items.length}
					onPrevious={() => {
						setIndex((index + items.length - 1) % items.length);
					}}
					onNext={() => {
						setIndex((index + 1) % items.length);
					}}
				/>
			)}
			{showInfo && (
				<Modal
					labelledBy="accessibility-title"
					describedBy="accessibility-content"
					width={600}
					onClose={() => setShowInfo(false)}
				>
					<H3 id="accessibility-title">Angaben zur Barrierefreiheit</H3>
					<ModalContent id="accessibility-content">
						<H4>{labels.barrierfreeAccess}</H4>
						<p>
							Ein stufenloser Zugang zur Haltestelle wird benötigt, um die jeweilige
							Bushaltestelle barrierefrei erreichen zu können. Haltestellenmast und{' '}
							<span>-möblierung</span> müssen so platziert sein, dass weder der
							Zu-/Abgang zur / von der Haltestelle noch der Einstieg ins bzw. der
							Ausstieg aus dem Fahrzeug für in ihrer Mobilität eingeschränkte Menschen
							verstellt wird.
						</p>

						<H4>{labels.highBoard}</H4>
						<p>
							Der Bordstein der jeweiligen Haltestelle weist eine Höhe von mindestens
							18 cm auf, so dass die Klapprampe für Rollstuhlfahrer normgerecht zum
							Einsatz kommen kann.
						</p>

						<H4>{labels.maneuveringSpace}</H4>
						<p>
							Für einen problemlosen Einstieg wird darüber hinaus im Aufstellbereich
							der Haltestelle eine ausreichende Manövrierfläche für Rollstühle und
							Kinderwagen benötigt. Die minimal notwendige Manövrierfläche von 2,5 m x
							1,5 m ergibt sich aus dem Bewegungsraum eines Rollstuhlnutzers (1,5 m x
							1,5 m) und der Länge der Klapprampe des Busses von ca. 1 m
						</p>

						<H4>{labels.tactileGuidingElements}</H4>
						<p>
							Taktile Bodenelemente und Leitstreifen sollen sehgeschädigten und
							blinden Menschen den Einstieg in den Bus erleichtern.
						</p>

						<p>
							<Button
								as="a"
								href="https://www.vvs.de/download/VVS-Empfehlungen%20barrierefreie%20Bushaltestellen.pdf"
								target="_blank"
								rel="noopener"
							>
								Broschüre barrierefreie Bushaltestelle
							</Button>
						</p>
					</ModalContent>
				</Modal>
			)}
			{activeItem !== null && (
				<Modal
					onClose={() => {
						setActiveItem(null);
						setShowFullImage(false);
					}}
				>
					{showFullImage ? (
						<Image
							src={showFullImage ? activeItem.image.full : activeItem.image.thumb}
							alt={`Steig ${activeItem.name} – Gesamtübersicht`}
						/>
					) : (
						<LoadingWrapper>
							<Image
								src={showFullImage ? activeItem.image.full : activeItem.image.thumb}
								alt={`Steig ${activeItem.name} – Gesamtübersicht`}
							/>
							<Gradient />
						</LoadingWrapper>
					)}
				</Modal>
			)}
		</Wrapper>
	);
}

AccessibilityList.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			globalId: PropTypes.string.isRequired,
			highBoard: PropTypes.bool,
			tactileGuidingElements: PropTypes.bool,
			barrierfreeAccess: PropTypes.bool,
			maneuveringSpace: PropTypes.bool,
			weatherProtection: PropTypes.bool,
			seatings: PropTypes.bool,
			image: PropTypes.shape({
				full: PropTypes.string,
				thumb: PropTypes.string,
			}).isRequired,
		}),
	).isRequired,
};
