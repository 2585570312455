import { LINE_LAYERS_NAME } from 'shared';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/src/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import * as olColor from 'ol/color';
import { colors, mapConfig } from '../../../utils/config';

// set variables to update layer style paint function
let layer = null;
let source = null;
let lastLines = null;

export default function loadLines(map, linesArray, typeFilter, zoomLevel) {
	/**
	 * A set of line ids of the lines that should be shown.
	 * (Globally, lines are an array. Locally, we want them as a set so that we can access them by id.)
	 *
	 * Is undefined if linesArray is undefined, and empty set if linesArray is defined but empty.
	 * Undefined or null means that no lines or all lines should be shown depending on the view.
	 * this is defined by the first implementation in the stops detail for clicking on line stops
	 */
	const lines = linesArray !== undefined && linesArray !== null ? new Set(linesArray) : null;
	if (!map) {
		// remove lines if zoomed out
		let allowedZoomlevel = mapConfig.minZoomLevel.stops + mapConfig.minZoom;
		// for disruptions different limit
		if (
			typeFilter &&
			Object.values(typeFilter.disruptions).some(filterValue => filterValue === 2)
		) {
			allowedZoomlevel = 0;
		}
		if (zoomLevel < allowedZoomlevel) {
			layer.setSource(
				new VectorSource({
					features: [],
				}),
			);
			// Only update layer if lines have been provided
			if (lines) {
				layer.set('newLines', lines);
				source.refresh();
			}

			return;
		}

		// checks for equality for last to new values
		// this is complicated because the lastLines are as set and only stringify doesn't work there
		const lastAndNewAreEqual =
			JSON.stringify(lines ? Array.from(lines) : lines) ===
			JSON.stringify(lastLines ? Array.from(lastLines) : lastLines);
		// if layer is set set lines as value and force repaint to get layer function
		if (layer && !lastAndNewAreEqual) {
			layer.set('newLines', lines);
			source.refresh();
		}

		checkFilter(typeFilter);

		// only refresh source on changes
		if (layer && !lastAndNewAreEqual) {
			source.refresh();
		}

		lastLines = lines;
		return;
	}
	// persisit new lines if they were set
	let newLines = null;
	if (layer) {
		newLines = layer.get('newLines');
		// remove layer before readding it
		// not sure if it is needed to generate a new layer here but the functionality of the map was changed and this is the safest way
		// to ensure that the map never has two layers here
		map.removeLayer(layer);
	}
	lastLines = lines;
	source = new VectorSource({
		url: '/geojson/linesV6.geojson',
		format: new GeoJSON(),
	});
	const style2 = new Style({
		fill: new Fill({
			color: '#fc03c2',
		}),
		stroke: new Stroke({
			color: '#fc03c2',
			width: 3,
		}),
	});
	layer = new VectorLayer({
		background: '#1a2b39',
		source,
		style(feature) {
			//set alpha 0 if no match
			const noMatchColor = olColor.asString([0, 0, 0, 0]);
			//check validity of date and don't show feature if not valid
			//validFrom
			// let validFrom = feature.get('validFrom');
			// let validUntil = feature.get('validUntil');
			// let fromDate = new Date();
			// const now = new Date();
			// if (validFrom) {
			// 	fromDate = parseDate(validFrom);
			// 	//valid from is in future -> dont show feature
			// 	if (fromDate > now) {
			// 		style2.getStroke().setColor(noMatchColor);
			// 		return;
			// 	}
			// }
			// if (validUntil) {
			// 	let toDate = parseDate(validUntil);
			// 	//valid to is in past -> dont show feature
			// 	if (toDate < now) {
			// 		style2.getStroke().setColor(noMatchColor);
			// 		return;
			// 	}
			// }
			const color = getLineColor(feature.get('text'), feature?.values_?.textDiva);
			if (color === colors.sbahn) {
				style2.setZIndex(2);
			} else {
				style2.setZIndex(1);
			}

			/* Determine which lines to show */

			/**
			 * Is true if a matching filter is set.
			 */
			let isMatch = false;
			/**
			 * Contains the ids of the lines that should be shown.
			 */
			let ids = new Set();
			/**
			 * Can contain the ids of the lines that should be shown.
			 * Is null if no filter is set.
			 */
			const newLines = layer.get('newLines');

			// if newlines is null there is no filter so show all lines and return
			if (!newLines) {
				// set color if we could find one
				if (color) {
					style2.getStroke().setColor(color);
				}
				return style2;
			}

			// if newlines is set, display them
			if (newLines) {
				ids = newLines;
			}

			// Only show lines with sufficient information
			// TODO: Improve matching for lines (there has to be a better way to map the ids)

			// textDiva matches part of line id
			if (ids.has(parseInt(`${feature.get('textDiva')}`, 10))) {
				isMatch = true;
			}
			// OR line is in list of lines to show
			else if (ids.has(feature.get('line'))) {
				// ... match by line name
				isMatch = true;
			} else if (ids.has(feature.get('id'))) {
				// ... match by line id
				isMatch = true;
			}

			// If line is a match, i.e., if it should be shown, set color to something visible
			if (isMatch) {
				// ... set to correct color by line type if we could find one
				if (color) {
					style2.getStroke().setColor(color);
				}
			} else {
				// ... set to transparent to hide the line
				style2.getStroke().setColor(noMatchColor);
			}
			return style2;
		},
	});
	if (newLines) {
		layer.set('newLines', newLines);
	}
	layer.set('name', LINE_LAYERS_NAME);
	map.addLayer(layer);
	checkFilter(typeFilter);
}

// Only show lines for specific filters
function checkFilter(typeFilter) {
	let hideLines = true;

	if (typeFilter?.stops?.Linien && typeFilter?.stops?.Linien !== 0) {
		// Show, if Lines are visible
		hideLines = false;
	} else if (
		typeFilter &&
		Object.values(typeFilter.disruptions).some(filterValue => filterValue === 2) && // type filter is set to disruptions
		layer.get('newLines') // Lines to display are specified (disruptions only display selected lines and never all lines)
	) {
		// Show, if disruptions are visible
		hideLines = false;
	}
	if (hideLines) {
		layer.setSource(
			new VectorSource({
				features: [],
			}),
		);
	} else {
		layer.setSource(source);
	}
}

// function parseDate(dateString) {
// 	let parsedDate = new Date(dateString);
// 	if (parsedDate.toString() === 'Invalid Date') {
// 		let dateArray = dateString.split('.');
// 		//check if date array is valid
// 		//handles only dates with format dd.mm.yyyy
// 		if (Array.isArray(dateArray) && dateArray.length === 3) {
// 			//dd.mm.yyyy -> new Date(year, monthIndex, day)
// 			// year -> index 2
// 			// month -> index 1, as month begins with zero parse to int and subtract 1
// 			// dd -> index 0
// 			parsedDate = new Date(dateArray[2], parseInt(dateArray[1]) - 1, dateArray[0]);
// 		}
// 	}
// 	return parsedDate;
// }

const getLineColor = (name, textDiva = '', defaultValue = false) => {
	// type = zacke
	if (name === '10' && textDiva === '21010_') {
		return colors.ubahn; // '#fcf403';
	}
	// type = seilbahn
	if (name === '20' && textDiva === '21020_') {
		return colors.ubahn; // '#fcf403';
	}
	// type = ubahn
	if (name.match(/^U\d+([AEae])?$/)) {
		return colors.ubahn; // '#0320fc';
	}
	// type = sbahn
	if (name.match(/^S\d+([AEae])?$/)) {
		return colors.sbahn; // '#03fc67';
	}
	// type = rbahn
	if (name.match(/^(IRE|R|RB|RE|MEX)\d+([AEae])?$/) || name.match(/^R-Bahn /)) {
		return colors.rbahn; // '#8a8a8a';
	}
	// type = taxi
	if (name.match(/^(RT|LT)\d+$/)) {
		return colors.bus; // '#8a8a8a';
	}
	// type = bus
	if (name.match(/^((N|X|BB)?\d+(\.\d+|[AaE])?|E)$/)) {
		return colors.bus; // '#fc0303';
	}
	return defaultValue;
};
