import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { colors, boxshadow } from '../../utils/config';

import SearchResultListItem from './SearchResultListItem';
import BoldSearchWords from './BoldSearchWords';

const StyledSearchResultList = styled.ul`
	position: absolute;
	top: 48px;
	left: 8px;
	margin: 0;
	overflow: auto;
	padding: 0;
	width: 96%;
	width: calc(100% - 16px);
	max-height: 330px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 8px;
	border-radius: 2px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-left: 1px solid ${colors.graylight};
	border-right: 1px solid ${colors.graylight};
	box-shadow: ${boxshadow.default};
	background-color: #fff;
	z-index: 40;

	${media.iphone`
		max-width: 358px;
	`}

	${media.lg`
		position: relative;
		top: 68px;
		left: auto;
		margin-left: 16px;
		margin-top: 16px;
		width: 460px;
		max-width: none;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(0.752);
		transform-origin: top left;
		top: 51px;
	}

	${media.xl`
		margin-left: 32px;
		margin-top: 32px;
	`}
`;

export default function SearchResultList({
	items,
	active,
	searchRegex,
	onSelect,
	setActive,
	className,
}) {
	return (
		<StyledSearchResultList className={className}>
			{items.map((item, i) => {
				if (item.type === 'category') {
					const title = (
						<BoldSearchWords label={item.shownLabel} searchRegex={searchRegex} />
					);
					return (
						<SearchResultListItem
							key={`category-${item.shownLabel.replace(/[^a-z0-9]/gi, '-')}`}
							title={title}
							subtitle="Kategorie"
							icon={item.icon}
							active={active === i}
							onClick={() => onSelect(item)}
							onMouseEnter={() => setActive(i)}
						/>
					);
				}

				if (item.type === 'tip') {
					const title = <BoldSearchWords label={item.title} searchRegex={searchRegex} />;

					return (
						<SearchResultListItem
							key={`tip-${item.id ? item.id : `index-${i}`}-${item.title.replace(
								/[^a-z0-9]/gi,
								'_',
							)}`}
							title={title}
							subtitle={item.address.city || 'Ausflugstip'}
							icon="tip"
							active={active === i}
							onClick={() => onSelect(item)}
							onMouseEnter={() => setActive(i)}
						/>
					);
				}
				if (item.type === 'city') {
					const title = <BoldSearchWords label={item.id} searchRegex={searchRegex} />;

					return (
						<SearchResultListItem
							key={`cityticket-${item.id ? item.id : `index-${i}`}`}
							title={title}
							subtitle={item.id}
							icon="citytickets"
							active={active === i}
							onClick={() => onSelect(item)}
							onMouseEnter={() => setActive(i)}
						/>
					);
				}

				const title = (
					<BoldSearchWords
						label={item.disassembledName || item.name}
						searchRegex={searchRegex}
					/>
				);
				return (
					<SearchResultListItem
						key={`${item.type}-${item.id.replace(/[^a-z0-9]/gi, '_')}`}
						title={title}
						subtitle={item.parent.name}
						icon={item.type}
						active={active === i}
						onClick={() => onSelect(item)}
						onMouseEnter={() => setActive(i)}
					/>
				);
			})}
		</StyledSearchResultList>
	);
}

SearchResultList.propTypes = {
	items: PropTypes.array,
	active: PropTypes.number,
	searchRegex: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	setActive: PropTypes.func.isRequired,
	className: PropTypes.string,
};

SearchResultList.defaultProps = {
	active: 0,
	className: '',
};
