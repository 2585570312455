import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';

import Button, { ButtonVariants } from '../../Button';
import CloseRounded from '../../assets/icons/CloseRounded.svg';
import SwapVertRounded from '../../assets/icons/SwapVertRounded.svg';
import { TEXT } from '../text';

const StyledResultSorting = styled.div`
	display: grid;
	grid-template-columns: min-content 1fr 150px;
	grid-template-areas: 'button . select';
	justify-content: space-between;
	position: sticky;
	top: 0;
	background-color: var(--GreyShade1, #fafafa);
	padding-bottom: var(--size_x1, 16px);
`;

const StyledButton = styled(Button)`
	font-size: 14px;
	font-weight: 400;
	white-space: nowrap;
	grid-area: button;
`;

const StyledReactSelect = styled(ReactSelect)`
	margin: 0;
	grid-area: select;

	// styled components messing up the class name of react select, check for the class name ending
	[class$='control'] {
		background-color: var(--GreyShade2, #ececec);
		border: none;
		border-radius: var(--size_x025, 4px);
		cursor: pointer;
	}
`;

const StyledIndicatorContainer = styled(components.IndicatorsContainer)`
	padding: var(--size_x025, 4px);
	img {
		width: var(--size_x15, 24px);
		height: var(--size_x15, 24px);
	}
`;

function IndicatorsContainer({ ...props }) {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<StyledIndicatorContainer {...props}>
			<img src={SwapVertRounded} alt={TEXT.RESULT_DETAIL.SETTINGS.SORT_IMG_ALT} />
		</StyledIndicatorContainer>
	);
}

function IndicatorSeparator() {
	return null;
}

export function ResultSettings({ onSortChange, sortOptions, selectedSortOption, onResetClick }) {
	return (
		<StyledResultSorting>
			<StyledButton
				type="button"
				onClick={onResetClick}
				variant={ButtonVariants.GHOST}
				icon={CloseRounded}
			>
				{TEXT.RESULT_DETAIL.SETTINGS.RESET_BUTTON}
			</StyledButton>

			<StyledReactSelect
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				options={sortOptions}
				value={selectedSortOption}
				onChange={onSortChange}
				components={{ IndicatorsContainer, IndicatorSeparator }}
			/>
		</StyledResultSorting>
	);
}

const SortOption = PropTypes.shape({
	key: PropTypes.string,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

ResultSettings.propTypes = {
	onSortChange: PropTypes.func.isRequired,
	onResetClick: PropTypes.func.isRequired,
	selectedSortOption: SortOption,
	sortOptions: PropTypes.arrayOf(SortOption).isRequired,
};
