import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const InfoMessage = styled.small`
	display: block;
	margin: 6px 0;
	padding: 0 32px;
	font-size: 12px;
	line-height: 1;
	opacity: 70%;

	${media.lg`
    font-size: 14px;
    padding: 0 48px;
`}
`;

export default InfoMessage;
