import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconBus from '../../assets/icons/bus.svg';
import IconTaxi from '../../assets/icons/taxi.svg';
import IconRBahn from '../../assets/icons/r-bahn.svg';
import IconSbahn from '../../assets/icons/s-bahn.svg';
import IconUbahn from '../../assets/icons/u-bahn.svg';
import IconZacke from '../../assets/icons/zacke.svg';
import IconSeilbahn from '../../assets/icons/seilbahn.svg';
import { getDmUriWithDeeplink } from '../../utils/vvsUris';
import { colors } from '../../utils/config';

const icons = {
	bus: IconBus,
	taxi: IconTaxi,
	rbahn: IconRBahn,
	sbahn: IconSbahn,
	ubahn: IconUbahn,
	zacke: IconZacke,
	seilbahn: IconSeilbahn,
};

const List = styled.ul`
	position: relative;
	display: flex;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	margin: 0 0 12px 0;
	padding: 0 0 0 48px;
	min-height: 32px;
	width: 100%;

	&::before {
		content: '';
		position: absolute;
		top: 2px;
		left: 0;
		display: block;
		height: 28px;
		width: 28px;
		background: left center no-repeat transparent;
		background-size: 100%;
	}

	${props => `
		&::before {
			background-image: url(${icons[props.type] || IconBus});
		}
	`}
`;

const Listitem = styled.li`
	list-style-type: none;
	margin: 0 4px 4px 0;
`;

export const Link = styled.a`
	border: 1px solid ${colors.grayblue};
	border-radius: 3px;
	color: ${colors.black};
	display: inline-block;
	padding: 4px 6px;
	text-decoration: none;

	&:hover {
		border-color: ${colors.black};
		cursor: pointer;
	}
`;

export default function LinesList({ lines, id }) {
	const output = [];
	// Fix the order of the line types
	['sbahn', 'rbahn', 'ubahn', 'bus', 'taxi', 'seilbahn', 'zacke'].forEach(type => {
		if (typeof lines[type] !== 'undefined') {
			output.push(
				<List key={`${id}-${type}`} type={type}>
					{lines[type].map(line => {
						const lineWithoutDirection = line.id
							.split(':')
							.slice(0, 2)
							.join(':')
							.concat(':::');
						return (
							<Listitem key={`${id}-${type}-${line.disassembledName}`}>
								<Link
									href={getDmUriWithDeeplink(id, lineWithoutDirection)}
									target="_blank"
									rel="noopener noreferrer"
									title={line.name}
								>
									{line.disassembledName}
								</Link>
							</Listitem>
						);
					})}
				</List>,
			);
		}
	});

	return <>{output}</>;
}

LinesList.propTypes = {
	lines: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
};
