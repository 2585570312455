import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { IconProps, IconCityTicketTile } from 'shared';

import media from '../../utils/Breakpoints';
import { fonts, colors, boxshadow } from '../../utils/config';

import ImageOepnv from '../../assets/menuimages/menuimage_oepnv.jpg';
import ImageSharing from '../../assets/menuimages/menuimage_sharing.jpg';
import ImageTips from '../../assets/menuimages/menuimage_tips.jpg';
import ImagePlaces from '../../assets/menuimages/menuimage_places.jpg';
import Imagecityticket from '../../assets/menuimages/menuimage_stadtticket.jpg';
import ImageDisruptions from '../../assets/menuimages/menuimage_disruptions.jpg';

import { onlyOnEnter } from '../../utils/events';

const StyledMenuTile = styled.a`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 0 0 104px;
	width: 104px;
	height: auto;
	margin-right: 8px;
	text-align: center;
	user-select: none;
	border-radius: 5px;
	background-color: ${colors.white};
	box-shadow: ${boxshadow.default};
	transform-origin: 50% 50%;
	transition: box-shadow 0.1s ease, transform 0.1s ease;
	will-change: transform;
	transform: translateZ(0);

	&:last-child {
		margin-right: 0;
	}

	&:focus,
	&:hover {
		cursor: pointer;
		box-shadow: ${boxshadow.hover};
		transform: scale(1.02) translateZ(0);
	}

	${media.lg`
		flex: 0 0 144px;
		height: 200px;
	`}
`;

const MediaWrapper = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 96px;
	border-radius: 5px;

	&&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 3px;
		background-color: ${props => props.borderColor};
	}

	${media.lg`
		height: 128px;
	`}

	img {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		transform: translateZ(0);
	}
`;
const StyledBadge = styled.div`
	position: absolute;
	width: 35px;
	height: 35px;
	top: -4px;
	right: -4px;
	z-index: 1;
	padding: 8px;
	background-color: ${colors.orange};
	border-radius: 999px;
	color: ${colors.white};
	text-align: center;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: 500;
`;

const Icon = styled.span`
	position: absolute;
	top: 80px;
	left: 50%;
	display: block;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: ${colors.white};
	box-shadow: ${boxshadow.menutileicon};
	transform: translate(-50%, 0);

	${media.lg`
		top: 104px;
		width: 42px;
		height: 42px;
	`}

	svg {
		transform: scale(0.8) translateZ(0);

		${media.lg`
			transform: none;
		`}
	}
`;

const Title = styled.h2`
	color: ${colors.black};
	display: flex;
	align-items: center;
	font-family: ${fonts.default};
	font-weight: normal;
	font-size: 11px;
	line-height: 13px;
	margin: 10px 0 4px;
	transform: translateZ(0);
	height: 39px;

	${({ wide }) =>
		wide
			? css`
					max-width: 80%;
			  `
			: css`
					max-width: 70%;
			  `}

	${media.lg`
		display: block;
		height: 32px;
		margin: 0 0 14px;
		font-size: 13px;
		line-height: 16px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(1.2);
		transform-origin: center;
	}
`;

export default function MenuTile({
	title,
	type,
	wideTitle,
	onClick,
	isNew = false,
	'data-tabindex': dataTabindex,
}) {
	let imageSource;
	let iconSource;
	let color;

	if (type === 'oepnv') {
		imageSource = ImageOepnv;
		iconSource = 'oepnv';
		color = `${colors.orange}`;
	} else if (type === 'sharing') {
		imageSource = ImageSharing;
		iconSource = 'sharing';
		color = `${colors.cyandark}`;
	} else if (type === 'tips') {
		imageSource = ImageTips;
		iconSource = 'tips';
		color = `${colors.green}`;
	} else if (type === 'places') {
		imageSource = ImagePlaces;
		iconSource = 'places';
		color = `${colors.purple}`;
	} else if (type === 'cityticket') {
		imageSource = Imagecityticket;
		iconSource = 'cityticket';
		color = `${colors.red}`;
	} else if (type === 'disruptions') {
		imageSource = ImageDisruptions;
		iconSource = 'disruption';
		color = `${colors.danger}`;
	}

	return (
		<StyledMenuTile
			onClick={onClick}
			onKeyDown={onlyOnEnter(onClick)}
			data-type={type}
			data-tabindex={dataTabindex}
		>
			{isNew && (
				<StyledBadge>
					<span>Neu</span>
				</StyledBadge>
			)}
			<MediaWrapper borderColor={color}>
				<img src={imageSource} alt={title} />
			</MediaWrapper>
			<Icon>
				{IconProps[iconSource] && IconProps[iconSource].path && (
					<svg
						viewBox={IconProps[iconSource].viewBox}
						width={IconProps[iconSource].width}
						height={IconProps[iconSource].height}
					>
						<path d={IconProps[iconSource].path} fill={color} />
					</svg>
				)}
				{iconSource === 'cityticket' && <IconCityTicketTile></IconCityTicketTile>}
			</Icon>
			<Title wide={wideTitle}>{title}</Title>
		</StyledMenuTile>
	);
}

MenuTile.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.oneOf([
		'oepnv',
		'sharing',
		'rundumsrad',
		'tips',
		'places',
		'cityticket',
		'disruptions',
	]).isRequired,
	isNew: PropTypes.bool,
	wideTitle: PropTypes.bool,
	onClick: PropTypes.func,
	'data-tabindex': PropTypes.number,
};
