import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BaseButton = styled.button`
	display: flex;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	border: none;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	transition: opacity 0.2s ease-in-out;
	cursor: pointer;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

const ButtonPrimary = styled(BaseButton)`
	background: var(--VVS-Orange, #f60);
	color: var(--TrueWhite, #fff);
`;

const ButtonIcon = styled(BaseButton)`
	background: transparent;
	color: var(--TrueBlack, #000);
	padding: 0.5rem;

	svg,
	img {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

const ButtonGhost = styled(BaseButton)`
	padding: 0.45rem 0.5rem;
	color: var(--TrueBlack, #000);
	background: var(--GreyShade1, #fafafa);
	border-radius: 4px;
	border: 1px var(--GreyShade3, #d9d9d9) solid;
`;

const StyledButtonGhostIcon = styled.img`
	width: 1rem;
	height: 1rem;
	border: 0.1rem var(--GreyShade3, #d9d9d9) solid;
	background-color: var(--GreyShade3, #d9d9d9);
	border-radius: 50%;
`;

export const ButtonVariants = {
	PRIMARY: 'primary',
	ICON: 'icon',
	GHOST: 'ghost',
};

/**
 * A button component. This is a wrapper around the native HTML button element.
 * It will just pass the children and props on to the button element.
 * The component just adds some css styling.
 *
 * The parameter variant is used to determine the styling of the button.
 * If you want to add an icon, just pass it as a child.
 */
export default function Button({ children, variant, ...props }) {
	switch (variant) {
		// Allow prop spreading in this file, because we don't want to interfere with the
		// browser's button built in behaviour
		/* eslint-disable react/jsx-props-no-spreading */
		case ButtonVariants.PRIMARY: {
			return <ButtonPrimary {...props}>{children}</ButtonPrimary>;
		}
		case ButtonVariants.ICON: {
			return <ButtonIcon {...props}>{children}</ButtonIcon>;
		}
		case ButtonVariants.GHOST: {
			return (
				<ButtonGhost {...props}>
					{children}
					{props.icon && <StyledButtonGhostIcon src={props.icon} />}
				</ButtonGhost>
			);
		}
		/* eslint-enable react/jsx-props-no-spreading */

		default: {
			console.warn(`Unknown Button variant \`${variant}\``);
			return null;
		}
	}
}

Button.defaultProps = {
	variant: 'primary',
};

Button.propTypes = {
	variant: PropTypes.oneOf(Array.from(Object.values(ButtonVariants))),
};
