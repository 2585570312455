import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { boxshadow } from '../../utils/config';

import Header from './Header';

const StyledOffCanvasMenu = styled.div`
	background-color: #fff;
	position: fixed;
	top: 0;
	right: 0;
	max-width: 362px;
	width: 100%;
	height: 100%;
	transform: translateX(100%);
	transition: transform ease 0.5s;
	z-index: 40;

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: translateX(100%) scale(0.752);
		transform-origin: top right;
	}

	&.opened {
		box-shadow: ${boxshadow.default};
		transform: translateX(0);

		@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
			transform: scale(0.752);
		}
	}

	${media.lg`
		max-width: 432px;
	`}
`;

const Content = styled.div`
	height: 95%;
	height: calc(100% - 64px);
	overflow-y: auto;
	padding: 32px 0 0 0;

	${media.lg`
		height: calc(100% - 96px);
	`}
`;

const OffCanvasMenu = React.forwardRef(({ onClose, 'data-tabindex': tabIndex, children }, ref) => (
	<StyledOffCanvasMenu ref={ref}>
		<Header onClose={onClose} data-tabindex={tabIndex}>
			Sichtbare Elemente
		</Header>
		<Content>{Children.toArray(children)}</Content>
	</StyledOffCanvasMenu>
));

OffCanvasMenu.propTypes = {
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	'data-tabindex': PropTypes.number,
};

export default OffCanvasMenu;
