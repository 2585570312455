import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { fetchers } from 'shared';

import MarkerContext from '../../../contexts/MarkerContext';
import {
	DisruptionTypeOptions,
	TransportOptions,
	SortOptions,
	SortFn,
	TransportTypeIcon,
	DisruptionMenuDefaults,
	DisruptionClusterClickEventType,
	DisruptionType,
	extendTransportType,
	SearchTypeIcon,
	OtherIcons,
} from './disruption-menu.config';
import { AllOption } from '../../new-components/Menu/Inputs/MultiSelect';

/**
 * @param {Array<{ value: string }>} typeValue
 */
function typeValueToFilter(typeValue) {
	if (typeValue.at(0) === AllOption) {
		return {
			Aufzüge: 2,
			'Baustellen und Umleitungen': 2,
			Störungen: 2,
		};
	}

	return {
		Aufzüge: typeValue.find(({ value }) => value === DisruptionType.ELEVATOR) ? 2 : 0,
		'Baustellen und Umleitungen': typeValue.find(({ value }) => value === DisruptionType.INFO)
			? 2
			: 0,
		Störungen: typeValue.find(({ value }) => value === DisruptionType.DISRUPTION) ? 2 : 0,
	};
}

/**
 * @param {string} dateValue
 */
function dateValueToFilter(dateValue) {
	const from = new Date(dateValue);
	const to = new Date(dateValue);
	to.setDate(from.getDate() + 1);
	return { from, to };
}

/**
 * null means all transport types are valid and remove the filter completely
 * @param {Array<{ value: number }> | null} typeValue
 */
function transportValueToFilter(transportValue) {
	if (transportValue.at(0) === AllOption) {
		return null;
	}
	return transportValue.flatMap(({ value }) => extendTransportType(value));
}

/**
 * Enumeration for search option types.
 * @typedef SearchOptionType
 * @enum {string}
 */
const SearchOptionType = {
	LOCATION: 'LOCATION',
	LINE: 'LINE',
};

/**
 * @typedef {Object} SearchOption
 * @property {string} key
 * @property {enum<'LOCATION', 'LINE'>} type
 * @property {string} label
 * @property {string} value
 * @property {[number, number]?} coord - only when type is 'LOCATION' (Format: [lon, lat])
 * @property {number?} line - only when type is 'LINE'
 * @property {string} icon
 */

/**
 * @async
 * @param {string} input
 * @returns {Promise<SearchOption[]>}
 */
async function searchLocations(input) {
	const results = await fetchers.searchLocations(input);
	if (!results.locations) return [];

	return results.locations.map(location => ({
		key: location.id,
		type: SearchOptionType.LOCATION,
		label: location.name,
		value: location.id,
		coord: location.coord,
		icon: SearchTypeIcon[location.type] || SearchTypeIcon.street,
	}));
}

/**
 * @param {object} line
 * @param {string} line.name
 * @param {number} index
 * @param {object[]} lines
 * @returns {boolean}
 */
function filterOnwWayLines(line, index, lines) {
	return lines.findIndex(({ name }) => name === line.name) !== index;
}

/**
 * @param {string} id
 * @return {number}
 */
function extractLine(id) {
	return parseInt(id.split(':').at(1), 10);
}

/**
 * @async
 * @param {string} input
 * @returns {Promise<SearchOption[]>}
 */
async function searchLines(input) {
	const results = await fetchers.searchLines(input);
	if (!results.lines) return [];

	return (
		results.lines
			// filter one way
			.filter(filterOnwWayLines)
			.map(line => ({
				key: line.id,
				type: SearchOptionType.LINE,
				label: line.name,
				value: line.id,
				line: extractLine(line.id),
				icon: TransportTypeIcon[line.product.id],
			}))
	);
}

/**
 * @param {number[] | number} linesArg
 * @returns {function(number[]): number[] | null}
 * null means that no lines should be shown otherwise array of line ids as numbers
 */
function addLines(linesArg) {
	const lines = Array.isArray(linesArg) ? linesArg : [linesArg];
	return prevLines => {
		const newLines = [...(prevLines ?? []), ...lines];
		return newLines?.length > 0 ? newLines : null;
	};
}

/**
 * @param {number[] | number} linesArgs
 * @returns {function(number[]): number[]}
 * null means that no lines should be shown otherwise array of line ids as numbers
 */
function removeLines(linesArg) {
	const lines = Array.isArray(linesArg) ? linesArg : [linesArg];
	return prevLines => {
		const filtered = prevLines?.filter(line => !lines.includes(line));
		return filtered?.length > 0 ? filtered : null;
	};
}

/**
 * Hook for managing the state and interactions of the disruption menu.
 *
 * @param {function} updateMap
 * @param {function} updateView
 * @param {function(map, SearchOption): void} addDisruptionSearchMarker
 */
export function useDisruptionMenu(updateMap, updateView, addDisruptionSearchMarker) {
	const { setFilters, setMarker, setLines, setDisruptionsMarkers } = useContext(MarkerContext);
	const [dateValue, setDateValue] = useState(DisruptionMenuDefaults.DATE);
	const [typeValue, setTypeValue] = useState(DisruptionMenuDefaults.TYPE);
	const [transportValue, setTransportValue] = useState(DisruptionMenuDefaults.TRANSPORT);
	const [sorting, setSorting] = useState(DisruptionMenuDefaults.SORTING);
	const [internalFeatures, setInternalFeatures] = useState([]);
	const searchItemRef = useRef(null);
	const selectedItemRef = useRef(null);

	useEffect(() => {
		// only add filters for transport type if it is not the all value
		// otherwise filters won't work correctly and might remove elevators
		const transportFilter = transportValueToFilter(transportValue);
		setFilters(
			{
				disruptions: typeValueToFilter(typeValue),
				date: dateValueToFilter(dateValue),
				...(transportFilter && { product: transportFilter }),
				filterType: 'disruptions',
				markerType: 'disruptions',
			},
			() => updateMap(),
		);
	}, [setFilters, updateMap, typeValue, dateValue, transportValue]);

	useEffect(() => {
		function handleEvent({ detail: { features } }) {
			setInternalFeatures(features);
		}
		window.addEventListener(DisruptionClusterClickEventType, handleEvent);

		return () => {
			window.removeEventListener(DisruptionClusterClickEventType, handleEvent);
			addDisruptionSearchMarker();
		};
	}, [addDisruptionSearchMarker]);

	const items = useMemo(() => {
		return internalFeatures
			.map(marker => {
				return {
					/* eslint-disable no-underscore-dangle */
					id: marker.ol_uid, // marker._values.id contains ids
					type: marker.values_.type,
					transport: marker.values_.affectedLines?.[0]?.product?.id || '',
					title: marker.values_.subject,
					subtitle: marker.values_.subtitle,
					text: marker.values_.content,
					icon:
						TransportTypeIcon[marker.values_.affectedLines?.[0]?.product?.id] ||
						OtherIcons[marker.values_.type] ||
						'',
					// remove the time from the dates
					validFrom: Date.parse(marker.values_.validFrom.toJSON().slice(0, 10)),
					validTo: Date.parse(marker.values_.validTo.toJSON().slice(0, 10)),
					lines: marker.values_.affectedLines?.map?.(({ id }) => parseInt(id, 10)),
					/* eslint-enable no-underscore-dangle */
				};
			})
			.sort(SortFn[sorting.value]);
	}, [internalFeatures, sorting]);

	const handleSelectItem = useCallback(
		async item => {
			if (selectedItemRef.current && selectedItemRef.current.lines) {
				setLines(removeLines(selectedItemRef.current.lines));
			}
			const marker = item
				? internalFeatures.find(feature => feature.ol_uid === item.id)
				: null;

			await new Promise(res => setMarker(marker, res));
			await new Promise(res => setLines(addLines(item?.lines || []), res));
			selectedItemRef.current = item;
			updateView();
		},
		[internalFeatures, setMarker, setLines, updateView],
	);

	const handleReset = useCallback(() => setInternalFeatures([]), []);

	const handleClose = useCallback(async () => {
		await new Promise(res => setMarker(null, res));
		await new Promise(res => setFilters(null, res));
		await new Promise(res => setLines(null, res));
		setDisruptionsMarkers([]);

		updateView();

		const updatedUrl = window.location.href.replace('disruptions', '');
		window.history.pushState({}, document.title, updatedUrl);
	}, [setFilters, setMarker, setLines, setDisruptionsMarkers, updateView]);

	/**
	 * @returns {function(string): Promise<SearchOption[]>}
	 */
	const loadOptions = useCallback(async inputValue => {
		const lines = await searchLines(inputValue);
		const locations = await searchLocations(inputValue);

		return [...lines, ...locations];
	}, []);

	const handleSearchChange = useCallback(
		/**
		 * @async
		 * @param {SearchOption} item
		 * @returns {void}
		 */
		async item => {
			if (searchItemRef.current) {
				if (searchItemRef.current.type === SearchOptionType.LOCATION) {
					addDisruptionSearchMarker();
				} else if (searchItemRef.current.type === SearchOptionType.LINE) {
					await new Promise(res =>
						setLines(removeLines(searchItemRef.current.line), res),
					);
				}
			}
			searchItemRef.current = item;
			if (item !== null) {
				if (item.type === SearchOptionType.LOCATION) addDisruptionSearchMarker(item);
				else if (item.type === SearchOptionType.LINE) {
					await new Promise(res => setLines(addLines(item.line), res));
				}
			}
			updateView();
		},
		[addDisruptionSearchMarker, setLines, updateView],
	);

	return {
		handleClose,
		loadOptions,
		handleSearchChange,
		dateValue,
		handleDateChange: setDateValue,
		typeValue,
		handleTypeChange: setTypeValue,
		typeOptions: DisruptionTypeOptions,
		transportValue,
		handleTransportChange: setTransportValue,
		transportOptions: TransportOptions,
		sorting,
		handleSortingChange: setSorting,
		sortOptions: SortOptions,
		handleReset,
		items,
		handleSelectItem,
	};
}
