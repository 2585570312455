import { searchCategories } from '../../../utils/config';

export default (marker, markerContext) => {
	try {
		const selectedMarker = marker;
		selectedMarker.type = 'pr';
		selectedMarker.name = marker.vvsTitle;
		selectedMarker.id = marker.vvsId;
		markerContext.setContext({
			marker: selectedMarker,
			filters: searchCategories.stops.filter,
		});

		document.querySelector('#searchinput').blur();
	} catch (e) {
		// Do nothing on errors
	}
};
