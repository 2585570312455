import elevatorIcon from '../../../assets/disruption/disruptions-elevator.svg';
import infoIcon from '../../../assets/disruption/disruptions-info.svg';
import disruptionsIcon from '../../../assets/disruption/disruptions.svg';
import busIcon from '../../../assets/icons/bus.svg';
import rbahnIcon from '../../../assets/icons/r-bahn.svg';
import sbahnIcon from '../../../assets/icons/s-bahn.svg';
import seilbahnIcon from '../../../assets/icons/seilbahn.svg';
import taxiIcon from '../../../assets/icons/taxi.svg';
import ubahnIcon from '../../../assets/icons/u-bahn.svg';
import zackeIcon from '../../../assets/icons/zacke.svg';

import stopIcon from '../../../assets/icons/stop.svg';
import poiIcon from '../../../assets/icons/poi.svg';
import addressIcon from '../../../assets/icons/address.svg';

import { AllOption } from '../../new-components/Menu/Inputs/MultiSelect';

export const LABEL = {
	TITLE: 'Meldungen',
	TYPE: {
		OPTION: {
			DISRUPTION: 'Störung',
			INFO: 'Baustelle',
			ELEVATOR: 'Aufzug',
		},
	},
	TRANSPORT: {
		OPTION: {
			SBAHN: 'S-Bahn',
			RBAHN: 'R-Bahn',
			UBAHN: 'U-Bahn',
			ZACKE: 'Zacke',
			SEILBAHN: 'Seilbahn',
			BUS: 'Bus',
			TAXI: 'Taxi',
		},
	},
	SORT: {
		OPTION: {
			ALPHABET: 'alphabetisch',
			DATE: 'nach Datum',
		},
	},
};

/**
 * @enum {string}
 */
export const DisruptionType = {
	DISRUPTION: 'disruptions',
	INFO: 'disruptionsInfo',
	ELEVATOR: 'disruptionsElevator',
};

/**
 * @type {Array<{ value: string, label: string, icon: string }>}
 */
export const DisruptionTypeOptions = [
	{
		key: DisruptionType.DISRUPTION,
		value: DisruptionType.DISRUPTION,
		label: LABEL.TYPE.OPTION.DISRUPTION,
		icon: disruptionsIcon,
	},
	{
		key: DisruptionType.INFO,
		value: DisruptionType.INFO,
		label: LABEL.TYPE.OPTION.INFO,
		icon: infoIcon,
	},
	{
		key: DisruptionType.ELEVATOR,
		value: DisruptionType.ELEVATOR,
		label: LABEL.TYPE.OPTION.ELEVATOR,
		icon: elevatorIcon,
	},
];

/**
 * @enum {number}
 */
export const TransportType = {
	SBAHN: 0,
	RBAHN: 1,
	UBAHN: 2,
	ZACKE: 3,
	SEILBAHN: 4,
	BUS: 5,
	NACHTBUS: 6, // => 5
	TAXI: 7,
	RUFTAXI: 8, // => 7
	BUERGERBUS_BUS: 12, // => 5
	SEV_BUS: 13, // => 5
	SEV_RUFTAXIS: 14, // => 7
	EXPRESS_BUS: 15, // => 5
};

/**
 * @type {Array<number>}
 */
const TransportTypeBus = [
	TransportType.BUS,
	TransportType.NACHTBUS,
	TransportType.BUERGERBUS_BUS,
	TransportType.SEV_BUS,
	TransportType.EXPRESS_BUS,
];

/**
 * @type {Array<number>}
 */
const TransportTypeTaxi = [TransportType.TAXI, TransportType.RUFTAXI, TransportType.SEV_RUFTAXIS];

/**
 * @param {number} type
 * @return {Array<number>}
 */
export function extendTransportType(type) {
	if (type === TransportType.BUS) return TransportTypeBus;
	if (type === TransportType.TAXI) return TransportTypeTaxi;
	return [type];
}

/**
 * @type {Object.<number, string>}
 */
export const TransportTypeIcon = {
	0: sbahnIcon, // 'sbahn'
	1: rbahnIcon, // 'rbahn'
	2: ubahnIcon, // 'ubahn'
	3: zackeIcon, // 'zahnradbahn'
	4: seilbahnIcon, // 'seilbahn'
	5: busIcon, // 'bus'
	6: busIcon, // 'nachtbus'
	8: taxiIcon, // 'ruftaxi'
	16: rbahnIcon,
};

/**
 * @type {Object.<number, string>}
 */
export const OtherIcons = {
	disruptionsElevator: elevatorIcon, // 'elevatorIcon'
};

/**
 * @type {Array<{ value: string, label: string, icon: string }>}
 */
export const TransportOptions = [
	{
		key: TransportType.SBAHN,
		value: TransportType.SBAHN,
		label: LABEL.TRANSPORT.OPTION.SBAHN,
		icon: sbahnIcon,
	},
	{
		key: TransportType.RBAHN,
		value: TransportType.RBAHN,
		label: LABEL.TRANSPORT.OPTION.RBAHN,
		icon: rbahnIcon,
	},
	{
		key: TransportType.UBAHN,
		value: TransportType.UBAHN,
		label: LABEL.TRANSPORT.OPTION.UBAHN,
		icon: ubahnIcon,
	},
	{
		key: TransportType.BUS,
		value: TransportType.BUS,
		label: LABEL.TRANSPORT.OPTION.BUS,
		icon: busIcon,
	},
	{
		key: TransportType.TAXI,
		value: TransportType.TAXI,
		label: LABEL.TRANSPORT.OPTION.TAXI,
		icon: taxiIcon,
	},
	{
		key: TransportType.ZACKE,
		value: TransportType.ZACKE,
		label: LABEL.TRANSPORT.OPTION.ZACKE,
		icon: zackeIcon,
	},
	{
		key: TransportType.SEILBAHN,
		value: TransportType.SEILBAHN,
		label: LABEL.TRANSPORT.OPTION.SEILBAHN,
		icon: seilbahnIcon,
	},
];

/**
 * @enum {string}
 */
export const SortType = {
	ALPHA: 'ALPHA',
	DATE: 'DATE',
};

/**
 * @type {Array<{ key: string, label: string, value: string }>}
 */
export const SortOptions = [
	{ key: SortType.ALPHA, label: LABEL.SORT.OPTION.ALPHABET, value: SortType.ALPHA },
	{ key: SortType.DATE, label: LABEL.SORT.OPTION.DATE, value: SortType.DATE },
];

/**
 * @type {Object.<SortType, Function>}
 */
export const SortFn = {
	/**
	 * @type {Function}
	 * @param {Object} a
	 * @param {Object} b
	 * @returns {number}
	 */
	[SortType.ALPHA]: (a, b) => a.title.localeCompare(b.title),

	/**
	 * @type {Function}
	 * @param {Object} a
	 * @param {Object} b
	 * @returns {number}
	 */
	[SortType.DATE]: (a, b) => a.date - b.date,
};

export const DisruptionMenuDefaults = {
	SEARCH: null,
	// today
	DATE: new Date().toJSON().slice(0, 10),
	TYPE: [AllOption],
	TRANSPORT: [AllOption],
	SORTING: SortOptions[0],
};

export const DisruptionClusterClickEventType = 'disruptionClusterClick';
export function dispatchDisruptionClusterClickEvent(detail) {
	const event = new CustomEvent(DisruptionClusterClickEventType, { detail });
	window.dispatchEvent(event);
}

export const SearchTypeIcon = {
	poi: poiIcon,
	stop: stopIcon,
	suburb: addressIcon,
	street: addressIcon,
};
