import React from 'react';
import PropTypes from 'prop-types';
import CategoryList from '.';
import { nameToIcon } from '../../utils/config';
// import { nameToIcon } from '../../utils/config';

export default function TippsList({
	selectedMarker,
	items,
	limit,
	setMarker,
	setLines,
	isButtonVisible,
	hasCategoryMenuOnTop,
}) {
	let index = false;
	let selectedMarkerId = null;
	if (selectedMarker !== null && selectedMarker.type === 'tip') {
		selectedMarkerId = selectedMarker.id;
	}

	const adjustedItems = items.map((item, i) => {
		const id = nameToIcon(`${item.id || 0}-${item.title || 'notitle'}`, true);
		if (id === selectedMarkerId) {
			index = i;
		}

		return {
			id,
			title: item.title,
			// subtitle: item.category,
			image: item.image && item.image.src,
			imageAlt:
				item.image &&
				`${item.image.copyright}${item.image.copyright && item.image.title ? ' – ' : ''}${
					item.image.title
				}`,
			introtext: item.introtext,
			address: (
				<>
					{item.address.street}
					<br />
					{item.address.zipcode} {item.address.city}
				</>
			),
			phone: item.address.phone,
			email: item.address.email,
			content: item.longtext,
			externallinktext: 'Mehr Informationen zum Ausflugsziel',
			externallink: item.link,
			// icon: nameToIcon(item.category),
			type: 'tip',
		};
	});

	return (
		<CategoryList
			selectedIndex={index}
			listtype="pois"
			items={adjustedItems}
			limit={limit}
			setMarker={setMarker}
			setLines={setLines}
			marker={selectedMarker}
			isButtonVisible={isButtonVisible}
			hasCategoryMenuOnTop={hasCategoryMenuOnTop}
			showList
		/>
	);
}

TippsList.propTypes = {
	selectedMarker: PropTypes.object,
	items: PropTypes.array,
	limit: PropTypes.number,
	setMarker: PropTypes.func.isRequired,
	setLines: PropTypes.func.isRequired,
	isButtonVisible: PropTypes.bool,
	hasCategoryMenuOnTop: PropTypes.bool,
};

TippsList.defaultProps = {
	limit: 5,
	isButtonVisible: false,
	hasCategoryMenuOnTop: false,
};
