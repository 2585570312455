import React, { useCallback, useMemo, useState } from 'react';

import NineDotsIcon from '../../assets/icons/NineDots.svg';
import { Select } from './Select';
import { TEXT } from '../text';

// See https://react-select.com/props#prop-types
const REACT_SELECT_SELECT_OPTION = 'select-option';

const ALL_OPTION_KEY = 'MULTI_SELECT_ALL';

export const AllOption = {
	key: ALL_OPTION_KEY,
	label: TEXT.SELECT.ALL.LABEL_OPTION,
	valueLabel: TEXT.SELECT.ALL.LABEL_SELECT,
	icon: NineDotsIcon,
	value: ALL_OPTION_KEY,
};

function useMultiSelect({ value, options, onChange }) {
	const [multiValue, setMultiValue] = useState(value);

	const multiOptions = useMemo(() => [AllOption, ...options], [options]);

	const handleChange = useCallback(
		(values, { action, option }) => {
			if (action === REACT_SELECT_SELECT_OPTION) {
				if (option.key === ALL_OPTION_KEY) {
					const nextValue = [AllOption];
					setMultiValue(nextValue);
					onChange(nextValue);
				} else {
					const nextValue = values.filter(({ key }) => key !== ALL_OPTION_KEY);
					setMultiValue(nextValue);
					onChange(nextValue);
				}
			} else {
				setMultiValue(values);
				onChange(values);
			}
		},
		[onChange],
	);

	return { multiValue, multiOptions, handleChange };
}

export function MultiSelect({ label, value, options, onChange }) {
	const { multiValue, multiOptions, handleChange } = useMultiSelect({ value, options, onChange });

	return (
		<Select
			isMulti
			label={label}
			value={multiValue}
			options={multiOptions}
			onChange={handleChange}
		/>
	);
}

MultiSelect.ALL_OPTION = ALL_OPTION_KEY;

MultiSelect.propTypes = {
	label: Select.propTypes.label,
	value: Select.propTypes.value,
	options: Select.propTypes.options,
	onChange: Select.propTypes.onChange,
};
