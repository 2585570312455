import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledLabel } from './styled';
import { TEXT } from '../text';
import {
	StyledReactSelect,
	Control,
	Option,
	SingleValue,
	IndicatorSeparator,
	ValueContainer,
	ClearIndicator,
} from './StyledSelectComponents';

const StyledSelect = styled.div`
	display: grid;
	grid-template-areas:
		'label'
		'select';
	gap: var(--size_x05, 8px);
`;

export function Select({ label, value, options, isMulti = false, onChange }) {
	return (
		<StyledSelect>
			<StyledLabel>{label}</StyledLabel>
			<StyledReactSelect
				openMenuOnClick
				placeholder={TEXT.SELECT.PLACE_HOLDER}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				isMulti={isMulti}
				value={value}
				options={options}
				isSearchable={false}
				components={{
					Control,
					Option,
					SingleValue,
					IndicatorSeparator,
					ValueContainer,
					ClearIndicator,
				}}
				onChange={onChange}
				onSelect={onChange}
			/>
		</StyledSelect>
	);
}

const SelectOption = PropTypes.shape({
	key: PropTypes.any,
	icon: PropTypes.string,
	label: PropTypes.string.isRequired,
	valueLabel: PropTypes.string,
	value: PropTypes.any.isRequired,
});

Select.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([SelectOption, PropTypes.arrayOf(SelectOption)]), // Assuming value is a string, adjust as needed
	isMulti: PropTypes.bool,
	options: PropTypes.arrayOf(SelectOption).isRequired,
	onChange: PropTypes.func.isRequired,
};
