import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { disruptionsClassifier, fetchers, IconProps, productIconIdToLineType } from 'shared';

import media from '../../utils/Breakpoints';
import { colors, boxshadow } from '../../utils/config';
import { stringToDate } from '../../utils/dateFunctions';

import DepartureTable from '../DepartureTable';

import TextBlockLoadingIndicator from '../TextBlockLoadingIndicator';
import LinesList, { Link } from './LinesList';
import { getDmUriWithDeeplink } from '../../utils/vvsUris';
import AccessibilityList, { accessibilityProps } from '../AccessibilityList';
import MarkerContext from '../../contexts/MarkerContext';

import disruptionSVG from '../../assets/icons/disruption.svg';
import timetableChangeSVG from '../../assets/icons/timetableChange.svg';
import elevatorSVG from '../../assets/icons/elevator.svg';

export const StyledCategoryItemStopDetail = styled.div`
	width: 100%;
	margin: 8px auto 8px auto;
	padding: 8px;
	border-radius: 5px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	z-index: 20;

	${media.md`
		position: relative;
		top: auto;
		left: auto;
		width: 358px;
		margin-top: 0;
		margin-left: 32px;
		border: 1px solid ${colors.graylight};
		box-shadow: ${boxshadow.default};
		background-color: ${colors.white};
	`}

	${media.lg`
		width: 458px;
		margin-bottom: 48px;
	`}
`;

const Iconwrapper = styled.div`
	position: absolute;
	top: 26px;
	right: 14px;
	width: 36px;
	height: 36px;

	&:hover {
		cursor: pointer;
	}

	${media.lg`
		right: 20px;
	`}

	svg {
		margin: 8px 0 0 8px;
	}
`;

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	max-width: 280px;
	margin: 10px 0 32px 0;

	${media.lg`
		width: 374px;
		max-width: none;
		margin: 18px 0 32px 0;
	`}
`;

const StopIcon = styled.div`
	display: block;
	flex: 0 0 56px;
	border: 0;
	user-select: none;

	${media.lg`
		flex: 0 0 88px;
	`}

	svg {
		display: block;
		margin: 0 auto;
	}
`;

const Title = styled.h2`
	font-size: 21px;
	line-height: 27px;
`;

const CardBody = styled.div`
	padding: 0 16px 32px 56px;
	width: 100%;
	max-width: 100%;

	${media.lg`
		padding-left: 88px;
	`}

	> p {
		font-size: 18px;
		margin-top: 32px;

		+ p {
			margin-top: 16px;
		}
	}
`;

const StyledDisruptionDetails = styled.div`
	header {
		display: flex;
		> h4 {
			margin-top: 0.2rem;
			margin-left: 28px;

			@media (min-width: 1280px) {
				margin-left: 60px;
			}
		}
	}
	> ul {
		list-style: none;
		padding-left: 56px;
		margin: 0;
		margin-bottom: 2rem;

		@media (min-width: 1280px) {
			padding-left: 88px;
		}

		> li {
			margin-bottom: 1rem;
		}
	}
`;

// const productToLineType = {
// 	Bus: 'bus',
// 	Bürgerbus: 'bus',
// 	Nachtbus: 'bus',
// 	Stadtbahn: 'ubahn',
// 	'S-Bahn': 'sbahn',
// 	'R-Bahn': 'rbahn',
// 	Ruftaxi: 'taxi',
// 	Linientaxi: 'taxi',
// 	Seilbahn: 'seilbahn',
// 	Zahnradbahn: 'zacke',
// };

const getLineType = (line, defaultValue = false) => {
	// // Handle special case for On-Demand
	// if (line.product.name === 'On-Demand') {
	// 	return 'onDemand';
	// }

	const type = productIconIdToLineType[line.product.iconId];
	// const type = productToLineType[line.product.name];
	if (typeof type !== 'undefined') {
		return type;
	}

	// TODO: Leave as fallback. Remove later
	if (line.name.match(/Zahnradbahn/i)) {
		return 'zacke';
	}
	if (line.name.match(/Seilbahn/i)) {
		return 'seilbahn';
	}
	if (line.disassembledName.match(/^U\d+([AEae])?$/)) {
		return 'ubahn';
	}
	if (line.disassembledName.match(/^S\d+([AEae])?$/)) {
		return 'sbahn';
	}
	if (line.disassembledName.match(/^(IRE|R|RB)\d+([AEae])?$/) || line.name.match(/^R-Bahn /)) {
		return 'rbahn';
	}
	if (line.disassembledName.match(/^(RT|LT)\d+$/)) {
		return 'taxi';
	}
	if (line.disassembledName.match(/^((N|X|BB)?\d+(\.\d+|[AaE])?|E)$/)) {
		return 'bus';
	}
	return defaultValue;
};

const DisruptionItemPropType = PropTypes.shape({
	id: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	timestamps: PropTypes.shape({
		availability: PropTypes.shape({
			from: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
		}),
	}),
});

function formatDateString(dateString) {
	return new Intl.DateTimeFormat('de-DE').format(new Date(dateString));
}

function DisruptionListItem({ item }) {
	const availability = item?.timestamps?.availability;
	const { from, to } = useMemo(
		() => ({
			from: availability?.from ? formatDateString(availability?.from) : '',
			to: availability?.to ? formatDateString(availability?.to) : '',
		}),
		[availability],
	);

	return (
		<li>
			<h4>{item.subtitle}</h4>
			{(from || to) && (
				<p>
					{from && `von ${from}`}
					{to && ` bis ${to}`}
				</p>
			)}
			<p>{item.content}</p>
		</li>
	);
}

DisruptionListItem.propTypes = {
	item: DisruptionItemPropType,
};

const iconsMap = {
	[disruptionsClassifier.CLASSES.Störung]: disruptionSVG,
	[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen]: timetableChangeSVG,
	[disruptionsClassifier.CLASSES.Aufzüge]: elevatorSVG,
};

function DisruptionDetails({ items }) {
	const info = useMemo(
		() =>
			items.reduce((red, item) => {
				const infoType = disruptionsClassifier.classify(item);
				return {
					...red,
					[infoType]: [...(red[infoType] || []), item],
				};
			}, {}),
		[items],
	);

	if (items.length === 0) return null;

	return (
		<StyledDisruptionDetails>
			{Object.keys(info).map(disruptionsClass => (
				<>
					<header>
						<img src={iconsMap[disruptionsClass]} alt={disruptionsClass} />
						<h4>{disruptionsClass}</h4>
					</header>
					<ul>
						{info[disruptionsClass].map(item => (
							<DisruptionListItem key={item.id} item={item} />
						))}
					</ul>
				</>
			))}
		</StyledDisruptionDetails>
	);
}

DisruptionDetails.propTypes = {
	items: PropTypes.arrayOf(DisruptionItemPropType),
};
export default function CategoryItemStopDetail({ stopId, title, onClose, gisId }) {
	function getAbortController() {
		if ('AbortController' in window) {
			return new window.AbortController();
		}
		return false;
	}

	const [lines, setLines] = useState(false);
	const [accessibility, setAccessibility] = useState(false);
	const [departures, setDepartures] = useState(false);
	const [stopPointAccessibilities, setStopPointAccessibilities] = useState(false);
	const [disruptions, setDisruptions] = useState([]);

	const { setLines: setContextLines } = useContext(MarkerContext);

	const minute = Math.floor(Date.now() / 1000 / 60);

	useEffect(() => {
		// Reset state if the stopId changed
		setLines(false);
		setAccessibility(false);
		setStopPointAccessibilities(false);

		const abortController = getAbortController();
		const signal = abortController?.signal;

		// Fetch Lines
		fetchers
			.fetchLinesById(stopId, 'map-stop', { signal })
			.then(vvsLines => {
				const newLines = {};
				const now = new Date();
				// this is now an array because of newer logic being added elsewhere
				// this only fixes some immediate bugs
				const contextLines = [];
				vvsLines.forEach(line => {
					const type = getLineType(line);
					// create date objects to control against now
					const from = new Date(line.properties.validity.from);
					const to = new Date(line.properties.validity.to);

					if (from > now || now > to) {
						// Line is not (yet) valid
						return;
					}

					if (type === false) {
						// Skip mixed lines
						return;
					}

					if (line.destination?.id) {
						const idArray = line.id.split(':'); // vvs:30040: :H:j22:1
						let parsedId = idArray.length > 2 ? idArray[1] : null;
						// add exception for Zackebus as it shouldn't have the same id
						if (parsedId === '21010' && line.name.indexOf('Zackebus') !== -1) {
							parsedId = 30033;
						}
						parsedId = parseInt(parsedId);
						if (parsedId) {
							contextLines.push(parsedId);
						}
					}

					// if (line.number.match(/\//) && !line.disassembledName.match(/^MEX/)) {
					// 	// Skip lines with multiple numbers, if it is no MEX line
					// 	return;
					// }

					if (typeof newLines[type] === 'undefined') {
						newLines[type] = [line];
					} else if (
						!newLines[type].some(
							oldLine => oldLine.disassembledName === line.disassembledName,
						)
					) {
						newLines[type].push(line);
					}
				});
				// this doesn't update the view anymore which will lead to updates being ignored
				// solving this needs a bigger refactor
				setContextLines(contextLines);
				setLines(newLines);
			})
			.catch(e => {
				if (e.name === 'AbortError') {
					return;
				}
				// console.error(e);
				setLines([]);
			});

		// Fetch stop accessibility (maps / pdfs)
		fetchers
			.fetchAccessibility([stopId], { signal })
			.then(response => {
				if (response[stopId]) {
					setAccessibility(response[stopId]);
				} else {
					setAccessibility({});
				}
			})
			.catch(e => {
				if (e.name === 'AbortError') {
					return;
				}
				// console.error(e);
				setAccessibility({});
			});

		// Fetch stop point accessibilities
		fetchers
			.fetchStopPointAccessibilities(stopId, { signal })
			.then(response => {
				if (response.success === true) {
					const items = response.data.filter(
						item =>
							accessibilityProps.some(prop => item[prop] !== null) ||
							item.image.full !== null,
					);
					setStopPointAccessibilities(items);
				} else {
					setStopPointAccessibilities([]);
				}
			})
			.catch(e => {
				if (e.name === 'AbortError') {
					return;
				}
				// console.error(e);
				setStopPointAccessibilities([]);
			});

		return () => {
			if (abortController) {
				abortController.abort();
			}
			setContextLines(null);
		};
	}, [stopId]);

	// Fetch departures separately, as we want to renew them every minute
	useEffect(() => {
		// Reset state if the stopId changed
		setDepartures(false);

		const abortController = getAbortController();
		const signal = abortController?.signal;

		// Fetch departures
		fetchers
			.fetchDepartures(`${stopId}&m=${minute}`, 5, { signal })
			.then(stopEvents => {
				// Do sth with the departures
				const newDepartures = stopEvents.map(stopEvent => {
					const datePlanned = stringToDate(stopEvent.departureTimePlanned);
					const dateReal = stringToDate(
						stopEvent.departureTimeEstimated || stopEvent.departureTimePlanned,
					);
					const timeplanned = datePlanned.toLocaleTimeString('de-DE');
					const timereal = dateReal.toLocaleTimeString('de-DE');

					return {
						id: `${stopEvent.transportation.properties.tripCode}-${stopEvent.departureTimePlanned}-${stopEvent.transportation.number}`,
						timeplanned: timeplanned.substr(0, timeplanned.length - 3),
						timereal: timereal.substr(0, timereal.length - 3),
						title: stopEvent.transportation.disassembledName,
						subtitle: stopEvent.transportation.destination.name,
						icon: getLineType(stopEvent.transportation, ''),
						link: getDmUriWithDeeplink(
							stopEvent.location.id,
							stopEvent.transportation.id,
						),
						locationinfo: stopEvent.location.disassembledName,
						isRealtimeControlled: stopEvent.isRealtimeControlled,
						isCancelled: !!stopEvent.isCancelled,
					};
				});
				setDepartures(newDepartures);
			})
			.catch(e => {
				if (e.name === 'AbortError') {
					return;
				}
				// console.error(e);
				setDepartures([]);
			});

		// Fetch stop point accessibilities
		fetchers
			.fetchStopPointAccessibilities(stopId, { signal })
			.then(response => {
				if (response.success === true) {
					const items = response.data.filter(
						item =>
							accessibilityProps.some(prop => item[prop] !== null) ||
							item.image.full !== null,
					);
					setStopPointAccessibilities(items);
				} else {
					setStopPointAccessibilities([]);
				}
			})
			.catch(e => {
				if (e.name === 'AbortError') {
					return;
				}
				// console.error(e);
				setStopPointAccessibilities([]);
			});

		fetchers
			.fetchDisruptions({ stopId: gisId }, 2, { signal, fetchAdditionalData: false })
			.then(setDisruptions)
			.catch(e => {
				if (e.name === 'AbortError') return;
				setDisruptions([]);
			});

		return () => {
			if (abortController) {
				abortController.abort();
			}
		};
	}, [stopId, gisId, minute]);

	let linesOutput;
	if (lines !== false) {
		linesOutput = <LinesList lines={lines} id={stopId} />;
	} else {
		linesOutput = <TextBlockLoadingIndicator rows={3} />;
	}

	let accessibilityOutput;
	if (accessibility !== false) {
		if (accessibility.map || accessibility.a_map) {
			// Handle accessibility
			accessibilityOutput = [];

			if (accessibility.map) {
				accessibilityOutput.push(
					<p key={`${stopId}-accessibility-1`}>
						<Link
							href={`https://www3.vvs.de/Download/EnvMaps/vvs/${accessibility.map}`}
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => {
								if (typeof _paq !== 'undefined') {
									// eslint-disable-next-line no-underscore-dangle
									window._paq.push([
										'trackEvent',
										'LivKarte', // Category
										'haltestellenkarte', // Action
										'Nahverkehr', // Name
										title, // Value
									]);
								}
							}}
						>
							Haltestellenkarte
						</Link>
					</p>,
				);
			}
			if (accessibility.a_map) {
				accessibilityOutput.push(
					<p key={`${stopId}-accessibility-2`}>
						<Link
							href={`https://www3.vvs.de/Download/EnvMaps/vvs/${accessibility.a_map}`}
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => {
								if (typeof _paq !== 'undefined') {
									// eslint-disable-next-line no-underscore-dangle
									window._paq.push([
										'trackEvent',
										'LivKarte', // Category
										'barrierefreiewegeführung', // Action
										'Nahverkehr', // Name
										title, // Value
									]);
								}
							}}
						>
							Barrierefreie Wegeführung
						</Link>
					</p>,
				);
			}
		}
	} else {
		accessibilityOutput = <TextBlockLoadingIndicator rows={3} />;
	}

	let stopPointData;
	if (stopPointAccessibilities !== false) {
		stopPointData = <AccessibilityList items={stopPointAccessibilities} />;
	} else {
		stopPointData = <TextBlockLoadingIndicator rows={3} />;
	}

	let departuresList;
	if (departures !== false) {
		departuresList = <DepartureTable items={departures} />;
	} else {
		departuresList = (
			<>
				<TextBlockLoadingIndicator className="full-width" rows={1} />
				<TextBlockLoadingIndicator className="full-width" rows={1} />
				<TextBlockLoadingIndicator className="full-width" rows={1} />
				<TextBlockLoadingIndicator className="full-width" rows={1} />
				<TextBlockLoadingIndicator className="full-width" rows={1} />
			</>
		);
	}

	return (
		<StyledCategoryItemStopDetail>
			<Iconwrapper
				onClick={event => {
					onClose(event);
					setContextLines(null);
				}}
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
					<g fill="#097fac" fillRule="evenodd">
						<path d="M2.1214 0l16.9705 16.9706-2.1213 2.1213L.0001 2.1214z" />
						<path d="M16.9706 0L0 16.9707l2.1213 2.1214L19.092 2.1214z" />
					</g>
				</svg>
			</Iconwrapper>
			<TextWrapper>
				<StopIcon>
					<svg width="36" height="36" viewBox="0 0 36 36">
						<circle
							stroke="#009110"
							strokeWidth="3"
							fill="#FFF200"
							cx="18"
							cy="18"
							r="16.5"
						/>
						<path d={IconProps.stopWithoutOutline.path} fill="#009110" />
					</svg>
				</StopIcon>
				<Title>{title}</Title>
			</TextWrapper>
			<CardBody>
				{linesOutput}
				{accessibilityOutput}
			</CardBody>
			{stopPointData}
			{departuresList}
			<DisruptionDetails items={disruptions} />
		</StyledCategoryItemStopDetail>
	);
}

CategoryItemStopDetail.propTypes = {
	stopId: PropTypes.string.isRequired,
	gisId: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func,
};
