import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fetchers } from 'shared';

import VVSIcon from '../VVSIcon';

import { SearchBar, Input, SearchBarIcon, SearchLoading } from './SearchBar';
import SearchResultList from './SearchResultList';

import escapeRegExp from '../../utils/escapeRegExp';
import { searchCategories, nameToIcon } from '../../utils/config';

import MarkerContext from '../../contexts/MarkerContext';

import { ReactComponent as Loupe } from './icons/loupe.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { CategoryListWrapper } from '../CategoryList';

const StyledSearch = styled.div`
	position: relative;

	&.active ~ ${CategoryListWrapper} {
		display: none;
	}
`;

class Search extends Component {
	constructor(props) {
		super(props);

		this.value = props.value;

		this.state = {
			active: 0,
			loading: false,
			results: [],
			scrollToActiveItem: false,
		};
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillUpdate(nextProps) {
		if (this.props.value !== nextProps.value) {
			this.value = nextProps.value;
			this.searchInputElement.value = nextProps.value;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.active !== this.state.active && this.state.scrollToActiveItem) {
			const resultList = this.searchWrapper.querySelector('.result-list');
			if (resultList === null) return;
			const activeItem = resultList.querySelector('.active');
			if (activeItem === null) return;
			const { offsetTop } = activeItem;
			resultList.scrollTo({
				top: offsetTop - this.props.scrollOffset,
				behavior: 'smooth',
			});
		}
	}

	handleSearchChange = event => {
		const { value } = event.target;
		this.value = value;

		if (this.abortController) {
			this.abortController.abort();
		}
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			if (value === '') {
				this.setState({ results: [], loading: false });
				return;
			}
			let signal;
			if ('AbortController' in window) {
				this.abortController = new window.AbortController();
				signal = this.abortController.signal;
			}
			let results = [];
			const searchRegex = new RegExp(escapeRegExp(value), 'i');

			if (value.length > 2) {
				Object.keys(searchCategories).forEach(searchCategory => {
					const labels = [searchCategories[searchCategory].label]
						.concat(searchCategories[searchCategory].customLabels || [])
						.filter(label => !!label.match(searchRegex));
					if (labels.length > 0) {
						const result = {
							...searchCategories[searchCategory],
							shownLabel: labels[0],
							type: 'category',
						};
						results.push(result);
					}
				});
			}
			this.setState({ results, loading: true });
			const cityTicketItems = JSON.parse(window.localStorage.getItem('cityticket'));
			if (Array.isArray(cityTicketItems)) {
				const cityTicketHitIndex = cityTicketItems.findIndex(
					city => city.id.toLowerCase() === value.toLowerCase(),
				);
				if (cityTicketHitIndex > -1) {
					const cityTicketResult = cityTicketItems[cityTicketHitIndex];
					cityTicketResult.label = cityTicketResult.id;
					results.push(cityTicketResult);
				}
			}
			fetchers
				.searchLocationsAndTipps(value, { signal })
				.then(response => {
					results = results.concat(response.locations).concat(response.tipps || []);
					return fetchers.searchLines(value, { signal });
				})
				.then(response => {
					response.lines = response?.lines?.map(line => {
						line.parent = {
							name: line?.destination?.name ?? '',
						};
						return line;
					});
					results = results.concat(response.lines || []);
					this.setState({ results, loading: false });
					return results;
				})
				.catch(error => {
					if (error.name === 'AbortError') {
						// Fetch aborted
					} else {
						// eslint-disable-next-line no-console
						console.error('Uh oh, an error!', error);
						this.setState({ results, loading: false });
					}
				});
		}, 300);
	};

	handleIconKeyDown = e => {
		if (this.value) {
			if (e.key === 'Enter' || e.key === ' ' || e.keyCode === 13 || e.keyCode === 32) {
				this.handleDeleteInput();
				e.target.blur();
			}
		}
	};

	handleDeleteInput = async () => {
		if (this.abortController) {
			this.abortController.abort();
		}
		clearTimeout(this.searchTimeout);

		// Wait for state update to propagate before calling parent's event handlers
		// to avoid unexpected race conditions
		await new Promise(resolve =>
			this.setState({ results: [], loading: false, active: 0 }, resolve),
		);
		this.value = '';
		await new Promise(resolve =>
			this.context.setContext({ filters: null, marker: null }, resolve),
		);
		this.searchInputElement.value = '';
		this.searchInputElement.focus();

		this.props.onCategorySelect(null);
	};

	handleKeyDown = e => {
		let handle = false;
		if (e.key !== undefined) {
			if (e.key === 'Enter') {
				handle = true;
				this.selectActiveResult();
			} else if (e.key === 'ArrowUp') {
				handle = true;
				this.highlightPreviousResult();
			} else if (e.key === 'ArrowDown') {
				handle = true;
				this.highlightNextResult();
			} else if (e.key === 'Escape') {
				handle = true;
				this.handleDeleteInput();
			}
		} else if (e.keyCode !== undefined) {
			if (e.keyCode === 13) {
				handle = true;
				this.selectActiveResult();
			} else if (e.keyCode === 38) {
				handle = true;
				this.highlightPreviousResult();
			} else if (e.keyCode === 40) {
				handle = true;
				this.highlightNextResult();
			} else if (e.keyCode === 27) {
				handle = true;
				this.handleDeleteInput();
			}
		}

		if (handle) {
			e.preventDefault();
		}
	};

	selectActiveResult = () => {
		const { results, active } = this.state;
		if (results.length > 0) {
			this.selectResult(results[active]);
			this.searchInputElement.blur();
		}
	};

	highlightPreviousResult = () => {
		const { results, active } = this.state;
		const { length } = results;
		this.setState({
			active: (active + length - 1) % length,
			scrollToActiveItem: true,
		});
	};

	highlightNextResult = () => {
		const { results, active } = this.state;
		const { length } = results;
		this.setState({
			active: (active + length + 1) % length,
			scrollToActiveItem: true,
		});
	};

	highlightResult = active => {
		this.setState({ active, scrollToActiveItem: false });
	};

	selectResult = selectedResult => {
		const { onSelect, onCategorySelect } = this.props;

		if (this.abortController) {
			this.abortController.abort();
		}
		clearTimeout(this.searchTimeout);
		this.setState({ loading: false, results: [] });

		if (selectedResult.type === 'category') {
			this.searchInputElement.value = selectedResult.shownLabel;
			this.value = selectedResult.shownLabel;
			this.setState({
				results: [],
				active: 0,
			});
			this.context.setContext({ marker: null, filters: selectedResult.filter });
			onCategorySelect(selectedResult);

			if (typeof _paq !== 'undefined') {
				// eslint-disable-next-line no-underscore-dangle
				window._paq.push([
					'trackEvent',
					'LivKarte', // Category
					'suche', // Action
					'LivKarte', // Name
					`Kategorie ${this.value}`,
				]);
			}
			return;
		}

		if (selectedResult.type === 'tip') {
			this.searchInputElement.value = searchCategories.tipps.label;
			this.value = searchCategories.tipps.label;
			this.setState({ results: [], active: 0 });
			const resultId = nameToIcon(
				`${selectedResult.id || 0}-${selectedResult.title || 'notitle'}`,
				true,
			);
			// eslint-disable-next-line no-param-reassign
			selectedResult.id = resultId;
			this.context.setContext({
				marker: selectedResult,
				filters: searchCategories.tipps.filter,
			});
		} else if (selectedResult.type === 'stop') {
			this.searchInputElement.value = searchCategories.stops.label;
			this.value = searchCategories.stops.label;
			this.setState({ results: [], active: 0 });
			this.context.setContext({
				marker: selectedResult,
				filters: searchCategories.stops.filter,
			});
		} else if (selectedResult.type === 'city') {
			this.searchInputElement.value = searchCategories.cityticket.label;
			this.value = searchCategories.cityticket.label;
			this.setState({ results: [], active: 0 });
			this.context.setContext({
				marker: selectedResult,
				filters: searchCategories.cityticket.filter,
			});
		} else if (
			selectedResult.type === 'suburb' ||
			selectedResult.type === 'street' ||
			selectedResult.type === 'singlehouse'
		) {
			const value = selectedResult.disassembledName
				? `${selectedResult.disassembledName}, ${selectedResult.parent.name}`
				: selectedResult.name;
			this.searchInputElement.value = value;
			this.value = value;
			this.setState({ results: [], active: 0 });
			this.context.setContext({ marker: selectedResult, filters: null });
		} else if (selectedResult.type === 'poi') {
			this.searchInputElement.value = searchCategories.pois.label;
			this.value = searchCategories.pois.label;
			const marker = {
				...selectedResult,
				id: selectedResult.id
					.split(':')
					.slice(0, 3)
					.join(':')
					.concat(':-1'),
			};
			this.setState({
				results: [],
				active: 0,
			});
			this.context.setContext({
				marker,
				filters: searchCategories.pois.filter,
			});
			fetchers.fetchPOIById(marker.id).then(poi => {
				if (poi !== null) {
					const poiCategory = nameToIcon(poi.category);
					if (searchCategories[`pois-${poiCategory}`]) {
						this.context.setFilters(searchCategories[`pois-${poiCategory}`].filter);
					}
				}
			});
		} else {
			return;
		}

		this.searchInputElement.blur();

		if (typeof _paq !== 'undefined') {
			// eslint-disable-next-line no-underscore-dangle
			window._paq.push([
				'trackEvent',
				'LivKarte', // Category
				'suche', // Action
				'LivKarte', // Name
				this.value,
			]);
		}

		if (onSelect) {
			onSelect(selectedResult);
		}
	};

	/* eslint-disable lines-between-class-members */
	abortController = null;
	searchTimeout = null;
	searchWrapper = null;
	value = '';
	/* eslint-enable lines-between-class-members */

	render() {
		const { active, results, loading } = this.state;
		const { value } = this;
		const { autoFocus, positionrelative, categoryMenuVisible } = this.props;
		const searchRegex = new RegExp(escapeRegExp(value), 'gi');

		let icon = <Loupe />;
		if (value !== '') {
			icon = <Delete />;
		}

		return (
			<StyledSearch
				className={autoFocus ? 'active' : ''}
				ref={ref => {
					this.searchWrapper = ref;
				}}
			>
				<SearchBar
					positionrelative={positionrelative}
					categoryMenuVisible={categoryMenuVisible}
					searchResultListVisible={results.length > 0}
				>
					<VVSIcon />
					<Input
						type="text"
						data-tabindex={1}
						id="searchinput"
						placeholder="O‌rt, Haltestelle, A‌dresse oder POI"
						defaultValue={value}
						onFocus={() =>
							this.searchWrapper && this.searchWrapper.classList.add('active')
						}
						onBlur={() =>
							this.searchWrapper && this.searchWrapper.classList.remove('active')
						}
						onKeyDown={this.handleKeyDown}
						onChange={e => {
							this.handleSearchChange(e);
						}}
						ref={element => {
							this.searchInputElement = element;
						}}
						autoComplete="off"
						autoFocus={autoFocus}
					/>
					{loading && <SearchLoading />}
					<SearchBarIcon
						data-tabindex={2}
						onKeyDown={e => {
							this.handleIconKeyDown(e);
						}}
						onClick={() => {
							this.handleDeleteInput();
						}}
					>
						{icon}
					</SearchBarIcon>
				</SearchBar>
				<SearchResultList
					className="result-list"
					items={results}
					active={active}
					searchRegex={searchRegex}
					onSelect={this.selectResult}
					setActive={newActive => this.highlightResult(newActive)}
				/>
			</StyledSearch>
		);
	}
}

Search.contextType = MarkerContext;

Search.propTypes = {
	autoFocus: PropTypes.bool,
	value: PropTypes.string,
	scrollOffset: PropTypes.number,
	positionrelative: PropTypes.bool,
	searchResultListVisible: PropTypes.bool,
	categoryMenuVisible: PropTypes.bool,
	onCategorySelect: PropTypes.func,
	onSelect: PropTypes.func,
};

Search.defaultProps = {
	value: '',
	scrollOffset: 150,
	onCategorySelect: () => {},
	onSelect: () => {},
};

export default Search;
