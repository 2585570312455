import checkUrl from './checkUrl';
import fetchAccessibility from './fetchAccessibility';
import fetchAllStops from './fetchAllStops';
import fetchBikeRoute, { getSavedParamsByType } from './fetchBikeRoute';
import fetchCoordData from './fetchCoordData';
import fetchCoords from './fetchCoords';
import fetchDepartures from './fetchDepartures';
import fetchDisruptions from './fetchDisruptions';
import fetchLinesById from './fetchLinesById';
import fetchLocationData from './fetchLocationData';
import fetchLocationInfo from './fetchLocationInfo';
import fetchParkObjectById from './fetchParkObjectById';
import fetchParkObjects from './fetchParkObjects';
import fetchPOIById from './fetchPOIById';
import fetchPOIs from './fetchPOIs';
import fetchPOIsByArea from './fetchPOIsByArea';
import fetchPositions from './fetchPositions';
import fetchSharingOperators from './fetchSharingOperators';
import fetchSharingStations from './fetchSharingStations';
import fetchStopPointAccessibilities from './fetchStopPointAccessibilities';
import fetchThemeRoutes, {
	fetchThemeRouteById,
	fetchThemeRoutesMetaById,
} from './fetchThemeRoutes';
import fetchTipps from './fetchTipps';
import fetchTippsInArea from './fetchTippsInArea';
import searchLocations from './searchLocations';
import searchLocationsAndTipps from './searchLocationsAndTipps';
import searchLines from './searchLines';

export {
	checkUrl,
	fetchAccessibility,
	fetchAllStops,
	fetchBikeRoute,
	getSavedParamsByType,
	fetchCoordData,
	fetchCoords,
	fetchDepartures,
	fetchDisruptions,
	fetchLinesById,
	fetchLocationData,
	fetchLocationInfo,
	fetchParkObjectById,
	fetchParkObjects,
	fetchPOIById,
	fetchPOIs,
	fetchPOIsByArea,
	fetchPositions,
	fetchSharingOperators,
	fetchSharingStations,
	fetchStopPointAccessibilities,
	fetchThemeRoutes,
	fetchThemeRouteById,
	fetchThemeRoutesMetaById,
	fetchTipps,
	fetchTippsInArea,
	searchLocations,
	searchLocationsAndTipps,
	searchLines,
};

export default {
	checkUrl,
	fetchAccessibility,
	fetchAllStops,
	fetchBikeRoute,
	getSavedParamsByType,
	fetchCoordData,
	fetchCoords,
	fetchDepartures,
	fetchDisruptions,
	fetchLinesById,
	fetchLocationData,
	fetchLocationInfo,
	fetchParkObjectById,
	fetchParkObjects,
	fetchPOIById,
	fetchPOIs,
	fetchPOIsByArea,
	fetchPositions,
	fetchSharingOperators,
	fetchSharingStations,
	fetchStopPointAccessibilities,
	fetchThemeRoutes,
	fetchThemeRouteById,
	fetchThemeRoutesMetaById,
	fetchTipps,
	fetchTippsInArea,
	searchLocations,
	searchLocationsAndTipps,
	searchLines,
};
