import { API_BASE } from './constants';

const linesByIdCache = {};

/**
 * Fetches lines by stop id
 * WARNING: This does NOT fetch lines by line id, but by stop id
 *
 * @param {string} id Stop id
 */
export default async (id, pkKwd = '', fetchOptions = {}) => {
	if (typeof linesByIdCache[id] !== 'undefined') {
		return linesByIdCache[id];
	}

	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	const params = {
		SpEncId: 0,
		deleteAssignedStops: 1,
		lineReqType: 1,
		locationServerActive: 1,
		lsShowTrainsExplicit: 1,
		mergeDir: 1,
		mode: 'odv',
		outputFormat: 'rapidJSON',
		serverInfo: 0,
		type_sl: 'stopID',
		name_sl: id,
		withoutTrains: 0,
		pk_campaign: 'map',
		pk_kwd: pkKwd,
	};

	linesByIdCache[id] = fetch(
		`${API_BASE}XML_SERVINGLINES_REQUEST?${Object.keys(params)
			.map((key) => `${key}=${params[key]}`)
			.join('&')}`,
		fetchOptions,
	)
		.then((response) => response.json())
		.then((json) => json.lines);
	return linesByIdCache[id];
};
