import { searchCategories } from '../../../utils/config';

export default (marker, markerContext) => {
	try {
		const selectedMarker = marker;
		selectedMarker.type = 'tip';
		selectedMarker.id = marker.vvsid;
		selectedMarker.address = marker.introtext;
		markerContext.setContext({
			marker: selectedMarker,
			filters: searchCategories.tipps.filter,
		});

		document.querySelector('#searchinput').blur();
	} catch (e) {
		// Do nothing on errors
	}
};
