import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconProps } from 'shared';

import media from '../../utils/Breakpoints';
import { fonts, colors, boxshadow } from '../../utils/config';

const StyledCategoryItemPoiDetail = styled.div`
	height: auto;
	margin: 0 auto 8px;
	padding: 8px;
	border-radius: 5px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;

	${media.md`
		position: relative;
		top: auto;
		left: auto;
		width: 358px;
		border: 1px solid ${colors.graylight};
		box-shadow: ${boxshadow.default};
		background-color: ${colors.white};
	`}

	${media.lg`
		width: 458px;
	`}
`;

const TextWrapper = styled.div`
	position: relative;
	max-width: 290px;
	margin-right: 80px;
	margin-left: 56px;

	${media.md`
		margin-right: 130px;
	`}

	${media.lg`
		margin-left: 88px;
		margin-right: 130px;
	`}
`;

const BackButton = styled.button`
	position: absolute;
	top: 7px;
	left: -64px;
	display: block;
	width: 62px;
	height: 62px;
	border: 0;
	user-select: none;
	background-image: url("data:image/svg+xml,%3Csvg width='15' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.377 2.63L2.13 12.878l10.246 10.246' stroke='%23097fac' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;

	&:hover {
		cursor: pointer;
	}

	${media.lg`
		top: 14px;
		left: -88px;
	`}
`;

const Title = styled.h2`
	font-family: ${fonts.default};
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	margin-top: 16px;

	${media.lg`
		font-size: 21px;
		line-height: 27px;
		margin-top: 24px;
	`}
`;

const Subtitle = styled.p`
	font-size: 15px;
	line-height: 19px;
	color: ${colors.graydark};

	${media.md`
		font-size: 18px;
		line-height: 23px;
	`}
`;

const Iconwrapper = styled.div`
	position: absolute;
	top: 22px;
	right: 26px;
	width: 48px;
	height: 48px;
`;

const MediaWrapper = styled.div`
	img {
		position: absolute;
		top: 8px;
		right: 8px;
		display: block;
		width: 72px;
		height: 72px;
		overflow: hidden;
		border-radius: 5px;
		background-color: ${colors.graylight};
		object-fit: cover;

		${media.md`
			width: 72px;
			height: 72px;
		`}

		${media.lg`
			width: 130px;
			height: 130px;
		`}
	}
`;

const CardBody = styled.div`
	width: 100%;
	font-size: 15px;
	line-height: 19px;
	margin-top: 32px;
	margin-left: 56px;
	padding-right: 16px;
	padding-bottom: 24px;

	${media.lg`
		margin-left: 88px;
	`}
`;

const Address1 = styled.p`
	margin-bottom: 18px;
	position: relative;
	max-width: 210px;

	&::before {
		content: '';
		display: block;
		width: 14px;
		height: 18px;
		position: absolute;
		top: 0;
		left: -36px;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.95 12.477L7 17.389l-4.95-4.912c-2.733-2.764-2.733-7.252 0-10.015a6.95 6.95 0 019.9 0c2.733 2.763 2.733 7.251 0 10.015z' fill='%2398AAB3' fill-rule='nonzero'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
	}
`;

const Address2 = styled.p`
	margin-bottom: 36px;
`;

const Introtext = styled.p`
	font-family: ${fonts.bold};
	font-weight: normal;
	margin-bottom: 18px;
`;

const ContentWrapper = styled.div`
	position: relative;
	max-height: 200px;
	overflow: hidden;
	padding-right: 12px;
	overflow-y: scroll;

	${'' /* &::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 300px;
		width: 100%;
		height: 100px;
		background: linear-gradient(hsla(0, 0%, 100%, 0), #fff 100%);
	} */}
`;

const Content = styled.div`
	ul {
		margin-top: 0;
		padding-left: 20px;
	}

	h3 {
		font-weight: 800;
		margin-bottom: 19px;
	}
`;

const ExternalLink = styled.a`
	position: relative;
	display: block;
	text-decoration: none;
	color: ${colors.cyandark};
	margin-top: 24px;

	&::before {
		content: '';
		display: block;
		width: 18px;
		height: 18px;
		position: absolute;
		top: 1px;
		left: -36px;
		background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-width='2' stroke='%2398AAB3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.467 17H17V1H1v8.533'/%3E%3Cpath d='M10 13.25V8H4.75M10 8l-7 7'/%3E%3C/g%3E%3C/svg%3E");
		background-repeat: no-repeat;
	}
`;

function getInnerHTML(html) {
	return { __html: html };
}

export default function CategoryItemPoiDetail({
	title,
	subtitle,
	icon,
	image,
	imageAlt,
	introtext,
	address,
	phone,
	email,
	web,
	content,
	externallink,
	externallinktext,
	onClose,
}) {
	return (
		<StyledCategoryItemPoiDetail>
			<TextWrapper>
				<BackButton onClick={onClose} />
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</TextWrapper>
			{icon && (
				<Iconwrapper>
					<svg width="100%" height="100%" viewBox={IconProps[icon].viewBox}>
						<path d={IconProps[icon].path} fill={colors.grayblue} />
					</svg>
				</Iconwrapper>
			)}
			{image && (
				<MediaWrapper>
					<img src={image} alt={imageAlt || title} width="80" height="80" />
				</MediaWrapper>
			)}
			<CardBody>
				{address && <Address1>{address}</Address1>}

				{(phone || email || web) && (
					<Address2>
						{phone ? `${phone}` : null}
						{phone && <br />}
						{email ? `${email}` : null}
						{email && <br />}
						{web ? (
							<ExternalLink href={web} target="_blank" rel="noopener noreferrer">
								{web}
							</ExternalLink>
						) : null}
					</Address2>
				)}

				<ContentWrapper>
					{introtext && <Introtext>{introtext}</Introtext>}
					{/* eslint-disable-next-line react/no-danger */}
					{content && <Content dangerouslySetInnerHTML={getInnerHTML(content)} />}
				</ContentWrapper>

				{externallink && (
					<ExternalLink href={externallink} target="_blank" rel="noopener noreferrer">
						{externallinktext}
					</ExternalLink>
				)}
			</CardBody>
		</StyledCategoryItemPoiDetail>
	);
}

CategoryItemPoiDetail.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	icon: PropTypes.string,
	image: PropTypes.string,
	imageAlt: PropTypes.string,
	introtext: PropTypes.string,
	address: PropTypes.node,
	phone: PropTypes.string,
	email: PropTypes.string,
	web: PropTypes.string,
	content: PropTypes.string,
	externallink: PropTypes.string,
	externallinktext: PropTypes.string,
	onClose: PropTypes.func,
};
