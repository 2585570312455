import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import VVSIcon from '../assets/icons/vvs_logo.svg';
import CloseIcon from '../assets/icons/close.svg';
import { TEXT } from './text';

const StyledMenuHeader = styled.div`
	display: grid;
	grid-template-columns: 3rem 1fr 3rem;
	align-items: center;
	gap: var(--size_x075);
	padding: var(--size_x075);
`;

const StyledImg = styled.img`
	width: 48px;
	height: 48px;
	position: relative;
`;

const StyledHeadline = styled.span`
	color: black;
	font-size: 1rem;
	font-weight: 500;
	word-wrap: break-word;
`;

const StyledCloseButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	padding:

	img {
		padding: 0.75px;
		width: 1.5rem;
		height: 1.5rem;
	}
`;

export function MenuHeader({ title, onCloseClick }) {
	return (
		<StyledMenuHeader>
			<StyledImg src={VVSIcon} alt={TEXT.VVS_ICON_ALT} />
			<StyledHeadline>{title}</StyledHeadline>
			<StyledCloseButton type="button" onClick={onCloseClick}>
				<img src={CloseIcon} alt={TEXT.CLOSE_BUTTON_ALT} />
			</StyledCloseButton>
		</StyledMenuHeader>
	);
}

MenuHeader.propTypes = {
	title: PropTypes.string.isRequired,
	onCloseClick: PropTypes.func,
};
