import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IconWrapper = styled.button`
	background-color: rgba(255, 255, 255, 0.8);
	border: 0;
	padding: 0;
	position: absolute;
	top: 16px;
	right: 16px;
	text-align: left;
	width: 36px;
	height: 36px;

	&:focus {
		outline: #097fac auto 1px;
	}

	&:active,
	&:focus,
	&:hover {
		background-color: #fff;
		cursor: pointer;
	}

	svg {
		margin: 8px 0 0 8px;
	}
`;

const CloseButton = React.forwardRef(({ onClose }, ref) => (
	<IconWrapper ref={ref} onClick={onClose} tabIndex="0">
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<g fill="#097fac" fillRule="evenodd">
				<path d="M2.1214 0l16.9705 16.9706-2.1213 2.1213L.0001 2.1214z" />
				<path d="M16.9706 0L0 16.9707l2.1213 2.1214L19.092 2.1214z" />
			</g>
		</svg>
	</IconWrapper>
));

CloseButton.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default CloseButton;
