import { mapConfig } from 'shared';

import { fromLonLat } from 'ol/src/proj';
import { getCenter } from 'ol/extent';
import { containsCoordinate } from 'ol/extent';

import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/src/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import * as olColor from 'ol/color';
import renderTooltipSelected from '../Markers/Tooltips/renderTooltipSelected';
import renderTooltip from '../Markers/Tooltips/renderTooltip';

let mapRef = null;
let setClickedMarkerRef = null;
let vectorLayer = null;
let citySource = null;
let currentMarker = null;
let lastCurrentFilters = null;

export default function loadCityShapes(
	isInit = false,
	map,
	setClickedMarker,
	filters,
	selectedMarker,
	currentFilters,
) {
	if (!isInit) {
		lastCurrentFilters = currentFilters;
		checkFilter(filters);
		if (selectedMarker !== null && currentMarker?.id !== selectedMarker.id) {
			citySource.refresh();
		} else if (selectedMarker === null && currentMarker !== null) {
			citySource.refresh();
		}
		currentMarker = selectedMarker;
		return;
	}
	// is Init is not longer valid because of other changes
	// remove layer before readding it
	// not sure if it is needed to generate a new layer here but the functionality of the map was changed and this is the safest way
	// to ensure that the map never has two layers here without breaking the new code
	if (vectorLayer) {
		map.removeLayer(vectorLayer);
	}
	lastCurrentFilters = currentFilters;
	currentMarker = selectedMarker;
	mapRef = map;
	setClickedMarkerRef = setClickedMarker;
	const style = new Style({
		fill: new Fill({
			color: '#fc631e',
		}),
		stroke: new Stroke({
			color: '#fc631e', // 252, 99, 30
			width: 1.25,
		}),
	});
	citySource = new VectorSource({
		url: '/geojson/cityV13.geojson',
		format: new GeoJSON(),
	});

	vectorLayer = new VectorLayer({
		background: '#fc631e',
		source: citySource,
		zIndex: 0,
		style: function(feature) {
			const isActive = feature.get('state') === 3 || feature.get('hovered') === true;
			let alpha = isActive ? 0.2 : 0.1;
			//#fc631e
			const color = [252, 90, 30, alpha];
			style.getFill().setColor(olColor.asString(color));
			return style;
		},
	});
	vectorLayer.set('name', 'city');
	map.addLayer(vectorLayer);
	// add click event to features
	citySource.on('featuresloadend', checkLoaded);
}

function checkLoaded(event) {
	let cityTicketFeatures = [];
	event.features.forEach(feature => {
		feature.set('canHover', true);
		feature.set('canClick', true);
		feature.set('onHover', () => {
			// do nothing on hover
		});
		feature.set('type', 'city');
		const featureId = encodeURIComponent(feature.get('Name1'));
		feature.set('id', featureId);
		feature.vvsId = featureId;
		feature.type = 'city';
		feature.vvsType = 'city';
		feature.vvsUpdate = false;
		feature.set('onePersonPrice', '3,50€');
		feature.set('fivePersonPrice', '7,00€');
		GeneratePDFLink(feature);
		if (feature.get('Name1') === 'Herrenberg') {
			feature.set('monthlyTicket', '52,20€');
		}
		cityTicketFeatures.push({ id: feature.get('Name1'), type: 'city' });
		//id is important for browser bar update
		feature.id = featureId;
		const offset = [0, 55];
		if (currentMarker) {
			feature.id = currentMarker.id;
			const splittedId = currentMarker.id.split('#');
			//if page is reloaded id and coords are mixed
			//if no coords take center of geometry for tooltip
			if (Array.isArray(splittedId) && splittedId.length > 0 && splittedId[0] === featureId) {
				//set position to center of element
				let featureCenter = getCenter(feature.getGeometry().getExtent());
				if (splittedId[1]) {
					const splitCoords = splittedId[1].split(',');
					let newCoords = [0, 0];
					newCoords[0] = parseFloat(splitCoords[0]);
					newCoords[1] = parseFloat(splitCoords[1]);

					featureCenter = newCoords;
				}
				feature.set('tooltipPos', featureCenter);
				renderTooltip('city', feature, mapRef, null, 'tooltip', 2, offset, null, null);
				renderTooltipSelected(feature, mapRef, offset, setClickedMarkerRef);

				const extent = mapRef.getView().calculateExtent();
				const isInView = containsCoordinate(extent, featureCenter);
				if (!isInView) {
					mapRef.getView().setCenter(featureCenter);
				}
			}
		}
		feature.set('onClick', event => {
			let coords = mapRef.getCoordinateFromPixel(event.pixel);
			feature.set('tooltipPos', coords);
			feature.id = featureId + '#' + coords;
			renderTooltip('city', feature, mapRef, null, 'tooltip', 2, offset, null, null);
			renderTooltipSelected(feature, mapRef, offset, setClickedMarkerRef);
			mapRef.getOverlayById('tooltip-selected').setPosition(coords);
		});
	});
	window.localStorage.setItem('cityticket', JSON.stringify(cityTicketFeatures));
}

//if filter for city ticket isn't set dont load
function checkFilter(typeFilter) {
	let isFiltered = true;
	if (typeFilter?.cityticket?.StadtTicket && typeFilter?.cityticket?.StadtTicket !== 0) {
		isFiltered = false;
	}
	if (isFiltered) {
		vectorLayer.setSource(
			new VectorSource({
				features: [],
			}),
		);
	} else {
		vectorLayer.setSource(citySource);
	}
}

// set marker link if id is valid
function GeneratePDFLink(marker) {
	let id = marker.get('Name1');
	//TODO check if https://download.vvs.de/Geltungsbereich-StadtTicket-Pluederhausen.pdf is correct id
	//check for special characters
	if (id === 'Göppingen/Birenbach/Rechberghausen/Wäschenbeuren') {
		id = 'G%C3%B6ppingen_Birenbach_Rechberghausen_W%C3%A4schenbeuren';
	} else if (id === 'Süßen') {
		id = 'S%C3%BC%C3%9Fen';
	} else if (id === 'Kirchheim/Dettingen (Teck)') {
		id = 'Kirchheim_Dettingen(Teck)';
	} else if (id === 'Markgröningen') {
		id = 'Markgro%CC%88ningen';
	} else if (id === 'Weilheim an der Teck/Neidlingen') {
		id = 'Weilheim-an-der-Teck_Neidlingen';
	} else if (id === 'Marbach am Neckar') {
		id = 'Marbach';
	}
	// standard link logic
	else {
		id = id
			.replace(/&/g, '-')
			.replace(/ä/g, 'ae')
			.replace(/ü/g, 'ue')
			.replace(/ö/g, 'oe')
			.replace(/ /g, '-')
			.replace(/---/g, '-')
			.replace(/\//g, '-')
			.replace(/-\([a-zA-Z]+\)/g, '');
	}
	const link = `https://download.vvs.de/Geltungsbereich-StadtTicket-${id}.pdf`;
	marker.set('vvsTicketLink', link);
}
