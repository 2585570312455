import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fonts } from '../../utils/config';

const StyledText = styled.p`
	color: ${props => (props.bright ? `${colors.graylight}` : `${colors.black}`)};
	font-size: ${props => (props.small ? '16px' : '18px')};
	line-height: ${props => (props.small ? '21px' : '26px')};
	font-style: ${props => (props.italic ? 'italic' : 'normal')};

	text-align: ${props => (props.alignright ? 'right' : 'left')};
	font-family: ${props => (props.bold ? `${fonts.bold}` : `${fonts.default}`)};
	font-weight: ${props => (props.bold ? `${fonts.weightbold}` : `${fonts.weightdefault}`)};

	${props =>
		props.marginBottom === 'zero' &&
		`
		margin-bottom: 0;
	`}

	${props =>
		props.marginBottom === 'small' &&
		`
		margin-bottom: .5em;
	`}

	${props =>
		props.marginBottom === 'normal' &&
		`
		margin-bottom: 1em;
	`}

	${props =>
		props.marginBottom === 'large' &&
		`
		margin-bottom: 2em;
	`}
`;

function Text(props) {
	return <StyledText {...props}>{props.children}</StyledText>;
}

Text.propTypes = {
	children: PropTypes.node.isRequired,
	bright: PropTypes.bool,
	italic: PropTypes.bool,
	small: PropTypes.bool,
	bold: PropTypes.bool,
	marginBottom: PropTypes.oneOf(['zero', 'small', 'normal', 'large']),
	alignright: PropTypes.bool,
};

Text.defaultProps = {
	marginBottom: 'normal',
};

export default Text;
