import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const Content = styled.div`
	display: grid;
	grid-template-columns: 1fr 100px;
	grid-template-rows: auto auto auto;

	${media.lg`
		grid-template-columns: 1fr auto;
	`}
`;

export default Content;
