export const TEXT = {
	CLOSE_BUTTON_ALT: 'Close Button',
	VVS_ICON_ALT: 'VVS ICON',
	DATE_PICKER: {
		LABEL: 'DATUM',
	},
	SEARCH_BAR: {
		LABEL: 'Suche',
		BUTTON: {
			RESET: 'Zurücksetzten',
			SEARCH: 'Suche',
		},
		LOADING: "Lade...",
		PLACE_HOLDER: 'Haltestelle, Adresse, Ort, Linie',
	},
	TYPE_SELECT: {
		LABEL: 'Meldungstyp',
	},
	TRANSPORT_SELECT: {
		LABEL: 'Verkehrsmittel',
	},
	SELECT: {
		CHECKED_ALT: 'Selektiert',
		UNCHECKED_ALT: 'Unselektiert',
		PLACE_HOLDER: 'Wähle aus',
		NO_SELECTION: 'Keine Auswahl',
		ALL: {
			LABEL_SELECT: 'Alle ausgewählt',
			LABEL_OPTION: 'Alle',
		},
	},
	RESULT_DETAIL: {
		NAV: {
			BACK: 'Zurück zur Liste',
			PREV: 'Zum Vorherigen springen',
			NEXT: 'Zum Nächsten springen',
		},
		HEADER: {
			TITLE: 'Ergebnisse',
			TOGGLE_LABEL: 'Ergebnisse auf/zuklappen',
		},
		SETTINGS: {
			SORT_IMG_ALT: 'Sortierung',
			RESET_BUTTON: 'Auswahl löschen',
		},
	},
};
