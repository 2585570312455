import { createMarker, fetchers, disruptionsClassifier } from 'shared';
import renderTooltip from './Tooltips/renderTooltip';

/**
 * Helper constant that contains all possible marker types for disruptions
 */
export const DISRUPTIONS_MARKER_TYPES = ['disruptions', 'disruptionsElevator', 'disruptionsInfo'];

/**
 * Helper function to get the correct type of a disruption marker
 * @param {object} disruption
 * @returns DISRUPTIONS_MARKER_TYPES
 */
function getDisruptionType(disruption) {
	if (disruptionsClassifier.isAufzüge(disruption)) {
		return 'disruptionsElevator';
	}
	if (disruptionsClassifier.isBaustellenUndUmleitungen(disruption)) {
		return 'disruptionsInfo';
	}
	return 'disruptions';
}

/**
 * Helper function to create a marker from a disruption object with correct detail values
 *
 * @param {string} id - a unique id build on disruption.id and infoItem.id
 * @param {object} disruption - The disruption object
 * @param {object} activeMarker - The currently active marker
 * @param {object} setClickedMarker - The function to set the clicked marker
 * @param {Array} coords - The coordinates of the marker
 * @param {string} title - The title of the marker (e.g. to use in a tooltip or list item)
 * @param {function} setDisruptionFeatures - The function to set the disruption features
 * @returns
 */
function makeMarker(
	id,
	disruption,
	activeMarker,
	setClickedMarker,
	coords,
	title,
	setDisruptionFeatures,
) {
	const type = getDisruptionType(disruption);

	const newMarker = createMarker(
		{
			coords,
		},
		{
			/** Type of the disruption */
			type,
			id,
			onClick: () => {
				setClickedMarker(newMarker);
			},
			onClusterClick: features => {
				setDisruptionFeatures(features);
			},
			attachments: disruption.properties.attachments,
			validFrom: new Date(disruption.timestamps.validity[0].from),
			validTo: new Date(disruption.timestamps.validity[0].to),
			lastModified: new Date(disruption.timestamps.modified),
			originalDisruption: disruption,
			subject: disruption.subject,
			title, // NOTE: Title is a custom property that is not part of the original disruption object
			subtitle: disruption.subtitle,
			content: disruption.properties.htmlText, // WARNING: This is not sanitized! Make sure to sanitize it on the server side! Sanitizing on the client side does not make sense, because it can be easily circumvented.
			icon: 'stop', // Marker
			affectedLines: disruption.affected.lines,
			affectedStops: disruption.affected.stops,
			isDisruption: disruptionsClassifier.isStörung(disruption),
			isTimetableChange: disruptionsClassifier.isBaustellenUndUmleitungen(disruption),
			state: activeMarker?.id === disruption.id ? 3 : 2,
		},
	);
	return newMarker;
}

function generateUniqueMarkerId(disruptionId, lineOrStopId) {
	return `${disruptionId}-${lineOrStopId}`;
}

/** Loads data for disruptions markers, makes markers from it and passes them to the provided callback */
export default async (
	markerFilters,
	setDisruptionsMarkers,
	activeMarker,
	setClickedMarker,
	setDisruptionFeatures,
	map,
) => {
	if (
		markerFilters.disruptions &&
		(markerFilters.disruptions[disruptionsClassifier.CLASSES.Störung] === 2 ||
			markerFilters.disruptions[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen] ===
				2 ||
			markerFilters.disruptions[disruptionsClassifier.CLASSES.Aufzüge] === 2)
	) {
		const retries = 3;
		const filters = {};

		// Only fetch the subtypes of disruptions that are actually displayed
		filters.subTypes = Object.keys(markerFilters.disruptions).filter(
			subType => markerFilters.disruptions[subType] === 2,
		);

		// Pass on date constraints, or set today as default
		if (markerFilters.date) {
			filters.validAtRange = {
				from: markerFilters.date.from,
				to: markerFilters.date.to,
			};
		} else {
			const now = new Date();
			const in24Hours = new Date(now.valueOf() + 1000 * 60 * 60 * 24);

			const todayAt0am = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
			const tomorrowAt0am = new Date(
				in24Hours.getFullYear(),
				in24Hours.getMonth(),
				in24Hours.getDate(),
				0,
				0,
				0,
			);

			filters.validAtRange = {
				from: todayAt0am,
				to: tomorrowAt0am,
			};
		}
		if (markerFilters.product) {
			filters.product = Array.from(Object.values(markerFilters.product));
		}

		const disruptions = await fetchers.fetchDisruptions(filters, retries);

		/* Make marker options from disruptions */
		const markersFromDisruptions = [];
		disruptions.forEach(disruption => {
			if (disruption?.coords) {
				const newMarker = makeMarker(
					disruption.id,
					disruption,
					activeMarker,
					setClickedMarker,
					disruption.coords,
					disruption.subject ?? '',
					setDisruptionFeatures,
				);
				markersFromDisruptions.push(newMarker);
				if (activeMarker?.id === disruption.id) {
					renderTooltip(
						'disruption',
						newMarker,
						map,
						disruption,
						'tooltip-selected',
						2,
						[0, 35],
						null,
						null,
					);
				}
			}
		});

		// TODO: Add more types of disruption markers
		setDisruptionsMarkers([...markersFromDisruptions]);
	} else {
		setDisruptionsMarkers([]);
	}
};

// const exampleMarkerPoint = {
// 	desc: 'Hauptbf (Arnulf-Klett-Platz)',
// 	addDesc: '',
// 	type: 'STOP',
// 	id: '5006112',
// 	omc: '8111000',
// 	placeID: '51',
// 	locality: 'Stuttgart',
// 	layer: 'SYS-STOP',
// 	gisID: '5006112',
// 	distance: 0.051,
// 	stateless: '5006112',
// 	coords: [48.78316, 9.18113],
// 	attrs: {
// 		STOP_GLOBAL_ID: 'de:08111:6112',
// 		STOP_NAME_WITH_PLACE: 'Hauptbf (Arnulf-Klett-Platz)',
// 		STOP_MAJOR_MEANS: '1',
// 		STOP_MEANS_LIST: ['3', '107', '201', '104'],
// 		STOP_MOT_LIST: '3,5,11',
// 		'STOP_TARIFF_ZONES:vvs': ['1'],
// 		STOP_SURROUNDING_MAP: 'vvs/me_plaene\\ME_Hbf.pdf',
// 	},
// 	infos: null,
// };
// const exampleMarkerOptions = {
// 	type: 'stop',
// 	vvsId: '12345667890',
// 	title: 'Title',
// 	locationtype: 'stop',
// 	state: 2,
// 	locality: 'Stuttgart',
// 	canHover: true,
// 	canClick: true,
// 	onHover: () => {
// 		// renderTooltip('stop', marker, map, stop, 'tooltip', state, [0, 0], null, null);
// 		alert('Hover!');
// 	},
// 	onClick: () => {
// 		alert('CLICK!');
// 		// if (isCityTicketMode) {
// 		// 	renderTooltipSelected(marker, map, [0, 0], setClickedMarkerCityMode);
// 		// } else {
// 		// 	renderTooltipSelected(marker, map, [0, 0], setClickedMarker);
// 		// }
// 	},
// };
// const testMarker = createMarker(exampleMarkerPoint, exampleMarkerOptions);
