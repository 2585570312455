import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconProps, Icons } from 'shared';

import media from '../../utils/Breakpoints';
import { fonts, colors, boxshadow } from '../../utils/config';

const StopIcon = Icons.stop;

const StyledCategoryItem = styled.li`
	position: relative;
	height: 80px;
	width: 100%;
	border: 2px solid transparent;
	padding: 12px;
	display: flex;
	align-items: center;
	${
	// If image is at the start, align all flex items left
	// If image is at the end, align image right and text left
	props =>
		props.imagePosition === 'start'
			? 'justify-content: flex-start;'
			: 'justify-content: space-between;'
	}
	background-color: ${colors.white};

	&::after {
		position: absolute;
		left: 4px;
		right: 4px;
		bottom: -2px;
		content: '';
		display: block;
		height: 1px;
		background-color: ${colors.graylight};
	}

	&:hover,
	&:focus {
		cursor: pointer;
		border-radius: 5px;
		border-color: ${colors.cyandark};
		outline: none;

		&::after {
			display: none;
		}
	}

	${props =>
		props.active &&
		`
			border-color: ${colors.cyandark};
	`}

	${media.md`
		height: 80px;
		width: 358px;
		padding: 16px;
		box-shadow: ${props => (props.hideShadow ? 'none' : `${boxshadow.default}`)};
		border-radius: 5px;
		margin-bottom: 8px;

		&::after {
			display: none;
		}
	`}

	${media.lg`
		height: 96px;
		width: 458px;
		padding: 24px;
	`}
`;

const TextWrapper = styled.div`
	// Default
	max-width: 290px;
	display: grid;
	grid-template-columns: 32px 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0px 0px;
	grid-column-gap: 8px;
	grid-template-areas:
		"image headline"
		"image text";
	// Always use full space for disruptions
	${props =>
		props.listtype === 'disruptions' &&
		`
			max-width: 100%;
	`}

	${media.md`
		// Default
		max-width: 250px;
		// Always use full space for disruptions
		${props =>
			props.listtype === 'disruptions' &&
			`
				max-width: 100%;
		`}
		grid-column-gap: 16px;
	`}


	${media.lg`
		// Default
		max-width: 290px;
		// Always use full space for disruptions
		${props =>
			props.listtype === 'disruptions' &&
			`
				max-width: 100%;
		`}
		grid-column-gap: 24px;
	`}

	${props =>
		props.listtype === 'stops' &&
		`
			max-width: 340px;
	`}

	`;

const Title = styled.h2`
	grid-area: headline;
	font-family: ${fonts.default};
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	// Allow text to flex grow or shrink
	flex: 1;

	${media.md`
		font-size: 18px;
		line-height: 23px;
	`}
`;

const Subtitle = styled.p`
	grid-area: text;
	font-size: 14px;
	line-height: 18px;
	color: ${colors.graydark};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	${media.md`
		font-size: 18px;
		line-height: 23px;
	`}
`;

const MediaWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: image;
	// Make a blank space if there is no image
	width: ${props => (props.image ? '56px' : '48px')};
	height: ${props => (props.image ? '56px' : '48px')};
	// If the image is inline, only use as much width as needed and don't overflow height
	${props => (props.inline ? 'width: auto; height: 100%;' : null)}
	// Do not shrink or grow the image to ensure it stays the hardcoded size
	flex-shrink: 0;
	flex-grow: 0;

	${media.md`
		width: ${props => (props.image ? '64px' : '48px')};
		height: ${props => (props.image ? '64px' : '48px')};
		// If the image is inline, only use as much width as needed and don't overflow height
		${props => (props.inline ? 'width: auto; height: 100%;' : null)}
		margin-right: ${props => (props.image ? '0' : null)};
	`}

	${media.lg`
		width: ${props => (props.image ? '80px' : '48px')};
		height: ${props => (props.image ? '80px' : '48px')};
		// If the image is inline, only use as much width as needed and don't overflow height
		${props => (props.inline ? 'width: auto; height: 100%;' : null)}
		// Only add margin right if image is at the end
		margin-right: ${props => (props.image && props.imagePosition === 'end' ? '-8px' : null)};
	`}

	img {
		display: block;
		width: 56px;
		height: 56px;
		overflow: hidden;
		border-radius: 5px;
		background-color: ${colors.graylight};
		object-fit: cover;

		${media.md`
			width: 64px;
			height: 64px;
			// Only add margin right if image is at the end
			margin-right: ${props => (props.imagePosition === 'end' ? '-16px' : null)};
		`}

		${media.lg`
			width: 80px;
			height: 80px;
			// Only add margin right if image is at the end
			margin-right: ${props => (props.imagePosition === 'end' ? '-18px' : null)};
		`}
	}
`;

const SVGIcon = styled.svg`
	width: 32px;
	height: 32px;
`;

export default function CategoryItem({
	id,
	title,
	subtitle,
	icon,
	image,
	active,
	hideShadow,
	listtype,
	type,
	onClick,
	/**
	 * Position of the image
	 * @type {'start' | 'end'} - 'end' is default
	 */
	imagePosition,
}) {
	let mediaItem = '';

	if (
		[
			'freizeit',
			'essentrinken',
			'kultur',
			'sehenswuerdigkeiten',
			'rundumsrad',
			'bildungseinrichtungen',
			'krankenhaeuserkliniken',
			'verbaendevereine',
			'stella',
			'car2go',
			'sharenow',
			'flinkster',
			'regiorad',
			'stadtmobil',
			'elevator',
			'timetableChange',
			'disruption',
		].indexOf(icon) > -1
	) {
		// If icon is a disruption, use the colored icon, otherwise use the default icon
		const color = icon === 'disruption' ? colors.red : colors.grayblue;
		mediaItem = (
			<SVGIcon width="100%" height="100%" viewBox={IconProps[icon].viewBox}>
				<path d={IconProps[icon].path} fill={color} />
			</SVGIcon>
		);
	} else if (icon === 'stop') {
		mediaItem = <StopIcon />;
	} else if (image && image !== '') {
		mediaItem = <img src={image} alt={title} width="80" height="80" />;
	} else {
		mediaItem = '';
	}

	const onFocus = () => {
		const marker = document.querySelector(`.marker--${type}[data-vvsid="${id}"]`);
		if (marker) {
			marker.classList.add('active');
		}
	};

	const onBlur = () => {
		const marker = document.querySelector(`.marker--${type}[data-vvsid="${id}"]`);
		if (marker) {
			marker.classList.remove('active');
		}
	};

	return (
		<StyledCategoryItem
			data-id={id}
			active={active}
			onMouseOver={onFocus}
			onFocus={onFocus}
			onMouseOut={onBlur}
			onBlur={onBlur}
			onClick={() => {
				onBlur();
				onClick();
			}}
			hideShadow={hideShadow}
			tabIndex={0}
			imagePosition={imagePosition}
		>
			<TextWrapper listtype={listtype}>
				{imagePosition === 'title' && (
					<MediaWrapper image={image} inline>
						{mediaItem}
					</MediaWrapper>
				)}
				<Title title={title}>{title}</Title>
				<Subtitle title={subtitle}>{subtitle}</Subtitle>
			</TextWrapper>
			{(!imagePosition || imagePosition === 'end') && (
				<MediaWrapper image={image}>{mediaItem}</MediaWrapper>
			)}
		</StyledCategoryItem>
	);
}

CategoryItem.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	icon: PropTypes.oneOf([
		'freizeit',
		'essentrinken',
		'kultur',
		'sehenswuerdigkeiten',
		'rundumsrad',
		'bildungseinrichtungen',
		'krankenhaeuserkliniken',
		'verbaendevereine',
		'stella',
		'car2go',
		'flinkster',
		'regiorad',
		'stadtmobil',
		'stop',
		'poi',
		'elevator',
		'timetableChange',
		'disruption',
	]),
	image: PropTypes.string,
	listtype: PropTypes.string,
	active: PropTypes.bool,
	hideShadow: PropTypes.bool,
	type: PropTypes.string,
	onClick: PropTypes.func,
	imagePosition: PropTypes.oneOf(['title', 'end']),
};
