import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import { breakpoints as media, Button } from 'shared';

import { colors, boxshadow } from '../../utils/config';

import CategoryItem from '../CategoryItem';
import CategoryItemPoiDetail from '../CategoryItemPoiDetail';
import CategoryItemStopDetail, { StyledCategoryItemStopDetail } from '../CategoryItemStopDetail';
import CategoryItemVehicleDetail from '../CategoryItemVehicleDetail';
import CategoryItemDisruptionDetail from '../CategoryItemDisruptionDetail';

export const CategoryListWrapper = styled.div`
	display: ${props => (props.showList || props.showDetail ? 'block' : 'none')};
	position: absolute;
	top: 100vh;
	left: 8px;
	height: auto;
	width: 96%;
	width: calc(100% - 16px);
	margin: 8px auto 48px auto;
	padding: 0;
	background-color: ${colors.white};
	border: ${props => (props.showDetail ? '0' : `1px solid ${colors.graylight}`)};
	box-shadow: ${boxshadow.default};
	border-radius: 5px;
	transform: translate(0, -250px);
	z-index: 20;
	overflow-y: hidden;

	${media.iphone`
		max-width: 358px;
	`}

	${media.md`
		top: ${props => (props.hasCategoryMenuOnTop ? '138px' : `64px`)};
		left: auto;
		width: auto;
		max-width: 366px;
		height: auto;
		max-height: ${props =>
			props.hasCategoryMenuOnTop
				? 'calc(100% - 16px - 68px - 96px - 8px - 16px - 96px)'
				: 'calc(100% - 16px - 68px - 8px - 16px - 96px)'};
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0px;
		padding-left: 8px;
		padding-right: 8px;
		box-shadow: none;
		border: 0;
		transform: none;
		background-color: transparent;
		overflow-x: hidden;
		-ms-overflow-style: auto;
		overflow-y: auto;
	`}

	${media.lg`
		top: ${props => (props.hasCategoryMenuOnTop ? '204px' : `92px`)};
		max-width: 478px;
		margin-left: 8px;
		border-radius: 0;
	`}

	${media.xl`
		top: ${props => (props.hasCategoryMenuOnTop ? '204px' : `108px`)};
		margin-left: 24px;
		// height: calc(100% - 32px - 68px - 8px - 32px);
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		top: ${props => (props.hasCategoryMenuOnTop ? '144px' : `72px`)};
		transform: scale(.752);
		transform-origin: top left;
	}

	${props =>
		props.showList &&
		`overflow-y: ${props.showDetail ? 'hidden !important' : 'auto !important'};
	`}

	&::before {
		content: '';
		display: block;
		width: 24px;
		height: 4px;
		position: absolute;
		top: 6px;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 5;
		border-radius: 4px;
		background-color: ${colors.graylight};

		${media.md`
			display: none;
		`}
	}

	.CategoryItemTransition-enter {
		opacity: 0;
		transform: scale(0.9);
	}
	.CategoryItemTransition-enter-active {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 300ms, transform 300ms;
	}
	.CategoryItemTransition-exit {
		opacity: 1;
	}
	.CategoryItemTransition-exit-active {
		opacity: 0;
		transform: scale(0.9);
		transition: opacity 300ms, transform 300ms;
	}

	${StyledCategoryItemStopDetail} {
		margin-left: 0;
	}
`;

const StyledCategoryList = styled.ul`
	margin: 0;
	padding: 0;

	.item-enter {
		opacity: 0;
	}
	.item-enter-active {
		opacity: 1;
		transition: opacity 500ms ease-in;
	}
	.item-exit {
		opacity: 1;
	}
	.item-exit-active {
		opacity: 0;
		transition: opacity 500ms ease-in;
	}

	> li:last-child::after {
		display: none;
	}
`;

const ButtonWrapper = styled.div`
	list-style-type: none;
	margin: 8px;

	${media.md`
		margin: 0;
	`}
`;

// const Iconwrapper = styled.div`
// 	position: absolute;
// 	top: 22px;
// 	right: 26px;
// 	width: 48px;
// 	height: 48px;
// `;

class CategoryList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			listLimit: props.limit,
		};
	}

	render() {
		const {
			selectedIndex,
			listtype,
			items,
			marker,
			setMarker,
			setLines,
			showList,
			isButtonVisible,
			hasCategoryMenuOnTop,
		} = this.props;
		/** A button that increases the list limit */
		const MoreButton = () => (
			<ButtonWrapper>
				<Button
					color="blue"
					stretch
					onClick={() => {
						this.setState(prevState => ({
							listLimit: prevState.listLimit + 10,
						}));
					}}
				>
					Mehr anzeigen
				</Button>
			</ButtonWrapper>
		);

		return (
			<CategoryListWrapper
				showDetail={selectedIndex !== false}
				showList={selectedIndex === false && showList}
				hasCategoryMenuOnTop={hasCategoryMenuOnTop}
				listtype={listtype}
			>
				{selectedIndex === false && (
					<StyledCategoryList>
						<TransitionGroup component={null}>
							{items.slice(0, this.state.listLimit).map((item, index) => (
								<CSSTransition
									key={
										// item.id is not necessarily unique, as there might be multiple items for the same disruption (e.g. one for each affected stop).
										// However, in combination with the index, we can assume it to be unique.
										// If the id is already unique, the combination with the index will also be unique.
										// eslint-disable-next-line react/no-array-index-key
										`${item.id}-${index}`
									}
									timeout={500}
									classNames="item"
								>
									<CategoryItem
										listtype={listtype}
										id={item.id}
										title={item.title}
										subtitle={item.subtitle}
										icon={item.icon}
										image={item.image}
										hideShadow={selectedIndex !== false}
										type={item.type}
										onClick={() => {
											setMarker(item);
										}}
										imagePosition={item.imagePosition}
									/>
								</CSSTransition>
							))}
						</TransitionGroup>
					</StyledCategoryList>
				)}

				{selectedIndex === false &&
					isButtonVisible &&
					this.state.listLimit < items.length && <MoreButton />}

				<CSSTransition
					in={selectedIndex !== false}
					timeout={300}
					classNames="CategoryItemTransition"
					unmountOnExit
					onExited={() => {
						// only set marker null if it has the same type as the closed menu
						if (items.length > 0 && items[0]?.type === marker?.type) setMarker(null);
						setLines(null);
					}}
				>
					<>
						{selectedIndex !== false && (
							<>
								{(listtype === 'pois' || listtype === 'eating') && (
									<CategoryItemPoiDetail
										id={items[selectedIndex].id}
										type={items[selectedIndex].type}
										title={items[selectedIndex].title}
										subtitle={items[selectedIndex].subtitle}
										introtext={items[selectedIndex].introtext}
										icon={items[selectedIndex].icon}
										image={
											listtype === 'pois' ? items[selectedIndex].image : ''
										}
										address={items[selectedIndex].address}
										phone={items[selectedIndex].phone}
										email={items[selectedIndex].email}
										web={items[selectedIndex].web}
										content={items[selectedIndex].content}
										externallink={items[selectedIndex].externallink}
										externallinktext={items[selectedIndex].externallinktext}
										onClose={() => {
											setMarker(null);
										}}
									/>
								)}
								{listtype === 'sharing' && (
									<CategoryItemVehicleDetail
										id={items[selectedIndex].id}
										title={items[selectedIndex].title}
										type={items[selectedIndex].type}
										icon={items[selectedIndex].icon}
										subtitle={items[selectedIndex].subtitle}
										address={items[selectedIndex].address}
										vehicletypes={items[selectedIndex].vehicletypes}
										linkname={items[selectedIndex].linkname}
										iosDeeplink={items[selectedIndex].iosDeeplink}
										iosStore={items[selectedIndex].iosStore}
										androidDeeplink={items[selectedIndex].androidDeeplink}
										androidStore={items[selectedIndex].androidStore}
										windowsDeeplink={items[selectedIndex].windowsDeeplink}
										windowsStore={items[selectedIndex].windowsStore}
										uri={items[selectedIndex].uri}
										onClose={() => {
											setMarker(null);
										}}
									/>
								)}
								{listtype === 'stops' && (
									<CategoryItemStopDetail
										stopId={items[selectedIndex].id}
										gisId={items[selectedIndex].gisId}
										title={items[selectedIndex].title}
										onClose={() => {
											setMarker(null);
											setLines(null);
										}}
									/>
								)}
								{listtype === 'disruptions' && selectedIndex && (
									<CategoryItemDisruptionDetail
										attachments={marker.values_.attachments}
										isDisruption={marker.values_.isDisruption}
										isTimetableChange={marker.values_.isTimetableChange}
										originalDisruption={marker.values_.originalDisruption}
										affectedLines={marker.values_.affectedLines}
										affectedStops={marker.values_.affectedStops}
										icon={marker.values_.icon}
										subtitle={marker.values_.subtitle}
										validFrom={marker.values_.validFrom}
										validTo={marker.values_.validTo}
										lastModified={marker.values_.lastModified}
										content={marker.values_.content}
										onClose={() => setMarker(null)}
										switchToPreviousMarker={
											marker.values_.previousFeatureInCluster
												? () =>
														marker.values_.previousFeatureInCluster.values_.onClick(
															null,
														)
												: undefined
										}
										featureInClusterIndex={marker.values_.featureInClusterIndex}
										switchToNextMarker={
											marker.values_.nextFeatureInCluster
												? () =>
														marker.values_.nextFeatureInCluster.values_.onClick(
															null,
														)
												: undefined
										}
									/>
								)}
							</>
						)}
					</>
				</CSSTransition>
			</CategoryListWrapper>
		);
	}
}

CategoryList.propTypes = {
	selectedIndex: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.bool.isRequired])
		.isRequired,
	listtype: PropTypes.oneOf(['pois', 'stops', 'eating', 'sharing', 'disruptions']),
	items: PropTypes.array,
	limit: PropTypes.number,
	setMarker: PropTypes.func.isRequired,
	setLines: PropTypes.func.isRequired,
	marker: PropTypes.object,
	isButtonVisible: PropTypes.bool,
	hasCategoryMenuOnTop: PropTypes.bool,
	showList: PropTypes.bool, // Shows general list of items below search if true
};

CategoryList.defaultProps = {
	limit: 5,
	isButtonVisible: false,
	hasCategoryMenuOnTop: false,
};

export default CategoryList;
