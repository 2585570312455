import { fetchers, createMarker, sharingMappings } from 'shared';

import { getBottomLeft, getTopRight } from 'ol/src/extent';
import { toLonLat } from 'ol/src/proj';


import { nameToIcon, mapConfig, sharingTitleMappings } from '../../../utils/config';
import renderTooltipSelected from './Tooltips/renderTooltipSelected';
import renderTooltip from './Tooltips/renderTooltip';

const buildSharingOperators = (operators, filter) => {
	let sharingOperators = [];
	Object.keys(operators).forEach(key => {
		if (filter === null || parseInt(filter[key], 10) > 0) {
			sharingOperators = sharingOperators.concat(operators[key]);
		}
	});

	return sharingOperators;
};

let lastFilter = null;
let sharingOperators;
let lastSelectedMarker = null;
let lastBottomLeft = [-1, -1];
let lastTopRight = [-1, -1];

export default async (
	map,
	operators = {},
	filter = null,
	setSharingMarkers = () => {},
	selectedMarker = null,
	forceReload = false,
	openPopup = () => {},
	setClickedMarker = () => {},
) => {
	let isFilterChange = false;
	//check if any key in last filter differs from new filter
	if (lastFilter) {
		Object.keys(filter).forEach(key => {
			if (filter[key] !== lastFilter[key]) {
				isFilterChange = true;
			}
		});
	} else {
		isFilterChange = true;
	}
	lastFilter = filter;
	const extent = map.getView().calculateExtent();
	const bottomLeft = toLonLat(getBottomLeft(extent));
	const topRight = toLonLat(getTopRight(extent));
	sharingOperators = buildSharingOperators(operators, filter);
	//check if any params or filters or markers were changed
	//if not don't fetch again and don't set the updated flag
	if (
		selectedMarker === lastSelectedMarker &&
		lastTopRight[0] === topRight[0] &&
		lastBottomLeft[0] === bottomLeft[0] &&
		lastTopRight[1] === topRight[1] &&
		lastBottomLeft[1] === bottomLeft[1] &&
		!isFilterChange
	) {
		return;
	}
	if (
		sharingOperators.length > 0 &&
		map.getView().getZoom() >= mapConfig.minZoomLevel.sharing + mapConfig.minZoom
	) {
		lastSelectedMarker = selectedMarker;
		lastBottomLeft = bottomLeft;
		lastTopRight = topRight;
		const stations = await fetchers.fetchSharingStations(
			bottomLeft[1],
			bottomLeft[0],
			topRight[1],
			topRight[0],
			sharingOperators,
		);
		const stationMarkers = [];
		// sharingOperators could have changed, if the function was triggered again, so we re-check it.
		if (sharingOperators.length === 0 || stations === null) {
			setSharingMarkers([]);
			return;
		}
		stations.forEach(station => {
			if (typeof stationMarkers[station.id] !== 'undefined') {
				return;
			}
			let vvsOperator =
				sharingTitleMappings[station.type] || sharingMappings[station.type] || station.type;
			let state = lastFilter ? parseInt(lastFilter[vvsOperator], 10) : 0;
			//check lastSelectedMarker as fetching data can mess up, if marker was changed later
			if (lastSelectedMarker !== null && lastSelectedMarker.id === station.id) {
				state = 3;
			}
			if (state === 0) {
				return;
			}
			let title = `${station.desc}, ${station.locality}`;
			const marker = createMarker(station, {
				type: `sharing_${nameToIcon(sharingMappings[station.type] || station.type)}`,
				operator: sharingMappings[station.type] || station.type,
				vvsId: station.id,
				title: title,
				locationtype: 'station',
				vvsOperator: vvsOperator,
				state: state,
				canHover: true,
				canClick: true,
				onHover: () => {
					if (marker.get('state') === 3 || state === 3) {
						return;
					}
					renderTooltip(
						'sharing',
						marker,
						map,
						station,
						'tooltip',
						state,
						[0, 0],
						openPopup,
						operators,
					);
				},
				onClick: () => {
					renderTooltipSelected(marker, map, [0, 0], setClickedMarker);
				},
			});
			if (state === 3) {
				renderTooltip(
					'sharing',
					marker,
					map,
					station,
					'tooltip-selected',
					2,
					[0, 0],
					openPopup,
					operators,
				);
			}
			marker.vvsType = 'sharing';
			marker.vvsOperator = vvsOperator;
			marker.vvsId = station.id;
			marker.id = station.id;
			marker.vvsState = state;
			marker.vvsUpdate = false;
			marker.vvsTitle = title;

			stationMarkers.push(marker);
		});
		//set vvsUpdate for MarkerContext to know filters were updated
		if (stationMarkers.length > 0) {
			stationMarkers[0].vvsUpdate = true;
		}
		setSharingMarkers(stationMarkers);
		return;
	} else {
		//check if lastStopMarker is already generated marker
		//keep selected marker even if zoomed out
		if (selectedMarker !== null && selectedMarker.vvsId) {
			setSharingMarkers([selectedMarker]);
		} else {
			setSharingMarkers([]);;
		}
	}
};
