export default {
	1: 'ubahn',
	2: 'sbahn',
	3: 'bus',
	6: 'rbahn',
	8: 'taxi',
	9: 'seilbahn',
	11: 'zacke',
	13: 'ubahn', // Museumsverkehr
	100: 'footpath',
	107: 'fahrrad',
};
