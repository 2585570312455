import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Button';

const NavigationWrapper = styled.nav`
	margin: 16px 0;
`;

const NavigationList = styled.ul`
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style-type: none;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	max-width: 240px;
`;

const NavigationItem = styled.li`
	color: #4f5b5f;
	margin: 0;
	padding: 0;
`;

function Navigation({ current, length, onPrevious, onNext }) {
	return (
		<NavigationWrapper role="navigation">
			<NavigationList>
				<NavigationItem>
					<Button role="button" onClick={onPrevious}>
						Zurück
					</Button>
				</NavigationItem>
				<NavigationItem>
					{current} von {length}
				</NavigationItem>
				<NavigationItem>
					<Button role="button" onClick={onNext}>
						Weiter
					</Button>
				</NavigationItem>
			</NavigationList>
		</NavigationWrapper>
	);
}

Navigation.propTypes = {
	current: PropTypes.number.isRequired,
	length: PropTypes.number.isRequired,
	onPrevious: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
};

export default Navigation;
