import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconProps } from 'shared';

import media from '../../utils/Breakpoints';
import { colors } from '../../utils/config';
import { onlyOnEnter } from '../../utils/events';

const StyledSwitch = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
`;

const StyledLabel = styled.label`
	color: ${colors.black};
	display: flex;
	flex-grow: 1;
	align-items: center;
	padding: 12px 0;
	height: 100%;
	justify-content: space-between;

	&:hover {
		cursor: pointer;
		${props => props.disabled && 'cursor: not-allowed;'}
	}
`;

const StyledIconLabel = styled.span`
	&.icon {
		display: flex;
		align-items: center;
	}

	&.icon::before {
		content: '';
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 5px;
		width: 30px;
		height: 30px;
		background: center center no-repeat transparent;
	}

	&.icon-freizeit::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.freizeit.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-essentrinken::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.essentrinken.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-kultur::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.kultur.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-sehenswuerdigkeiten::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.sehenswuerdigkeiten.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-rundumsrad::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.rundumsrad.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-bildungseinrichtungen::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.bildungseinrichtungen.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-krankenhaeuserkliniken::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.krankenhaeuserkliniken.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-verbaendevereine::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.verbaendevereine.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-stella::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.stella.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-car2go::before,
	&.icon-sharenow::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.sharenow.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-flinkster::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.flinkster.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-regiorad::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.regiorad.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	&.icon-stadtmobil::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.stadtmobil.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-ausflugstipps::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.tips.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-haltestellen::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.stopWithOutline.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-stoerungen::before {
		background-image: url("${IconProps.disruption.svg}")
	}

	&.icon-baustellenundumleitungen::before {
		background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='${
			IconProps.timetableChange.viewBox
		}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
	IconProps.timetableChange.path
}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}
	
	&.icon-aufzuege::before {
		background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='${
			IconProps.elevator.viewBox
		}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
	IconProps.elevator.path
}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-stadtticket::before {
		background-image: url("${IconProps.citytickets.svg}");
	}
	&.icon-rbahn::before {
		${'' /* this is only here because of css parsing bug */}
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.rbahn.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-linien::before {
		background-image: url("${IconProps.stopLines.svg}");
	}
	&.icon-rbahn::before {
		${'' /* this is only here because of css parsing bug */}
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.rbahn.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-rbahn::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.rbahn.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-sbahn::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.sbahn.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-stadtbahn::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.ubahn.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-bus::before {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.bus.path
		}' fill='%2398AAB3'/%3E%3C/svg%3E");
	}

	&.icon-parkride::before {
		background-image: url("data:image/svg+xml,%3Csvg width='46' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.parkAndRide.path
		}' fill='%2398AAB3' transform='translate(6, -2)'/%3E%3C/svg%3E");
	}

	&.icon-pr::before {
		background-image: url("data:image/svg+xml,%3Csvg width='46' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.parkAndRide.path
		}' fill='%2398AAB3' transform='translate(6, -2)'/%3E%3C/svg%3E");
	}
`;

const StyledSwitchButton = styled.span`
	display: block;
	position: relative;
	background-color: ${colors.grayblue};
	border-radius: 14px;
	cursor: pointer;
	flex-grow: 0;
	flex-shrink: 0;
	width: 42px;
	height: 22px;
	margin-left: 16px;
	transition: background-color 0.4s;
	${props => props.disabled && 'cursor: not-allowed;'}

	${media.lg`
		width: 52px;
		height: 28px;
		margin-left: 24px;
	`}

	&::before {
		content: '';
		position: absolute;
		background-color: ${colors.white};
		border-radius: 50%;
		height: 18px;
		width: 18px;
		left: 2px;
		top: 2px;
		transition: transform 0.4s;

		${media.lg`
			height: 24px;
			width: 24px;
		`}
	}
`;

const Input = styled.input.attrs({ type: 'checkbox' })`
	opacity: 0;
	width: 0;
	height: 0;
	${props => props.disabled && 'cursor: not-allowed;'}

	&:checked + ${StyledSwitchButton} {
		background-color: ${colors.cyandark};

		&::before {
			transform: translateX(20px);

			${media.lg`
				transform: translateX(24px);
			`}
		}
	}
`;

class Switch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: false,
		};
	}

	componentDidMount() {
		this.setCheckedState(this.props.defaultChecked);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.defaultChecked !== this.props.defaultChecked ||
			nextState.isChecked !== this.state.isChecked ||
			nextProps.disabled !== this.props.disabled
		) {
			return true;
		}
		return false;
	}

	componentDidUpdate() {
		this.setCheckedState(this.props.defaultChecked);
	}

	setCheckedState = isChecked => {
		this.setState({ isChecked });
	};

	toggleState = () => {
		if (this.props.onChange) {
			this.props.onChange(!this.state.isChecked);
		}

		this.setState(prevState => ({
			isChecked: !prevState.isChecked,
		}));
	};

	render() {
		const { id, value, label, icon, disabled = false, 'data-tabindex': tabIndex } = this.props;
		const { isChecked } = this.state;

		return (
			<StyledSwitch>
				<StyledLabel
					htmlFor={id}
					disabled={disabled}
					data-tabindex={tabIndex}
					onKeyUp={onlyOnEnter(this.toggleState)}
				>
					<StyledIconLabel className={icon ? `icon icon-${icon}` : ''}>
						{label}
					</StyledIconLabel>
					<Input
						type="checkbox"
						checked={isChecked}
						id={id}
						value={value}
						onChange={e => {
							this.toggleState(e);
						}}
						disabled={disabled}
					/>
					<StyledSwitchButton disabled={disabled} htmlFor={id} />
				</StyledLabel>
			</StyledSwitch>
		);
	}
}

Switch.propTypes = {
	defaultChecked: PropTypes.bool,
	id: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	icon: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	'data-tabindex': PropTypes.number,
};

Switch.defaultProps = {
	icon: '',
	defaultChecked: false,
};

export default Switch;
