import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useDisruptionMenu } from './useDisruptionMenu';
import { Menu } from '../../new-components/Menu';
import { LABEL } from './disruption-menu.config';

const StyledDisruptionMenu = styled(Menu)`
	position: absolute;
	top: 8px;
	left: 8px;
	max-height: calc(100vh - 100px);
	width: 380px;

	@media (min-width: 1280px) {
		width: 460px;
	}
`;

export function DisruptionMenu({ updateMap, updateView, addMarker }) {
	const {
		handleClose,
		loadOptions,
		handleSearchChange,
		dateValue,
		handleDateChange,
		typeValue,
		handleTypeChange,
		typeOptions,
		transportValue,
		handleTransportChange,
		transportOptions,
		sorting,
		handleSortingChange,
		sortOptions,
		items,
		handleSelectItem,
		handleReset,
	} = useDisruptionMenu(updateMap, updateView, addMarker);

	return (
		<StyledDisruptionMenu
			title={LABEL.TITLE}
			onCloseClick={handleClose}
			loadOptions={loadOptions}
			onSearchChange={handleSearchChange}
			dateValue={dateValue}
			dateOnChange={handleDateChange}
			typeValue={typeValue}
			typeOnChange={handleTypeChange}
			typeOptions={typeOptions}
			transportValue={transportValue}
			transportOnChange={handleTransportChange}
			transportOptions={transportOptions}
			items={items}
			onSelectItem={handleSelectItem}
			onResetClick={handleReset}
			onSortChange={handleSortingChange}
			selectedSortOption={sorting}
			sortOptions={sortOptions}
		/>
	);
}

DisruptionMenu.propTypes = {
	updateMap: PropTypes.func.isRequired,
	updateView: PropTypes.func.isRequired,
	addMarker: PropTypes.func.isRequired,
};
