import { API_BASE } from './constants';
import searchLocations from './searchLocations';
import disruptionsClassifier from '../disruptionsClassifier';
import fetchAllStops from './fetchAllStops';

/* Helpers */

/**
 * Checks if a given search date is in a given date range
 *
 * @param {{from: Date, to: Date}} searchRange - treats the date as a range between 00:00 and 24:00
 * @param {{from: Date, to: Date}[]} dateRange
 * @return {boolean}
 */
const isValidAtDateRange = (searchRange, dateRange) => {
	// Recursively check all from-to pairs in dateRange

	// Use timestamps for easy comparison
	const searchStartTimestamp = searchRange.from.getTime();
	const searchEndTimestamp = searchRange.to.getTime();

	// Check from-to-pairs in date range recursively, pick top of list
	const rangeStartTimestamp = dateRange[0].from.getTime();
	const rangeEndTimestamp = dateRange[0].to.getTime();

	// Should return true if searchRange is in dateRange (first recursion stop condition)
	if (rangeStartTimestamp <= searchEndTimestamp && rangeEndTimestamp >= searchStartTimestamp) {
		return true;
	}
	// Recursively check other from-to-pairs
	if (dateRange.length > 1) {
		return isValidAtDateRange(searchRange, dateRange.slice(1));
	}
	// searchRange is not in current or previous from-to pair and no more from-to-pairs to check, there is no match.
	return false;
};

/**
 * Fetches the ids for a list of given stop names.
 * @param {string[]} stopNames
 * @param {import('./fetchAllStops').Stop[]} existingStops
 */
const fetchDestinationNamesAndIdsMap = async (stopNames, existingStops) => {
	// Unfortunately, there are also the destinations, where we only have a name and no id.
	// We SOMETIMES cannot map the name, because it doesn't match any name from the all stops list.
	// Instead, we have to search for each one of them in the very slow search API.
	// To avoid fetching the same destination multiple times, we only fetch the data for all
	// unique destination names.
	// Also, we only fetch the data if we cannot map the name with the all stops list.
	const distinctDestinations = stopNames.filter(
		// Filter duplicates
		(destinationName, index, self) => self.indexOf(destinationName) === index,
	);

	const destinationNamesAndIds = await Promise.all(
		distinctDestinations.map(async (destinationName) => {
			// Check if we can map the destination name to a stop id
			// by one of the name fields from the all stops list
			const stopId = existingStops.find(
				(stop) =>
					stop.name === destinationName ||
					stop.stopNameWithPlace === destinationName ||
					stop.desc === destinationName,
			)?.properties.stopId;
			if (stopId) {
				return {
					name: destinationName,
					id: stopId,
				};
			}

			// If that doesn't work, fallback to the search API
			const mostLikelyLocation = await searchLocations(destinationName).then(
				(response) => response.locations && response.locations[0],
			);

			return {
				name: destinationName,
				id: mostLikelyLocation?.properties?.stopId,
			};
		}),
	);

	/**
	 * A map of destination names to stop ids (easier to access than an array)
	 */
	const destinationNamesAndIdsMap = destinationNamesAndIds.reduce(
		(acc, { name, id }) => ({
			...acc,
			[name]: id,
		}),
		{},
	);

	return destinationNamesAndIdsMap;
};

/**
 * Helper that parses a date range, to keep the filter code clean
 *
 * @param {{from: string, to: string}} dateRange
 * @return {{from: Date, to: Date}[]}
 */
const parseDateRange = (dateRange) =>
	dateRange.map((el) => ({
		from: new Date(el.from),
		to: new Date(el.to),
	}));

/**
 * @typedef {Object} fetchOptions
 * @property {boolean} [fetchAdditionalData="false"] - An optional property with a default value.
 */

/**
 *
 * @param {{type?: string, validAtRange?: {from: Date, to: Date}, lineId?: string, lineIds?: string[], stopId?: string, subTypes: disruptionsClassifier.CLASSES[]}} filters - Filters are AND connected
 * @param {fetchOptions} fetchOptions
 * @return {object}
 */
const fetchDisruptions = async (
	filters = {},
	retries = 0,
	fetchOptions = { fetchAdditionalData: true },
) => {
	try {
		/* Load data */

		// fetch detailed disruptions api
		const response = await fetch(`${API_BASE}ems-info`, {
			headers: {
				accept: 'application/json',
			},
			...fetchOptions,
		});
		const json = await response.json();

		// fetch api with geo coordinates that can be matched with the infoId
		// ids start with ems-{code}
		const geojsonResponse = await fetch(
			`${API_BASE}ems-info?domain=emskom.vvs.de&path=/add-info/vvs/geojson`,
			{
				headers: {
					accept: 'application/json',
				},
			},
		);
		const geojson = await geojsonResponse.json();

		/* Perform filtering */

		// Strip historic data, because we don't show historic data
		const currentDisruptions = json.infos.current;

		// Filter hidden disruptions.
		// NOTE: As this is the fetcher, we only filter those that are always hidden. The dynamic filtering is done somewhere else
		const currentVisibleDisruptions = currentDisruptions.filter(
			(disruption) => !disruptionsClassifier.isOnHideList(disruption),
		);

		// Perform filtering (based on provided filtering parameter)
		const currentVisibleDisruptionsFiltered = currentVisibleDisruptions.filter(
			(disruption) =>
				// Filter by type
				(filters.type ? disruption.type === filters.type : true) &&
				// Filter by subTypes
				(filters.subTypes?.length > 0
					? filters.subTypes.includes(disruptionsClassifier.classify(disruption))
					: true) &&
				// Filter by date
				(filters.validAtRange
					? isValidAtDateRange(
							filters.validAtRange,
							parseDateRange(disruption.timestamps.validity),
					  )
					: true) &&
				// Filter by lineId
				(filters.lineId
					? disruption.affected.lines?.some((el) => el.id === filters.lineId)
					: true) &&
				(filters.lineIds
					? disruption.affected.lines?.some((el) => filters.lineIds.includes(el.id))
					: true) &&
				// Filter by stopId
				(filters.stopId
					? disruption.affected.stops?.some(el => el.id === filters.stopId)
					: true) &&
				(filters.product
					? disruption.affected.lines?.some(line =>
						filters.product.includes(line.product.id),
					)
					: true),
		);

		// add coords to filtered lists
		// coords are extracted from geojson api request and added to detailed disruptions information
		const currentVisibleDisruptionsFilteredWithCoords = [];
		currentVisibleDisruptionsFiltered.forEach((element) => {
			const disruptionClone = JSON.parse(JSON.stringify(element));
			const geojsonElement = geojson.find((el) => el?.properties?.infoId === element?.id);
			if (geojsonElement) {
				disruptionClone.coords = geojsonElement.geometry.coordinates;
				currentVisibleDisruptionsFilteredWithCoords.push(disruptionClone);
			}
		});

		return currentVisibleDisruptionsFilteredWithCoords;
	} catch (err) {
		// TODO: We might be able to remove this for the production system, which doesn't crash every few minutes and restarts only on request (hence retrying)
		console.error('Could not fetch disruptions', err);
		if (retries > 0) {
			console.log('Trying to fetch disruptions again...');
			return new Promise((resolve, reject) => {
				setTimeout(async () => {
					fetchDisruptions(filters, retries - 1, fetchOptions)
						.then(resolve)
						.catch(reject);
				}, 500);
			});
		}
		return [];
	}
};

export default fetchDisruptions;
