import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	padding: 40px;
	transform-origin: center center;
	animation: ${fadeIn} 0.5s ease-in-out;
	z-index: 1000;

	&,
	&::before {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&::before {
		background-color: #555;
		background-color: rgba(0, 0, 0, 0.5);
		content: '';
		display: block;
	}

	&:hover {
		cursor: pointer;
	}
`;

export default Wrapper;
