import { API_BASE } from './constants';

const searchCache = {};

/**
 * Search for specific lines with the given input.
 *
 * @name searchLocations
 * @function
 * @param {string} input        The input string.
 * @param {object} fetchOptions Options for the fetch request.
 * @return {object[]} An array of lines objects.
 */
export default async (input, fetchOptions = {}) => {
	if (!input) {
		return [];
	}
	if (typeof searchCache[input] === 'undefined') {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		searchCache[input] = fetch(
			`${API_BASE}/XML_SERVINGLINES_REQUEST?outputFormat=rapidJSON&lineName=${encodeURIComponent(
				input,
			)}&mode=line`,
			fetchOptions,
		)
			.then((response) => response.json())
			.then((json) => {
				// filter all none vvs lines
				if (json.lines) {
					json.lines = json.lines.filter((item) => item.id.startsWith('vvs'));
				}
				return json;
			});
	}
	return searchCache[input];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
