import { searchCategories } from '../../../utils/config';

export default (marker, markerContext) => {
	try {
		const selectedMarker = marker;
		selectedMarker.type = 'city';
		let filters = searchCategories.cityticket.filter;
		markerContext.setContext({
			marker: selectedMarker,
			filters,
		});
		document.querySelector('#searchinput').blur();
	} catch (e) {
		// Do nothing on errors
	}
};
