import React from 'react';
import ReactDOM from 'react-dom';
import setDataAttributes from '../../../../utils/setDataAttributes';
import renderSharingTooltip from './renderSharingTooltip';
import { fetchers, nameToIcon, IconCityTicket } from 'shared';
import TextBlockLoadingIndicator from '../../../TextBlockLoadingIndicator';
import { getVvsUriWithDeeplink } from '../../../../utils/vvsUris';
import renderParkAndRideTooltip from './renderParkAndRideTooltip';

export default function renderTooltip(
	type,
	marker,
	map,
	backendData,
	elementId = 'tooltip',
	state = 2,
	offset = [0, 0],
	openPopup,
	operators,
) {
	//dont render hover tooltip if state === 3
	//marker is selected and already has other tooltip
	if ((marker.get('state') === 3 || state === 3) && elementId === 'tooltip') {
		return;
	}
	const tooltip = document.getElementById(elementId);
	map.getOverlayById(elementId).setOffset(offset);
	const markerTooltip = document.createDocumentFragment();
	switch (type) {
		case 'stop':
			renderStopTooltip(backendData, markerTooltip);
			break;
		case 'sharing':
			renderSharingTooltip(backendData, operators, markerTooltip, null, openPopup);
			break;

		case 'position':
			renderPositionTooltip(backendData, markerTooltip, marker, state);
			break;
		case 'poi':
			renderPOITooltip(backendData, markerTooltip, state);
			break;
		case 'pr':
			renderParkAndRideTooltip(marker, markerTooltip);
			break;
		case 'tip':
			renderTippTooltip(backendData, markerTooltip, state);
			break;
		case 'city':
			rendercityticket(marker, markerTooltip);
			break;
		case 'disruption':
			renderDisruptionTooltip(backendData, markerTooltip);
			break;
		default:
			renderDefaultTooltip(markerTooltip);
			break;
	}
	tooltip.innerHTML = '';
	tooltip.appendChild(markerTooltip);
	const tooltipPosition = marker.get('tooltipPos')
		? marker.get('tooltipPos')
		: marker.getGeometry().getCoordinates();
	map.getOverlayById(elementId).setPosition(tooltipPosition);
	tooltip.style.display = '';
}

function rendercityticket(marker, markerTooltip) {
	ReactDOM.render(
		<div className="marker marker--city">
			<div className="marker__tooltip">
				<h2 className="tooltipcontent__title">
					<IconCityTicket></IconCityTicket>
					{marker.get('Name1')}
					<br></br>
				</h2>
				<div className="tooltipcontent__content">
					<p>
						<strong>StadtTicket für 1 Person</strong>
						<br></br>
						Preis {marker.get('onePersonPrice')}
					</p>
					<p>
						<strong>StadtTicket für bis zu 5 Personen</strong>
						<br></br>
						Preis {marker.get('fivePersonPrice')}
					</p>
					{marker.get('monthlyTicket') && (
						<>
							<p>
								<strong>MonatsTicket</strong>
								<br></br>
								Preis {marker.get('monthlyTicket')}
							</p>
						</>
					)}
					<hr />
					<p>
						{marker.get('vvsTicketLink') && (
							<>
								<a
									href={marker.get('vvsTicketLink')}
									target="_blank"
									rel="noopener noreferrer"
								>
									Geltungsbereich als PDF
								</a>
								<br />
							</>
						)}
						<a
							href={
								'https://www.vvs.de/tickets/einzelne-fahrten-tagestickets/stadtticket'
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							Weitere Informationen
						</a>
					</p>
				</div>
			</div>
		</div>,
		markerTooltip,
	);
}

function renderDefaultTooltip(markerTooltip) {
	ReactDOM.render(
		<div className="marker">
			<span className="marker__tooltip"></span>
		</div>,
		markerTooltip,
	);
}

function renderDisruptionTooltip(disruption, markerTooltip) {
	ReactDOM.render(
		<div className="marker">
			<span className="marker__tooltip">{disruption.subtitle}</span>
		</div>,
		markerTooltip,
	);
}

function renderStopTooltip(stop, markerTooltip) {
	ReactDOM.render(
		<div className="marker--stop">
			<span className="marker__tooltip">
				{stop.desc}, {stop.locality}
			</span>
		</div>,
		markerTooltip,
	);
}

function renderPositionTooltip(position, markerTooltip, marker, state) {
	let tooltip = document.createElement('span');
	tooltip.className = 'marker marker--position';
	let delay = marker.get('delay');
	setDataAttributes(tooltip, {
		title: `${position.line} - ${position.direction}`,
		locationtype: 'position',
		type: position.type,
		delay: delay,
		state: state,
		vvsid: `position-${position.journeyIdentifier}`,
		oldid: `position-${position.id}`,
	});

	ReactDOM.render(
		<span className="marker__tooltip">
			{position.line} - {position.direction}
			{delay && delay > -1 ? (
				<>
					<br />
					<span className="orange">+{delay}</span>
				</>
			) : (
				''
			)}
		</span>,
		tooltip,
	);
	markerTooltip.appendChild(tooltip);
	marker.set('tooltip', 'tooltip');
}

function renderPOITooltip(poi, markerTooltip, state) {
	try {
		const coords = poi.coord;
		if (Array.isArray(coords)) {
			fetchers.fetchLocationInfo(coords[0], coords[1]).then(data => {
				if (data.locations.length && data.locations[0].type === 'address') {
					const addressEl = document.getElementById(`poi-marker-address-${poi.id}`);
					const address = data.locations[0].name;
					if (addressEl) addressEl.innerText = address;
				}
			});
		}
	} catch (_) {
		// do nothing
	}
	ReactDOM.render(
		<div
			data-title={poi.name}
			data-subtitle={poi.parent?.name}
			data-address={poi.address || ''}
			data-category={poi.category}
			data-locationtype={'poi'}
			data-vvsid={poi.id}
			data-coords={JSON.stringify(poi.coord)}
			data-state={state}
			className={`marker marker--poi marker--poi-${nameToIcon(poi.category)}`}
		>
			<span className="marker__tooltip">
				{poi.name}
				<div id={`poi-marker-address-${poi.id}`} className="marker__address">
					<TextBlockLoadingIndicator rows={0} />
				</div>
				<a
					href={getVvsUriWithDeeplink(null, poi.id)}
					target="_blank"
					rel="noopener noreferrer"
					className="marker__tooltip__link"
					onClick={() => {
						if (typeof _paq !== 'undefined') {
							// eslint-disable-next-line no-underscore-dangle
							window._paq.push([
								'trackEvent',
								'LivKarte', // Category
								'fahrplanauskunftanfordern', // Action
								'InteressanteOrte', // Name
							]);
						}
					}}
				>
					Fahrplanauskunft
				</a>
			</span>
		</div>,
		markerTooltip,
	);
}

function renderTippTooltip(tip, markerTooltip, state) {
	ReactDOM.render(
		<div
			data-id={tip.id}
			data-vvsid={tip.id}
			data-title={tip.title}
			data-image={JSON.stringify(tip.image)}
			data-introtext={tip.introtext}
			data-address={JSON.stringify(tip.address)}
			data-link={tip.link}
			data-longtext={tip.longtext}
			data-locationtype={'tip'}
			data-state={state}
			className={`marker marker--tip`}
		>
			<span className="marker__tooltip">{tip.title}</span>
		</div>,
		markerTooltip,
	);
}
