import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/src/layer/Vector';
import { fromLonLat } from 'ol/src/proj';
import VectorSource from 'ol/src/source/Vector';
import { Icon, Stroke, Style } from 'ol/style';

import EmptyMarker from '../../../assets/disruption/empty-marker.png';

/**
 * @typedef {import('./useDisruptionMenu').SearchOption} Item
 */

/**
 * @param {Item} options
 */
export function createDisruptionSearchMarker({ label, coord, icon }) {
	const disruptionSearchMarker = new Feature({
		geometry: new Point(fromLonLat(coord.reverse())),
		name: label,
	});

	const bgMarkerStyle = new Style({
		stroke: new Stroke({
			color: '#000',
			width: 4,
		}),
		image: new Icon({
			scale: [0.5, 0.5],
			anchor: [0.5, 1],
			displacement: [0, 10],
			src: EmptyMarker,
		}),
	});

	const disruptionSearchMarkerStyle = new Style({
		image: new Icon({
			anchor: [0.5, 1],
			displacement: [0, 21],
			src: icon,
		}),
	});

	disruptionSearchMarker.setStyle([bgMarkerStyle, disruptionSearchMarkerStyle]);

	return disruptionSearchMarker;
}

/**
 * @param {object} map - ol/map
 * @param {Item} item
 */
export function addDisruptionSearchMarker(map, item) {
	const markerLayer =
		map
			.getLayers()
			.getArray()
			.find(layer => layer.get('name') === 'disruption-search-layer') ||
		(() => {
			const source = new VectorSource({ features: [] });

			const layer = new VectorLayer({ source });
			layer.set('name', 'disruption-search-layer');
			layer.setZIndex(999);

			map.addLayer(layer);
			return layer;
		})();

	const source = markerLayer.getSource();
	source.forEachFeature(feat => source.removeFeature(feat));

	if (!item) return;

	const marker = createDisruptionSearchMarker(item);
	source.addFeature(marker);

	const center = marker.getGeometry().getCoordinates();
	map.getView().animate({ center, zoom: 16, duration: 1000 });
}
