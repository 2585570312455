import React, { Component } from 'react';
import Overlay from 'ol/src/Overlay';
// import PropTypes from 'prop-types';

import {
	fetchers,
	cloneObject,
	MapComponent,
	MapWrapper,
	Tooltip,
	SettingsButton as OffCanvasMenuOpener,
	getLayerByName,
	getCoordOfPoint,
	// createMarker,
	disruptionsClassifier,
	Button,
} from 'shared';
import { toLonLat, fromLonLat } from 'ol/src/proj';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

import updatePOIs from './Markers/updatePOIs';
import updatePositions from './Markers/updatePositions';
import updateStopMarkers from './Markers/updateStopMarkers';
import updateSharingStations from './Markers/updateSharingStations';
import updateTipps from './Markers/updateTipps';
import updateDotMarker from './Markers/updateDotMarker';

import Search from '../Search';
import OffCanvasMenu from '../OffCanvasMenu';
import Subheadline from '../OffCanvasMenu/Subheadline';
import InfoMessage from '../OffCanvasMenu/InfoMessage';
import List from '../OffCanvasMenu/List';
import Menu from '../Menu';
import MenuTile from '../Menu/menutile';
import MenuOpener from '../Menu/menuopener';
import CategoryMenu from '../CategoryMenu';

import InfoOverlay from '../InfoOverlay';
import FooterButton from '../InfoOverlay/footerButton';

import MarkerContext from '../../contexts/MarkerContext';

import StopList from '../CategoryList/StopList';
import TippsList from '../CategoryList/TippsList';
import poiMarkerDetails from './MarkerDetails/poiMarkerDetails';
import sharingMarkerDetails from './MarkerDetails/sharingMarkerDetails';
import cityShapeDetails from './MarkerDetails/cityShapeDetails';
import stopMarkerDetails from './MarkerDetails/stopMarkerDetails';
import tipMarkerDetails from './MarkerDetails/tipMarkerDetails';
import parkAndRideDetails from './MarkerDetails/parkAndRideDetails';

import escapeRegExp from '../../utils/escapeRegExp';
import {
	addSharingOperator,
	sharingMappings,
	searchCategories,
	mapConfig,
	sharingTitleMappings,
	colors,
	fonts,
} from '../../utils/config';

import Popup from '../Popup';
import updateParkAndRide from './Markers/updateParkAndRide';

import { positionIcons, positionIconsRT } from './Markers/MarkerSources';
import loadCityShapes from './Geodata/loadCityShapes';
import loadLines from './Geodata/loadLines';
import updateDisruptionsMarkers, {
	DISRUPTIONS_MARKER_TYPES,
} from './Markers/updateDisruptionsMarkers';
import disruptionsMarkerDetails from './MarkerDetails/disruptionsMarkerDetails';
import { tabManager } from '../../utils/tab-manager';
import { onlyOnEnter } from '../../utils/events';

import DisruptionsIntroductionOverlay from '../new-components/DisruptionsIntroductionOverlay';
import { DisruptionMenu } from './disruption-menu';
import { dispatchDisruptionClusterClickEvent } from './disruption-menu/disruption-menu.config';
import { addDisruptionSearchMarker } from './disruption-menu/disruption-search-marker';

/**
 * Initial state of the sidebar filters
 * (no filters applied)
 */
const getInitialSidebarFilters = () => ({
	stops: {
		Haltestellen: 0, // Alle Haltestellen
		Linien: 0,
		// 'R-Bahn': 0, // 6
		// 'S-Bahn': 0, // 2
		// Stadtbahn: 0, // 1
		// Bus: 0, // 3
		'R-Bahn': 0, // 0
		'S-Bahn': 0, // 1
		Stadtbahn: 0, // 3
		Bus: 0, // 5
	},
	pois: {
		Freizeit: 0,
		'Essen & Trinken': 0,
		Kultur: 0,
		Sehenswürdigkeiten: 0,
		'Rund ums Rad': 0,
		Bildungseinrichtungen: 0,
		'Krankenhäuser & Kliniken': 0,
		'Verbände & Vereine': 0,
	},
	sharing: {
		Flinkster: 0,
		RegioRad: 0,
		Stella: 0,
		ShareNow: 0,
		Stadtmobil: 0,
	},
	// positions: {
	// 	'R-Bahn': 0, // 0
	// 	'S-Bahn': 0, // 1
	// 	Stadtbahn: 0, // 3
	// 	Bus: 0, // 5
	// },
	tipps: {
		Ausflugstipps: 0,
	},
	parkAndRide: {
		'Park & Ride': 0,
	},
	cityticket: {
		StadtTicket: 0,
	},
	disruptions: {
		[disruptionsClassifier.CLASSES.Störung]: 0,
		[disruptionsClassifier.CLASSES.BaustellenUndUmleitungen]: 0,
		[disruptionsClassifier.CLASSES.Aufzüge]: 0,
	},
});

class Map extends Component {
	constructor(props) {
		super(props);

		const pathname = window.location.href
			.replace(new RegExp(`^${escapeRegExp(document.baseURI)}`), '')
			.replace(new RegExp(`${escapeRegExp(window.location.search)}$`), '');
		let position = false;
		let filterType = false;
		let markerId = false;
		let uriParams = [];
		let homeMarker = null;

		const urlParams = new URLSearchParams(window.location.search);

		if (pathname.search('@') > -1) {
			const pathnameParams = pathname.split('@');
			const mapPosition = pathnameParams.pop().split(',');
			if (mapPosition.length === 3) {
				position = {
					lat: parseFloat(mapPosition[0]),
					lng: parseFloat(mapPosition[1]),
					zoom: parseInt(mapPosition[2].replace(/^z/, ''), 10),
				};

				if (urlParams.get('addMarker') === '1') {
					homeMarker = {
						type: 'singlehouse',
						coord: [position.lat, position.lng],
					};
				}
			}

			uriParams = pathnameParams.join('@').split('/');
		} else {
			uriParams = pathname.split('/');
		}

		if (uriParams[0]) {
			filterType = decodeURIComponent(uriParams[0]);
			if (uriParams[1]) {
				markerId = decodeURIComponent(uriParams[1]);
			}
		}

		this.state = {
			map: null,
			tooltip: null,
			useAutofocus: urlParams.get('autofocus') !== '0',
			markerLayer: null,
			filterType,
			markerId,
			position,
			positionSet: false,
			operators: {},
			homeMarker,
			sharingCatMenuItems: [],
			disruptionFeatures: [],
			dotMarker: [],
			sidebarFilters: getInitialSidebarFilters(),
			disruptionsInfoOverlayOpen: false, // Hidden by default. Might be shown later by localStorage
		};

		this.menu = React.createRef();
		this.infooverlay = React.createRef();

		this.localStorageSupport = false;

		const { localStorage } = window;
		try {
			const test = 'test';
			localStorage.setItem(test, test);
			localStorage.removeItem(test);

			let filters = localStorage.getItem('mapFilters');
			if (filters !== null) {
				filters = JSON.parse(filters);
				Object.keys(filters).forEach(key => {
					if (Object.prototype.hasOwnProperty.call(this.state.sidebarFilters, key)) {
						if (this.state.sidebarFilters[key] === null) {
							this.state.sidebarFilters[key] = {};
						}
						Object.keys(filters[key]).forEach(category => {
							if (
								key !== 'sharing' &&
								typeof this.state.sidebarFilters[key][category] === 'undefined'
							) {
								return;
							}
							if (filters[key][category]) {
								this.state.sidebarFilters[key][category] = 2;

								if (key === 'sharing' && category === 'Car2Go') {
									this.state.sidebarFilters[key].ShareNow = 2;
								}
							} else {
								this.state.sidebarFilters[key][category] = 0;
							}
						});
					}
				});
			}
			// Only show the disruptions introduction overlay if the user has not seen it before
			const disruptionsIntroductionOverlaySeen = localStorage.getItem(
				'disruptionsIntroductionOverlaySeen',
			);
			if (!disruptionsIntroductionOverlaySeen) {
				this.state.disruptionsInfoOverlayOpen = true;
				// Set localStorage item to true, so that the overlay is not shown again
				localStorage.setItem('disruptionsIntroductionOverlaySeen', true);
			}
			this.localStorageSupport = true;
		} catch (e) {
			// do nothing
		}
	}

	componentDidMount() {
		// no clue why this is an extra class but added this as RRP.js does
		document.documentElement.classList.add('prevent-scroll');
		this.loadOperators();
	}

	loadOperators() {
		const { filterType, markerId } = this.state;
		const { setSharingMarkers, marker } = this.context;

		fetchers.fetchSharingOperators().then(operators => {
			Object.keys(operators).forEach(operator => {
				if (['TIER', 'Deer'].indexOf(operator) > -1) {
					/* eslint-disable-next-line no-param-reassign */
					delete operators[operator];
				}
				if (typeof sharingMappings[operator] !== 'undefined') {
					/* eslint-disable-next-line no-param-reassign */
					operators[sharingMappings[operator]] = cloneObject(operators[operator]);
					/* eslint-disable-next-line no-param-reassign */
					delete operators[operator];
				}
			});
			const operatorKeys = Object.keys(operators);
			if (this.state.sidebarFilters.sharing === null) {
				this.state.sidebarFilters.sharing = {};
			}
			if (operatorKeys.length > 0) {
				const sharingFilters = {};
				operatorKeys.forEach(operator => {
					addSharingOperator(operator);
					if (typeof this.state.sidebarFilters.sharing[operator] !== 'undefined') {
						sharingFilters[operator] = this.state.sidebarFilters.sharing[operator];
					} else {
						// Default Value for Sharing-Operators:
						sharingFilters[operator] = 0;
					}
				});
				this.setState(
					({ sidebarFilters }) => ({
						sidebarFilters: {
							...sidebarFilters,
							sharing: sharingFilters,
						},
					}),
					() => {
						this.onFilterUpdate('sharing');
					},
				);

				if (this.state.map !== null) {
					const filters = this.getMergedFilters();
					let sharingMarker = marker;
					if (marker !== null && marker.type !== 'sharing') {
						sharingMarker = null;
					}
					updateSharingStations(
						this.state.map,
						operators,
						filters.sharing,
						markers => {
							setSharingMarkers(markers);
						},
						sharingMarker,
						true,
						this.context.openPopup,
						clickedMarker => {
							sharingMarkerDetails(clickedMarker, this.context);
						},
					);
				}
			}

			this.setState({ operators });

			const sharingCatMenuItemsInitial = [
				{
					id: 1,
					icon: 'alle',
					title: 'Alle',
					titlefilter: 'Sharing-Angebote',
					filter: searchCategories.sharing.filter,
					trackData: ['LivKarteSharingAnbieter', 'auswählen', 'Alle'],
				},
			];

			if (typeof searchCategories['sharing-stella'] !== 'undefined') {
				sharingCatMenuItemsInitial.push({
					id: 2,
					icon: 'stella',
					title: 'Stella',
					titlefilter: 'Stella',
					filter: searchCategories['sharing-stella'].filter,
					trackData: ['LivKarteSharingAnbieter', 'auswählen', 'UKatStella'],
				});
			}
			if (typeof searchCategories['sharing-sharenow'] !== 'undefined') {
				sharingCatMenuItemsInitial.push({
					id: 3,
					icon: 'sharenow',
					title: 'ShareNow',
					titlefilter: 'ShareNow',
					filter: searchCategories['sharing-sharenow'].filter,
					trackData: ['LivKarteSharingAnbieter', 'auswählen', 'UKatCar2Go'],
				});
			}
			if (typeof searchCategories['sharing-flinkster'] !== 'undefined') {
				sharingCatMenuItemsInitial.push({
					id: 4,
					icon: 'flinkster',
					title: 'Flinkster',
					titlefilter: 'Flinkster',
					filter: searchCategories['sharing-flinkster'].filter,
					trackData: ['LivKarteSharingAnbieter', 'auswählen', 'UKatFlinkster'],
				});
			}
			if (typeof searchCategories['sharing-stadtmobil'] !== 'undefined') {
				sharingCatMenuItemsInitial.push({
					id: 5,
					icon: 'stadtmobil',
					title: 'Stadtmobil',
					titlefilter: 'Stadtmobil',
					filter: searchCategories['sharing-stadtmobil'].filter,
					trackData: ['LivKarteSharingAnbieter', 'auswählen', 'UKatStadtmobil'],
				});
			}
			if (typeof searchCategories['sharing-regiorad'] !== 'undefined') {
				sharingCatMenuItemsInitial.push({
					id: 6,
					icon: 'regiorad',
					title: 'RegioRad',
					titlefilter: 'RegioRad',
					filter: searchCategories['sharing-regiorad'].filter,
					trackData: ['LivKarteSharingAnbieter', 'auswählen', 'UKatRegioRad'],
				});
			}
			this.setState({ sharingCatMenuItems: sharingCatMenuItemsInitial });

			if (filterType) {
				const newContext = {};
				const catKey = Object.keys(searchCategories).find(
					category => searchCategories[category].filter.filterType === filterType,
				);
				if (catKey) {
					newContext.filters = searchCategories[catKey].filter;

					if (markerId) {
						newContext.marker = {
							id: markerId,
							type: searchCategories[catKey].filter.markerType,
						};
					}

					this.context.setContext(newContext);
				}
				if (this.state.map !== null) {
					this.updateView(this.state.map);
				}
			}
		});
	}

	getMergedFilters() {
		const filters = cloneObject(this.state.sidebarFilters);

		if (this.context.filters) {
			Object.keys(filters).forEach(type => {
				if (['filterType', 'markerType'].indexOf(type) > -1 || filters[type] === null) {
					return;
				}
				Object.keys(filters[type]).forEach(category => {
					if (filters[type][category] === 2) {
						filters[type][category] = 0;
					}
				});
			});

			const contextFilters = this.context.filters;
			Object.keys(contextFilters).forEach(type => {
				if (['filterType', 'markerType'].indexOf(type) > -1) {
					filters[type] = contextFilters[type];
					return;
				}
				if (typeof filters[type] === 'undefined') {
					filters[type] = {};
				}
				Object.keys(contextFilters[type]).forEach(category => {
					filters[type][category] = contextFilters[type][category];
				});
			});
		}

		return filters;
	}

	async updateFeatureClicked(map, isFeatureClicked, clickedLines, setFilters) {
		const { setMarker, setLines, marker, lines } = this.context;

		/*
			This function being called can mean multiple things:
			1. A new marker is selected.
			2. A marker is deselected.
			3. One or more lines are selected.
		*/

		// If a marker is selected, do some custom zooming
		// NOTE: Some other part of the applications handles updating the context
		if (isFeatureClicked) {
			// If marker is a disruption, center it and zoom in, so that users can inspect the line shapes
			const isMarkerDisruption = DISRUPTIONS_MARKER_TYPES.includes(marker?.get('type'));
			if (isMarkerDisruption) {
				// Always set center to currently selected marker
				map.getView().setCenter(marker.getGeometry().getCoordinates());
				// Zoom in so that lines are visible (larger number = more zoomed in)
				// If lines are already visible, do not zoom out.
				const zoom = map.getView().getZoom();
				const lineZoom = mapConfig.minZoomLevel.stops + mapConfig.minZoom;
				if (zoom < lineZoom) {
					// Zoom in
					map.getView().setZoom(lineZoom);
				}
			}
		}

		// If a marker is deselected, and it was a disruption, remove lines from state (because these can have lines)
		// If marker was a disruption, remove lines from state
		const isMarkerDisruption = disruptionsClassifier.isAnyDisruption(
			marker?.get?.('originalDisruption'),
		);
		if (!isFeatureClicked && isMarkerDisruption) {
			// Reset marker
			await new Promise(res => setMarker(null, res));
			// Remove lines from state
			await new Promise(res => setLines(null, res)); // Wait for state to be updated, because we might change lines again later
		}

		// Only for disruptions: Handle what happens when a marker is deselected,
		// i.e., which lists and menus to close and open
		const isFilterDisruption = [
			'disruptions',
			'disruptionsAll',
			'disruptionsStörungen',
			'disruptionsBaustellenUndUmleitungen',
			'disruptionsAufzüge',
		].includes(this.context.filters?.filterType);
		if (isFilterDisruption) {
			// If nothing was clicked (no lines, no markers), and a marker is currently selected,
			// close the marker details but keep the list of disruptions open.
			if (!isFeatureClicked && !clickedLines?.length > 0 && marker) {
				await new Promise(res => setMarker(null, res));
				dispatchDisruptionClusterClickEvent({ features: [] });
			}
			// If lines were clicked, and a marker is currently selected,
			// close the marker details and the list of disruptions to make space
			// for the list of disruptions on the line (lines will be pushed in a later step)
			else if (isFeatureClicked && clickedLines?.length > 0 && marker) {
				await new Promise(res => setMarker(null, res));
				await new Promise(res => this.setState({ disruptionFeatures: [] }, res));
			}
			// If no marker is selected, and nothing was clicked,
			// close everything
			else if (!isFeatureClicked && !clickedLines?.length > 0 && !marker) {
				await new Promise(res => setMarker(null, res));
				await new Promise(res => this.setState({ disruptionFeatures: [] }, res));
				if (lines?.length === 0) await new Promise(res => setLines(null, res));
			}
		}

		/*
			If one or more lines was clicked, add them to context
			and remove the marker (line was clicked and not a marker)
		*/
		if (clickedLines?.length > 0) {
			await new Promise(res => setMarker(null, res));
			await new Promise(res => setLines(clickedLines, res));
		} else if (!isFilterDisruption) {
			// reset lines if none were clicked except for disruption
			await new Promise(res => setLines(null, res));
		}

		// If user clicks on a line in standard view, change into OPNV view
		// However, do not change into OPNV view if user clicks on a line in disruptions view
		if (clickedLines?.length > 0) {
			// Only change view if user is not in OPNV view
			// and not in disruptions view
			if (
				this.context.filters?.filterType &&
				![
					'stops', // OEPNV
					'disruptions', // Disruptions
					'disruptionsAll', // Disruptions
					'disruptionsStörungen', // Disruptions
					'disruptionsAufzüge', // Disruptions
					'disruptionsBaustellenUndUmleitungen', // Disruptions
				].includes(this.context.filters.filterType)
			) {
				await new Promise(res => setFilters(searchCategories.stops.filter, res));
			}
		}

		// In every case, update map to reflect newest changes
		this.updateView(this.state.map);
	}

	// typeFilter = main category the user is currently in
	updateView = (map = this.state.map, typeFilter = null) => {
		const activeFilters = {};
		const mergedFilters = this.getMergedFilters();
		const {
			marker,
			setStopMarkers,
			setDisruptionsMarkers,
			setSharingMarkers,
			setPoiMarkers,
			setTipMarkers,
			setParkAndRideMarkers,
			openPopup,
			filters,
			setVehicleMarkers,
			lines,
		} = this.context;

		// marker -> is selected marker, if a map marker is clicked
		if (!marker) {
			// Hide tooltip
			const tooltip = document.getElementById('tooltip');
			if (tooltip) {
				tooltip.style.display = 'none';
			}
			// Hide and clear selected tooltip
			const selectedTooltip = document.getElementById('tooltip-selected');
			if (selectedTooltip) {
				// remove inner html to not mess with tooltip ids
				selectedTooltip.innerHTML = '';
				selectedTooltip.style.display = 'none';
			}
		}

		loadLines(null, lines, mergedFilters, map.getView().getZoom());

		this.updateUri(map, mergedFilters, marker);
		Object.keys(mergedFilters).forEach(type => {
			if (['filterType', 'markerType'].indexOf(type) > -1) {
				return;
			}
			if (mergedFilters[type] === null) {
				activeFilters[type] = [];
			} else {
				activeFilters[type] = Object.keys(mergedFilters[type]).filter(
					category => mergedFilters[type][category],
				);
			}
		});

		// close offcanvas menu if not in main filter view
		if (filters !== null) {
			this.closeOffCanvas();
		}

		if (typeFilter === null || typeFilter === 'cityticket') {
			let cityMarker = marker;
			if (marker !== null && marker.type !== 'city') {
				cityMarker = null;
			}
			loadCityShapes(
				false,
				map,
				clickedMarker => {
					cityShapeDetails(clickedMarker, this.context);
				},
				mergedFilters,
				cityMarker,
				filters,
			);
		}

		if (typeFilter === null || typeFilter === 'stops' || typeFilter === 'cityticket') {
			let stopMarker = marker;
			if (marker !== null && marker.type !== 'stop' && marker.type !== 'city') {
				stopMarker = null;
			}
			updateStopMarkers(
				map,
				mergedFilters.stops.Haltestellen,
				markers => {
					setStopMarkers(markers);
				},
				stopMarker,
				clickedMarker => {
					stopMarkerDetails(clickedMarker, this.context, false);
				},
				mergedFilters.cityticket.StadtTicket,
				filters,
				clickedMarkerCityMode => {
					stopMarkerDetails(clickedMarkerCityMode, this.context, true);
				},
			);
		}

		if (typeFilter === null || typeFilter === 'pois') {
			let poiMarker = marker;
			if (marker !== null && marker.type !== 'poi') {
				poiMarker = null;
			}
			updatePOIs(
				map,
				mergedFilters.pois,
				markers => {
					setPoiMarkers(markers);
				},
				poiMarker,
				clickedMarker => {
					poiMarkerDetails(clickedMarker, this.context);
				},
			);
		}

		if (typeFilter === null || typeFilter === 'sharing') {
			if (Object.keys(this.state.operators).length > 0) {
				let sharingMarker = marker;
				if (marker !== null && marker.type !== 'sharing') {
					sharingMarker = null;
				}
				updateSharingStations(
					map,
					this.state.operators,
					mergedFilters.sharing,
					markers => {
						setSharingMarkers(markers);
					},
					sharingMarker,
					false,
					openPopup,
					clickedMarker => {
						sharingMarkerDetails(clickedMarker, this.context);
					},
				);
			}
		}

		if (typeFilter === null || typeFilter === 'stops') {
			updatePositions(map, mergedFilters.stops, markers => {
				setVehicleMarkers(markers);
			});
		}

		if (typeFilter === null || typeFilter === 'tipps') {
			let tipMarker = marker;
			if (marker !== null && marker.type !== 'tip') {
				tipMarker = null;
			}
			updateTipps(
				map,
				mergedFilters.tipps.Ausflugstipps,
				markers => {
					setTipMarkers(markers);
				},
				tipMarker,
				clickedMarker => {
					tipMarkerDetails(clickedMarker, this.context);
				},
			);
		}

		if (typeFilter === null || typeFilter === 'parkAndRide') {
			let prMarker = marker;
			if (marker !== null && marker.type !== 'pr' && marker.type !== 'stop') {
				prMarker = null;
			}
			updateParkAndRide(
				map,
				mergedFilters.parkAndRide['Park & Ride'],
				markers => {
					setParkAndRideMarkers(markers);
				},
				prMarker,
				clickedMarker => {
					parkAndRideDetails(clickedMarker, this.context);
				},
			);
		}

		if (
			typeFilter === null ||
			typeFilter === 'disruptions' ||
			typeFilter === 'disruptionsAll'
		) {
			// check if the disruptions screen isn't active
			// reset disruptions features length once if necessary
			// this prevents the list from being filled if your reenter the disruptions after closing
			// and showing an active list of disruptions
			if (
				!mergedFilters.disruptions ||
				(mergedFilters.disruptions &&
					mergedFilters.disruptions[disruptionsClassifier.CLASSES.Störung] !== 2 &&
					mergedFilters.disruptions[
						disruptionsClassifier.CLASSES.BaustellenUndUmleitungen
					] !== 2 &&
					mergedFilters.disruptions[disruptionsClassifier.CLASSES.Aufzüge] !== 2)
			) {
				if (this.state.disruptionFeatures.length > 0) {
					this.setState({ disruptionFeatures: [] });
				}
			}
			updateDisruptionsMarkers(
				mergedFilters,
				setDisruptionsMarkers,
				marker,
				selectedMarker => {
					// Set marker to selected marker
					this.context.setMarker(selectedMarker);

					disruptionsMarkerDetails(selectedMarker, this.context);
					/* If selected marker has affected lines with sufficient information, show them on the map */
					// const affectedLines = selectedMarker.get('affectedLines');
					// if (affectedLines?.length > 0) {
					// 	// Get line ids from affected lines and parse them to int
					// 	const lineIds = affectedLines.map(line => parseInt(line.id, 10));

					// 	this.context.setLines(lineIds, () => {
					// 		// Trigger update logic, because disruptions can be clicked without clicking a marker on the map,
					// 		// namely from the disruptions list and the disruptions details
					// 		this.updateFeatureClicked(
					// 			this.state.map,
					// 			true,
					// 			null,
					// 			this.context.setFilters,
					// 		);
					// 	});
					// }
				},
				disruptionFeatures => {
					this.context.setMarker(null);
					// this.context.setLines([], () => this.updateView(this.state.map));
					this.setState({ disruptionFeatures });

					dispatchDisruptionClusterClickEvent({ features: disruptionFeatures });
				},
				map,
			);
		}

		map.getViewport().setAttribute('data-zoom', map.getView().getZoom());
		tabManager.reinitialize();
	};

	updateMap = (map = this.state.map) => {
		const { positionSet, position, homeMarker, tooltip } = this.state;

		loadCityShapes(
			true,
			map,
			clickedMarker => {
				cityShapeDetails(clickedMarker, this.context);
			},
			null,
		);
		loadLines(map, this.context.lines, null, map.getView().getZoom());

		// adds tooltip div to Map once
		// tooltip div is set in shared
		if (map !== null && tooltip === null) {
			const tooltipRef = document.getElementById('tooltip');
			this.setState({ tooltip: tooltipRef });
			const overlay = new Overlay({
				id: 'tooltip',
				element: tooltipRef,
				offset: [0, 0],
				positioning: 'top-center',
			});
			map.addOverlay(overlay);
			const tooltipSelectedRef = document.getElementById('tooltip-selected');
			const overlaySelected = new Overlay({
				id: 'tooltip-selected',
				element: tooltipSelectedRef,
				offset: [0, 0],
				positioning: 'top-center',
			});
			map.addOverlay(overlaySelected);
		}

		const transportationLayer = getLayerByName(map, 'transportation');
		//
		// Set styles for transportation layer
		//
		transportationLayer.setStyle(feature => {
			let src = '';
			// positionIcons, positionIconsRT
			const transportationIcons = {
				'S-Bahn': 'sbahn',
				Stadtbahn: 'stadtbahn',
				Bus: 'bus',
				'R-Bahn': 'rbahn',
				'SEV-Bus': 'bus',
			};
			if (feature.get('realtime') === 1) {
				src = positionIconsRT[transportationIcons[feature.get('type')]];
			} else {
				src = positionIcons[transportationIcons[feature.get('type')]];
			}
			let text = '';
			const delay = feature.get('delay');
			if (delay && delay !== '') {
				if (delay <= 0) {
					text = '';
				} else {
					text = `+${delay}`;
				}
			}
			let zIndex = 20;
			if (feature.get('hovered')) {
				zIndex = 100;
			}
			return new Style({
				zIndex,
				image: new Icon({
					src,
					size: [42, 60],
					zIndex,
				}),
				text: new Text({
					text,
					font: `400 14px ${fonts.default}`,
					fill: new Fill({
						color: colors.orange,
					}),
					padding: [7, 7, 8, 7],
					backgroundFill: new Fill({
						color: colors.white,
					}),
					offsetX: 32,
					offsetY: -15,
					backgroundStroke: new Stroke({
						color: colors.orange,
						width: 1,
					}),
				}),
			});
		});
		if (positionSet === false && position !== false && homeMarker !== null) {
			this.setState({ homeMarker: null });
			this.centerMarker(homeMarker, map);
		}
		this.setState({ map, positionSet: true });
		this.updateView(map);

		map.once('rendercomplete', () => {
			document
				.querySelectorAll('.ol-zoom button, .ol-attribution button')
				.forEach((button, index) => button.setAttribute('data-tabindex', 100 + index));

			tabManager.reinitialize();
		});
	};

	updateUri = (map, filters, marker) => {
		const now = Date.now();
		clearTimeout(this.historyTimeout);
		if (this.lastHistoryUpdate + 300 > now) {
			this.historyTimeout = setTimeout(() => {
				this.updateUri(map, filters, marker);
			}, 300);
			return;
		}

		this.historyUpdateCount = (this.historyUpdateCount || 0) + 1;
		this.lastHistoryUpdate = now;
		let newUri = '';
		if (filters.filterType !== undefined) {
			newUri += encodeURIComponent(filters.filterType);

			if (marker !== null && marker.id) {
				newUri += `/${encodeURIComponent(marker.id)}`;
			}
		}
		const [lon, lat] = toLonLat(map.getView().getCenter());
		newUri += `@${lat.toFixed(5)},${lon.toFixed(5)},z${map.getView().getZoom()}`;

		window.history.replaceState({}, '', newUri);
		tabManager.reinitialize();
	};

	onFilterUpdate = (typeFilter = null) => {
		if (this.localStorageSupport) {
			window.localStorage.setItem('mapFilters', JSON.stringify(this.state.sidebarFilters));
		}
		this.updateView(this.state.map, typeFilter);
	};

	openOffCanvas = () => {
		this.menu.current.classList.add('opened');
		window.addEventListener('keyup', this.offCanvasKeyHandler);
		tabManager.reinitialize(this.menu.current);
	};

	offCanvasKeyHandler = e => {
		if (e.key === 'Escape') {
			this.closeOffCanvas();
		}
	};

	closeOffCanvas = () => {
		this.menu.current.classList.remove('opened');
		window.removeEventListener('keyup', this.offCanvasKeyHandler);
		tabManager.reinitialize(document);
	};

	openInfoOverlay = () => {
		this.infooverlay.current.classList.add('opened');
	};

	closeInfoOverlay = () => {
		this.infooverlay.current.classList.remove('opened');
	};

	centerMarker = (marker, usedMap) => {
		const { dotMarker } = this.state;
		let map = usedMap;
		if (map === null || map === undefined) {
			map = this.state.map;
		}
		if (map === null) return;
		// const [markerLayer] = map.getLayersByName('markers');
		let coords;
		if (['stop', 'suburb', 'singlehouse', 'poi', 'street'].indexOf(marker.type) > -1) {
			coords = getCoordOfPoint(marker);
		} else if (marker.type === 'tip') {
			coords = getCoordOfPoint(marker.address);
		}
		if (this.pulsatingMarker) {
			this.pulsatingMarker = [];
		}
		if (dotMarker) {
			this.setState({ dotMarker: [] });
		}
		if (coords) {
			const position = fromLonLat([coords[1], coords[0]]);
			let newZoom = 15;
			if (['stop', 'poi'].indexOf(marker.type) > -1) {
				newZoom = Math.max(
					mapConfig.minZoomLevel[`${marker.type}s`] + mapConfig.minZoom,
					15,
				);
			}
			if (map.getView().getZoom() < newZoom) {
				map.getView().setCenter(position, newZoom);
			} else {
				map.getView().setCenter(position);
			}
			if (['suburb', 'singlehouse', 'street'].indexOf(marker.type) > -1) {
				updateDotMarker(map, marker, markers => {
					this.setState({ dotMarker: markers });
				});
			}
		}
	};

	/**
	 * Zooms to the minimum required zoom level to display the provided category.
	 * By default, only zooms in but not out, if user is already zoomed in sufficiently.
	 *
	 * Set option force to true to enforce zooming to the exact zoom level and override any user zoom selection.
	 * Set option center to center the map if zooming is applied.
	 *
	 *
	 * @param {object} category
	 * @param {object} options
	 * @returns
	 */
	setCategoryMinZoom = (category, options = { force: false, center: null }) => {
		const { map, dotMarker } = this.state;
		const { setLines } = this.context;
		if (map === null) return;

		if (this.pulsatingMarker) {
			this.pulsatingMarker = [];
		}

		if (dotMarker) {
			this.setState({ dotMarker: [] });
		}

		if (category === null) {
			// reset lines when no category is selected
			setLines(null);
			return;
		}

		if (options.force) {
			map.getView().setZoom(mapConfig.minZoomLevel[category.filter.filterType]);
		} else {
			const newZoom = mapConfig.minZoomLevel[category.filter.filterType] + mapConfig.minZoom;

			if (map.getView().getZoom() < newZoom) {
				map.getView().setZoom(newZoom);
			}
		}
		if (options.center) {
			const newCenter = fromLonLat(options.center);
			this.state.map.getView().setCenter(newCenter);
		}
	};

	closeMenu = () => {
		const { setFilters } = this.context;

		setFilters(null, () => {
			this.onFilterUpdate(null);
			tabManager.reinitialize();
		});

		if (typeof _paq !== 'undefined') {
			// eslint-disable-next-line no-underscore-dangle
			window._paq.push([
				'trackEvent',
				'LivKarte', // Category
				'alle', // Action
				'Kategorie', // Name
			]);
		}
	};

	lastHistoryUpdate = 0;

	/**
	 * Contains for each list type a handler, that expects updates to filters from the <List /> component.
	 */
	listFilterUpdateHandlers = {
		stops: newFilters => {
			this.setState(
				prevState => ({
					sidebarFilters: {
						...prevState.sidebarFilters,
						stops: newFilters,
					},
				}),
				() => {
					this.onFilterUpdate('stops');
				},
			);
		},
		cityticket: cityFilters => {
			this.setState(
				prevState => ({
					sidebarFilters: {
						...prevState.sidebarFilters,
						cityticket: cityFilters,
					},
				}),
				() => {
					this.onFilterUpdate('cityticket');
				},
			);
		},
		parkAndRide: parkAndRideFilters => {
			this.setState(
				prevState => ({
					sidebarFilters: {
						...prevState.sidebarFilters,
						parkAndRide: parkAndRideFilters,
					},
				}),
				() => {
					this.onFilterUpdate('parkAndRide');
				},
			);
		},
		sharing: sharingFilters => {
			this.setState(
				prevState => ({
					sidebarFilters: {
						...prevState.sidebarFilters,
						sharing: sharingFilters,
					},
				}),
				() => {
					this.onFilterUpdate('sharing');
				},
			);
		},
		pois: poiFilters => {
			this.setState(
				prevState => ({
					sidebarFilters: {
						...prevState.sidebarFilters,
						pois: poiFilters,
					},
				}),
				() => {
					this.onFilterUpdate('pois');
				},
			);
		},
		tipps: tippFilters => {
			this.setState(
				prevState => ({
					sidebarFilters: {
						...prevState.sidebarFilters,
						tipps: tippFilters,
					},
				}),
				() => {
					this.onFilterUpdate('tipps');
				},
			);
		},
		disruptions: disruptionsFilters => {
			this.setState(
				prevState => ({
					sidebarFilters: {
						...prevState.sidebarFilters,
						disruptions: disruptionsFilters,
					},
				}),
				() => {
					this.onFilterUpdate('disruptions');
				},
			);
		},
		reset: () => {
			// reset all filters
			this.setState({ sidebarFilters: getInitialSidebarFilters() }, () => {
				// update local storage and view
				this.onFilterUpdate();
			});
		},
	};

	addDisruptionSearchMarker = marker => {
		addDisruptionSearchMarker(this.state.map, marker);
	};

	render() {
		const {
			lines,
			marker,
			setMarker,
			setLines,
			setFilters,
			filters,
			stopMarkers,
			disruptionsMarkers,
			sharingMarkers,
			vehicleMarkers,
			poiMarkers,
			tipMarkers,
			popup,
			parkAndRideMarkers,
		} = this.context;
		const { sharingCatMenuItems, dotMarker } = this.state;

		const isOnDisruptionRoute =
			disruptionsMarkers !== null && filters && typeof filters.disruptions !== 'undefined';

		let searchValue = '';
		if (filters !== null) {
			// For disruptions, build custom label
			if (filters.filterType === 'disruptions') {
				// First, check if all disruption types are active
				const allDisruptionFiltersActive = Object.values(filters.disruptions).every(
					value => !!value,
				);
				// If all disruption types are active, only show "Alle"
				// Otherwise, show all active disruption types
				const activeDisruptionTypes = allDisruptionFiltersActive
					? ['Alle']
					: Object.entries(filters.disruptions)
							.filter(([, value]) => value === 2)
							.map(([key]) => key);

				// If types where found, join them with a comma
				// Otherwise, show "keine ausgewählt"
				const typeString =
					activeDisruptionTypes.length > 0
						? activeDisruptionTypes.join(', ')
						: 'keine ausgewählt';

				searchValue = `Meldungen (${typeString})`;
			} else {
				// For everything that is not a disruption, read label for current filter type from config
				searchValue = searchCategories[filters.filterType].label;
			}
		}

		return (
			<MapWrapper>
				<MapComponent
					updateMap={map => this.updateMap(map)}
					onPositionChanged={map => this.updateView(map)}
					onFeatureClicked={(map, isSelectedMarker, clickedLines) => {
						this.updateFeatureClicked(map, isSelectedMarker, clickedLines, setFilters);
					}}
					transportations={[...vehicleMarkers]}
					markers={[
						...sharingMarkers,
						...parkAndRideMarkers,
						...stopMarkers,
						...disruptionsMarkers,
						...poiMarkers,
						...tipMarkers,
						...dotMarker,
					]}
					clusterMarkers={
						// Cluster markers only if in disruptions view
						filters &&
						(filters.filterType === 'disruptions' ||
							filters.filterType === 'disruptionsAll')
					}
				/>

				{!isOnDisruptionRoute && (
					<Search
						autoFocus={this.state.useAutofocus && this.state.filterType === false}
						onCategorySelect={newCategory => {
							this.setCategoryMinZoom(newCategory);
							this.updateView(this.state.map);
						}}
						onSelect={this.centerMarker}
						value={searchValue}
						categoryMenuVisible={
							(sharingMarkers !== null &&
								filters &&
								typeof filters.sharing !== 'undefined') ||
							(poiMarkers !== null &&
								filters &&
								typeof filters.pois !== 'undefined' &&
								true)
						}
					/>
				)}

				{stopMarkers !== null && filters && typeof filters.stops !== 'undefined' && (
					<>
						<StopList
							selectedMarker={marker}
							limit={0}
							items={stopMarkers}
							setMarker={newMarker => {
								setMarker(newMarker);
							}}
							setLines={newLine => {
								setLines(newLine);
							}}
						/>
					</>
				)}

				{sharingMarkers !== null && filters && typeof filters.sharing !== 'undefined' && (
					<>
						<CategoryMenu
							type="sharing"
							activeMenuItem={searchValue || 'Sharing-Angebote'}
							dockedAtSearchbar
							setFilters={newFilters =>
								setFilters(newFilters, () => {
									this.updateView(this.state.map);
								})
							}
							items={sharingCatMenuItems}
							data-tabindex={10}
						/>
					</>
				)}

				{poiMarkers !== null && filters && typeof filters.pois !== 'undefined' && (
					<>
						<CategoryMenu
							type="places"
							activeMenuItem={searchValue || 'Interessante Orte'}
							dockedAtSearchbar
							data-tabindex={10}
							setFilters={newFilters =>
								setFilters(newFilters, () => {
									this.updateView(this.state.map);
								})
							}
							items={[
								{
									id: 1,
									icon: 'alle',
									title: 'Alle',
									titlefilter: 'Interessante Orte',
									filter: searchCategories.pois.filter,
									trackData: ['LivKarte', 'Alle', 'InteressanteOrteUntertkat'],
								},
								{
									id: 2,
									icon: 'freizeit',
									title: 'Freizeit',
									titlefilter: 'Freizeit',
									filter: searchCategories['pois-freizeit'].filter,
									trackData: [
										'LivKarte',
										'freizeit',
										'InteressanteOrteUntertkat',
									],
								},
								{
									id: 3,
									icon: 'essentrinken',
									title: 'Essen und Trinken',
									titlefilter: 'Essen & Trinken',
									filter: searchCategories['pois-essentrinken'].filter,
									trackData: [
										'LivKarte',
										'essenundtrinken',
										'InteressanteOrteUntertkat',
									],
								},
								{
									id: 4,
									icon: 'kultur',
									title: 'Kultur',
									titlefilter: 'Kultur',
									filter: searchCategories['pois-kultur'].filter,
									trackData: ['LivKarte', 'kultur', 'InteressanteOrteUntertkat'],
								},
								{
									id: 5,
									icon: 'sehenswuerdigkeiten',
									title: 'Sehens- wertes',
									titlefilter: 'Sehenswürdigkeiten',
									filter: searchCategories['pois-sehenswuerdigkeiten'].filter,
									trackData: [
										'LivKarte',
										'sehenswert',
										'InteressanteOrteUntertkat',
									],
								},
								{
									id: 6,
									icon: 'mehr',
									title: 'Mehr',
									titlefilter: 'Mehr',
									isShowMore: true,
									trackData: ['LivKarte', 'mehr', 'InteressanteOrteUntertkat'],
								},
								{
									id: 7,
									icon: 'rundumsrad',
									title: 'Rund ums Rad',
									titlefilter: 'Rund ums Rad',
									filter: searchCategories['pois-rundumsrad'].filter,
									trackData: [
										'LivKarte',
										'rundumsrad',
										'InteressanteOrteUntertkat',
									],
								},
								{
									id: 8,
									icon: 'bildungseinrichtungen',
									title: 'Bildung',
									titlefilter: 'Bildungseinrichtungen',
									filter: searchCategories['pois-bildungseinrichtungen'].filter,
									trackData: ['LivKarte', 'bildung', 'InteressanteOrteUntertkat'],
								},
								{
									id: 9,
									icon: 'krankenhaeuserkliniken',
									title: 'Kliniken',
									titlefilter: 'Krankenhäuser & Kliniken',
									filter: searchCategories['pois-krankenhaeuserkliniken'].filter,
									trackData: [
										'LivKarte',
										'kliniken',
										'InteressanteOrteUntertkat',
									],
								},
								{
									id: 10,
									icon: 'verbaendevereine',
									title: 'Vereine',
									titlefilter: 'Verbände & Vereine',
									filter: searchCategories['pois-verbaendevereine'].filter,
									trackData: ['LivKarte', 'vereine', 'InteressanteOrteUntertkat'],
								},
							]}
						/>
					</>
				)}

				{tipMarkers !== null && filters && typeof filters.tipps !== 'undefined' && (
					<TippsList
						selectedMarker={marker}
						limit={20}
						items={tipMarkers}
						setMarker={newMarker => {
							setMarker(newMarker);
						}}
						setLines={newLine => {
							setLines(newLine);
						}}
						isButtonVisible
					/>
				)}

				{isOnDisruptionRoute && (
					<DisruptionMenu
						updateMap={this.updateMap}
						updateView={this.updateView}
						addMarker={this.addDisruptionSearchMarker}
					/>
				)}

				{/* {marker === null && lines !== null && (
					<LineDisruptionList
						lineIds={lines}
						onClose={() => setLines(null)}
						hasCategoryMenuOnTop={filters && typeof filters.disruptions !== 'undefined'}
					/>
				)} */}

				{!filters && (
					<OffCanvasMenuOpener
						data-tabindex={20}
						onClick={this.openOffCanvas}
						onKeyUp={onlyOnEnter(this.openOffCanvas)}
					>
						<Tooltip type="textonly" anchor="right" title="Sichtbare Elemente" />
					</OffCanvasMenuOpener>
				)}
				<OffCanvasMenu ref={this.menu} onClose={this.closeOffCanvas} data-tabindex={20}>
					<Subheadline>Öffentlicher Nahverkehr</Subheadline>
					{Object.values(this.state.sidebarFilters.disruptions).some(
						value => value === 2,
					) && (
						<InfoMessage>
							Meldungen und öffentlicher Nahverkehr können nicht gleichzeitig
							angezeigt werden.
						</InfoMessage>
					)}
					<List
						keyPrefix="list-stops"
						items={this.state.sidebarFilters.stops}
						onUpdate={this.listFilterUpdateHandlers.stops}
						disabled={Object.values(this.state.sidebarFilters.disruptions).some(
							value => value === 2,
						)}
						data-tabindex={30}
					/>
					{/* TODO: Enable Meldungen again, when we solved the problems like clustering only working for Meldungen */}
					{/* <Subheadline>Meldungen</Subheadline>
					{Object.values(this.state.sidebarFilters.stops).some(value => value === 2) && (
						<InfoMessage>
							Meldungen und öffentlicher Nahverkehr können nicht gleichzeitig
							angezeigt werden.
						</InfoMessage>
					)}
					<List
						keyPrefix="list-disruptions"
						items={this.state.sidebarFilters.disruptions}
						onUpdate={this.listFilterUpdateHandlers.disruptions}
						disabled={Object.values(this.state.sidebarFilters.stops).some(
							value => value === 2,
						)}
					/> */}
					<Subheadline>StadtTicket</Subheadline>
					<List
						keyPrefix="list-city"
						items={this.state.sidebarFilters.cityticket}
						onUpdate={this.listFilterUpdateHandlers.cityticket}
						data-tabindex={40}
					/>

					<Subheadline>Park &amp; Ride</Subheadline>
					<List
						keyPrefix="list-p-r"
						items={this.state.sidebarFilters.parkAndRide}
						onUpdate={this.listFilterUpdateHandlers.cityticket}
						data-tabindex={50}
					/>
					<Subheadline>Sharing-Angebote</Subheadline>
					<List
						keyPrefix="list-sharing"
						items={this.state.sidebarFilters.sharing || {}}
						titleMappings={sharingTitleMappings}
						onUpdate={this.listFilterUpdateHandlers.sharing}
						data-tabindex={60}
					/>
					<Subheadline>Interessante Orte</Subheadline>
					<List
						keyPrefix="list-pois"
						items={this.state.sidebarFilters.pois}
						onUpdate={this.listFilterUpdateHandlers.pois}
						data-tabindex={70}
					/>
					<Subheadline>Ausflugstipps</Subheadline>
					<List
						keyPrefix="list-ausflugstipps"
						items={this.state.sidebarFilters.tipps}
						onUpdate={this.listFilterUpdateHandlers.tipps}
						data-tabindex={80}
					/>
					<Button
						disabled={
							// You can only reset if there are filters active
							JSON.stringify(this.state.sidebarFilters) ===
							JSON.stringify(getInitialSidebarFilters())
						}
						color="blue"
						stretch
						onClick={this.listFilterUpdateHandlers.reset}
						data-tabindex={90}
					>
						Alle Filter zurücksetzen
					</Button>
				</OffCanvasMenu>

				{!filters && (
					<Menu isVisible={!filters}>
						<MenuTile
							title="Öffentlicher Nahverkehr / P+R"
							type="oepnv"
							data-tabindex={11}
							onClick={() => {
								setFilters(searchCategories.stops.filter, () => {
									this.updateView(this.state.map);
								});
								this.setCategoryMinZoom(searchCategories.stops);

								if (typeof _paq !== 'undefined') {
									// eslint-disable-next-line no-underscore-dangle
									window._paq.push([
										'trackEvent',
										'LivKarte', // Category
										'nahverkehr', // Action
										'Kategorie', // Name
									]);
								}
							}}
							wideTitle
						/>
						<MenuTile
							title="Meldungen"
							type="disruptions"
							isNew={true}
							data-tabindex={12}
							onClick={() => {
								setFilters(searchCategories.disruptions.filter, () => {
									this.updateView(this.state.map);
								});
								// Zoom out when switching into disruptions view
								this.setCategoryMinZoom(searchCategories.disruptions, {
									force: true,
									center: [mapConfig.center[1], mapConfig.center[0]], // 48.7835562, 9.1814453
								});
							}}
						/>
						<MenuTile
							title="Sharing-Angebote"
							type="sharing"
							data-tabindex={13}
							onClick={() => {
								setFilters(searchCategories.sharing.filter, () => {
									this.updateView(this.state.map);
								});
								this.setCategoryMinZoom(searchCategories.sharing);

								if (typeof _paq !== 'undefined') {
									// eslint-disable-next-line no-underscore-dangle
									window._paq.push([
										'trackEvent',
										'LivKarte', // Category
										'sharingangebote', // Action
										'Kategorie', // Name
									]);
								}
							}}
						/>
						<MenuTile
							title="Interessante Orte"
							type="places"
							data-tabindex={14}
							onClick={() => {
								setFilters(searchCategories.pois.filter, () => {
									this.updateView(this.state.map);
								});
								this.setCategoryMinZoom(searchCategories.pois);

								if (typeof _paq !== 'undefined') {
									// eslint-disable-next-line no-underscore-dangle
									window._paq.push([
										'trackEvent',
										'LivKarte', // Category
										'interessanteorte', // Action
										'Kategorie', // Name
									]);
								}
							}}
						/>
						<MenuTile
							title="Ausflugstipps"
							type="tips"
							data-tabindex={15}
							onClick={() => {
								setFilters(searchCategories.tipps.filter, () => {
									this.updateView(this.state.map);
								});
								this.setCategoryMinZoom(searchCategories.tipps);

								if (typeof _paq !== 'undefined') {
									// eslint-disable-next-line no-underscore-dangle
									window._paq.push([
										'trackEvent',
										'LivKarte', // Category
										'ausflugstipps', // Action
										'Kategorie', // Name
									]);
								}
							}}
						/>
						<MenuTile
							title="StadtTickets"
							type="cityticket"
							data-tabindex={16}
							onClick={() => {
								setFilters(searchCategories.cityticket.filter, () => {
									this.updateView(this.state.map);
								});
								this.setCategoryMinZoom(searchCategories.cityticket, {
									force: true,
									center: [9.30883881153767, 48.788811136774584], // 48.788811136774584, 9.30883881153767 center is offset because of zoom out
								});
							}}
						/>
					</Menu>
				)}

				{filters && (
					<MenuOpener
						data-tabindex={17}
						onKeyUp={onlyOnEnter(this.closeMenu)}
						onClick={this.closeMenu}
					/>
				)}

				{popup && <Popup text={popup.text} options={popup.options} />}

				<InfoOverlay ref={this.infooverlay} onClose={this.closeInfoOverlay} />
				{/* Only display overlay in disruptions mode */}
				{disruptionsMarkers !== null &&
					filters &&
					typeof filters.disruptions !== 'undefined' && (
						<DisruptionsIntroductionOverlay
							open={this.state.disruptionsInfoOverlayOpen}
							onClose={() => this.setState({ disruptionsInfoOverlayOpen: false })}
							onOpen={() => this.setState({ disruptionsInfoOverlayOpen: true })}
						/>
					)}
				<FooterButton onClick={this.openInfoOverlay} />
			</MapWrapper>
		);
	}
}

Map.contextType = MarkerContext;

// Map.propTypes = {};

// Map.defaultProps = {};

export default Map;
