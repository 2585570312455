import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import Content from './Content';
import CloseButton from './CloseButton';

const modalRoot = document.getElementById('root');

export default function Modal({ labelledBy, describedBy, onClose, children, width }) {
	const [modalWrapper, setModalWrapper] = useState();
	const closeButton = useRef();

	useEffect(() => {
		const wrapper = document.createElement('div');
		modalRoot.appendChild(wrapper);

		const preventFocusOutside = e => {
			const code = e.keyCode || e.which;
			if (code === 27) {
				onClose(e);
				return;
			}
			if (code !== 9) return;

			let { target } = e;
			while (target !== null) {
				if (target === wrapper) {
					return;
				}
				target = target.parentNode;
			}
			if (closeButton.current) {
				closeButton.current.focus();
			}
		};

		window.addEventListener('keyup', preventFocusOutside, false);

		setModalWrapper(wrapper);

		return () => {
			document.removeEventListener('keyup', preventFocusOutside, false);
			modalRoot.removeChild(wrapper);
		};
	}, [onClose]);

	if (!modalWrapper) {
		return false;
	}

	const style = {};
	if (typeof width !== 'undefined') {
		style.width = `${width}px`;
	}

	return ReactDOM.createPortal(
		<Wrapper
			role="dialog"
			aria-labelledby={labelledBy}
			aria-describedby={describedBy}
			onClick={e => {
				if (e.target === e.currentTarget) {
					onClose(e);
				}
			}}
		>
			<Content style={style}>
				<CloseButton ref={closeButton} onClose={onClose} />
				{children}
			</Content>
		</Wrapper>,
		modalWrapper,
	);
}

Modal.propTypes = {
	labelledBy: PropTypes.string,
	describedBy: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	width: PropTypes.number,
};
