import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ResultList } from './ResultList';
import { ResultHeader } from './ResultHeader';

const StyledCollapsable = styled.div`
	transition: all 250ms ease-in-out;
	overflow: hidden;
`;

const StyledResult = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: min-content 1fr;
	padding: var(--size_x075, 12px);
	height: 100%;

	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
`;

export function Result({
	items,
	selectedSortOption,
	sortOptions,
	onSortChange,
	onResetClick,
	onSelectItem,
}) {
	const ref = useRef();
	const [styles, setStyles] = useState(null);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const handleToggle = useCallback(() => setIsCollapsed(v => !v), []);

	useEffect(() => {
		if (ref.current && !isCollapsed) {
			const { top } = ref.current.getBoundingClientRect();
			setStyles({ maxHeight: window.innerHeight - top - 100 });
		} else {
			setStyles({ maxHeight: 0, opacity: 0 });
		}
	}, [isCollapsed]);

	return (
		<StyledResult>
			<ResultHeader count={items.length} isCollapsed={isCollapsed} onToggle={handleToggle} />
			<StyledCollapsable ref={ref} style={styles}>
				<ResultList
					items={items}
					selectedSortOption={selectedSortOption}
					sortOptions={sortOptions}
					onSortChange={onSortChange}
					onSelectItem={onSelectItem}
					onResetClick={onResetClick}
				/>
			</StyledCollapsable>
		</StyledResult>
	);
}

Result.propTypes = {
	items: ResultList.propTypes.items,
	onSelectItem: ResultList.propTypes.onSelectItem,
	onResetClick: ResultList.propTypes.onResetClick,
	onSortChange: ResultList.propTypes.onSortChange,
	selectedSortOption: ResultList.propTypes.selectedSortOption,
	sortOptions: ResultList.propTypes.sortOptions,
};
