import React from 'react';
// GlobalMapStyles added because styling is moved there in shared
import { GlobalStyle, GlobalMapStyles } from 'shared';

import Map from './components/Map';

import { Provider as MarkerContextProvider } from './contexts/MarkerContext';

function App() {
	return (
		<MarkerContextProvider>
			<div className="App">
				<GlobalStyle />
				<GlobalMapStyles />
				<Map />
			</div>
		</MarkerContextProvider>
	);
}

export default App;
