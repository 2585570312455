/* 3rd party imports */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* Custom imports */
import Button from '../Button';
import vvsLogo from '../assets/icons/vvs_logo.svg';
import arrowForwardRoundedIcon from '../assets/icons/arrow_forward_rounded.svg';
import helpIcon from '../assets/icons/help.svg';
import closeIcon from '../assets/icons/close.svg';

/* Styles */

/** Modal Backdrop */
const ModalBackground = styled.div`
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;

	// Fade in animation
	& {
		animation: fadeIn 0.2s ease-in-out;
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

/** The button that opens the modal, is position absolute in the top right corner */
const OpenModalButton = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	border: none;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));

	&:hover {
		cursor: pointer;
		opacity: 0.8;
		transition: opacity 0.2s ease-in-out;
	}
`;

/** Icon for the button that opens the modal */
const OpenModalButtonIcon = styled.img.attrs({
	src: helpIcon,
	alt: 'Hilfe Icon',
})`
	display: block;
	width: auto;
	height: 24px;
`;

/** The modal itself */
const Modal = styled.div`
	position: relative;
	z-index: 1001;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	padding: 40px 80px;
	border-radius: 20px;
	background: var(--Grey-Shade-1, #fafafa);
	box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
`;

/** The headline of the modal */
const Headline = styled.h3`
	display: flex;
	align-items: center;
	color: var(--TrueBlack, #000);
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 39px;
`;

/** The VVS logo in the headline */
const HeadlineImg = styled.img`
	display: block;
	width: auto;
	height: 96px;
	margin-right: 1rem;
`;

/** The body of the modal */
const ModalBody = styled.div`
	margin-bottom: 32px;

	& > p {
		color: var(--TrueBlack, #000);
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px; /* 166.667% */
	}
`;

/** The Icon for the modal action button */
const ModalActionIcon = styled.img.attrs({
	src: arrowForwardRoundedIcon,
	alt: 'Pfeil nach rechts Icon',
})`
	display: block;
	width: auto;
	height: 24px;
`;

/** The button on the top right that closes the model */
const ModalCloseButton = styled.button`
	position: absolute;
	top: 16px;
	right: 16px;
	border: none;
	background: transparent;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
		transition: opacity 0.2s ease-in-out;
	}
`;

/** The x Icon for the modal close button */
const ModalCloseIcon = styled.img.attrs({
	src: closeIcon,
	alt: 'Schließen Icon',
})`
	display: block;
	width: auto;
	height: 32px;
`;

/**
 * This is the introduction modal for the disruptions overlay.
 * It is specifically designed for this purpose and thus not very reusable.
 */
// TODO: Move the modal itself into a separate component as soon as it is needed elsewhere
export default function DisruptionsIntroductionOverlay({ open, onClose, onOpen }) {
	return (
		<>
			<OpenModalButton onClick={() => onOpen()}>
				<OpenModalButtonIcon />
			</OpenModalButton>
			{open && (
				<ModalBackground>
					<Modal>
						<Headline id="disruptions-introduction-title">
							<HeadlineImg src={vvsLogo} alt="VVS Icon" />
							Meldungen
						</Headline>
						<ModalBody id="disruptions-introduction-content">
							<p>
								Diese Kartenansicht zeigt aktuelle und zukünftige
								Fahrplanabweichungen im VVS-Gebiet. Betriebsstörungen,
								Aufzugsstörungen oder Hinweise zu Baustellen und sonstigen
								Änderungen werden an den betroffenen Stellen auf der Karte markiert.
								Über die Auswahl einer Linie, eines Orts, des Meldungs- oder
								Verkehrsmitteltyps werden die auf der Karte angezeigten Ereignisse
								gefiltert. Durch Auswahl eines Datums in der Zukunft werden die
								Meldungen angezeigt, die an diesem Tag gültig sind. Weitere
								Informationen zu Meldungen im VVS sind auf der VVS-Webseite zu
								finden.
							</p>
						</ModalBody>
						<Button variant="primary" onClick={() => onClose()}>
							Meldungen ansehen
							<ModalActionIcon />
						</Button>
						<ModalCloseButton onClick={() => onClose()}>
							<ModalCloseIcon />
						</ModalCloseButton>
					</Modal>
				</ModalBackground>
			)}
		</>
	);
}

DisruptionsIntroductionOverlay.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onOpen: PropTypes.func.isRequired,
};
