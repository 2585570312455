/**
 * Classifies disruptions. Enum values are human readable and can be displayed / are to the user.
 * NOTE: Determining the type of a disruption (Linienstörung, Aufzugstörung, Konzert, ...) is a complex task
 * so we move it into this util.
 */
const disruptionsClassifier = {
	CLASSES: {
		Störung: 'Störungen',
		BaustellenUndUmleitungen: 'Baustellen und Umleitungen',
		Aufzüge: 'Aufzüge',
		// This class describes elements that should never be displayed
		Hidden: 'Hidden',
	},
	// NOTE: These type definitions were provided by the VVS
	isStörung: (disruption) =>
		(disruption?.type === 'lineInfo' &&
			disruption.properties?.genericParams?.disruption === 'lines') ||
		(disruption?.type === 'routeInfo' &&
			disruption.properties?.genericParams?.disruption === 'lines'),
	isBaustellenUndUmleitungen: (disruption) =>
		(disruption?.type === 'lineInfo' &&
			disruption.properties?.genericParams?.timetableChange === 'lines') ||
		(disruption?.type === 'routeInfo' &&
			disruption.properties?.genericParams?.timetableChange === 'lines'),
	isAufzüge: (disruption) =>
		disruption?.type === 'stopInfo' &&
		disruption.properties?.genericParams?.stopInfo === 'escalator_elevator' &&
		disruption.properties?.genericParams?.elevator === 'true',
	/** Is true if the disruption is explicitly hidden, we consider Hidden its own class */
	isOnHideList: (disruption) =>
		['stopBlocking', 'lineBlocking', 'tripMessage'].includes(disruption?.type) ||
		(disruption?.type === 'stopInfo' &&
			disruption.properties?.genericParams?.lineInfo === 'specialTraffic'),
	/**
	 * @returns {disruptionsClassifier.CLASSES | null} Returns type or null
	 */
	classify: (disruption) => {
		if (disruptionsClassifier.isAufzüge(disruption)) {
			return disruptionsClassifier.CLASSES.Aufzüge;
		}
		if (disruptionsClassifier.isBaustellenUndUmleitungen(disruption)) {
			return disruptionsClassifier.CLASSES.BaustellenUndUmleitungen;
		}
		if (disruptionsClassifier.isStörung(disruption)) {
			return disruptionsClassifier.CLASSES.Störung;
		}
		if (disruptionsClassifier.isOnHideList(disruption)) {
			return disruptionsClassifier.CLASSES.Hidden;
		}
		return null;
	},
	/**
	 * Checks if provided object is any kind of disruption
	 *
	 *	@param {object} disruption
	 * 	@param {boolean} includeHidden - If true, Hidden disruptions are considered a disruption
	 */
	isAnyDisruption: (disruption, includeHidden = true) => {
		if (includeHidden) {
			return disruptionsClassifier.classify(disruption) !== null;
		}
		return (
			disruptionsClassifier.classify(disruption) !== null &&
			disruptionsClassifier.classify(disruption) !== disruptionsClassifier.CLASSES.Hidden
		);
	},
};

export default disruptionsClassifier;
