import Feature from 'ol/src/Feature';
import Point from 'ol/src/geom/Point';
import { fromLonLat } from 'ol/src/proj';

import getCoordOfPoint from './getCoordOfPoint';

export default function createMarker(point, options = {}) {
	const coord = getCoordOfPoint(point);
	if (!Array.isArray(coord) || coord.length !== 2) {
		console.error('point has no coordinates', point);
		return null;
	}

	const marker = new Feature({
		...options,
		geometry: new Point(fromLonLat([coord[1], coord[0]])),
	});
	marker.coord = fromLonLat([coord[1], coord[0]]);
	return marker;
}
