import styled, { keyframes } from 'styled-components';
import media from '../../utils/Breakpoints';
import { colors, fonts, boxshadow } from '../../utils/config';

export const SearchBar = styled.div`
	position: fixed;
	top: 8px;
	left: 8px;
	height: 48px;
	width: 96%;
	width: calc(100% - 16px);
	margin: 0 auto;
	display: flex;
	align-items: center;
	background-color: ${colors.white};
	border: 1px solid ${colors.graylight};
	box-shadow: ${boxshadow.default};
	border-radius: 5px;
	padding: 0;
	font-weight: normal;
	transition: box-shadow 0.2s ease;
	z-index: 30;

	${media.iphone`
		max-width: 358px;
	`}

	${media.lg`
		// position: relative;
		top: 16px;
		left: 16px;
		margin: 0 auto 0 0;
		width: 460px;
		max-width: none;
		height: 68px;
	`}

	${media.xl`
		top: 32px;
		left: 32px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(.752);
		transform-origin: top left;
	}

	${props =>
		props.positionrelative &&
		`
		position: relative;
		top: 8px;
		left: 0;
	`}

	${props =>
		props.searchResultListVisible &&
		`
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	`}

	${props =>
		props.categoryMenuVisible &&
		`
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	`}

	&:hover & {
		box-shadow: ${boxshadow.hover}
	}

	.active & {
		box-shadow: ${boxshadow.focus}
	}
`;

export const SearchBarIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 46px;
	height: 40px;
	background-color: #fff;

	&:hover,
	&:focus,
	&:active {
		cursor: pointer;
		outline: none;

		&::before {
			border-radius: 50%;
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 40px;
			height: 40px;
			background-color: ${colors.graylight};
		}
	}

	${media.lg`
		width: 80px;
		height: 60px;
	`}

	svg {
		max-height: 16px;
		width: auto;
		z-index: 1;

		${media.lg`
			max-height: none;
		`}
	}
`;

export const Input = styled.input`
	${'' /* width: 300px; */}
	flex-grow: 1;
	border: 0;
	padding: 0;
	font-size: 15px;
	line-height: 40px;
	height: 40px;
	padding-right: 8px;
	color: ${colors.black};
	font-family: ${fonts.bold};
	font-weight: normal;

	&::-webkit-input-placeholder {
		color: ${colors.graydark};
		font-weight: normal;
	}
	&::-moz-placeholder {
		color: ${colors.graydark};
		font-weight: normal;
	}
	&:-ms-input-placeholder {
		color: ${colors.graydark};
		font-weight: normal;
	}

	&::-ms-clear {
		display: none;
	}

	${media.lg`
		font-size: 18px;
		line-height: 60px;
		height: 60px;
		padding-left: 8px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(1.0248);
		transform-origin: top left;
	}
`;

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const SearchLoading = styled.div`
	position: absolute;
	right: 50px;
	top: 9px;
	background-color: #fff;
	border-radius: 15px;
	border: 3px solid ${colors.graylight};
	border-top-color: ${colors.orange};
	width: 30px;
	height: 30px;
	animation: ${rotate} 1s linear infinite;

	${media.lg`
		right: 80px;
		top: 18px;
	`}
`;
