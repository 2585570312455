import { fetchers, createMarker } from 'shared';

import { toLonLat } from 'ol/src/proj';
import { getHeight, getWidth } from 'ol/src/extent';

import { mapConfig } from '../../../utils/config';
import renderTooltip from './Tooltips/renderTooltip';
import renderTooltipSelected from './Tooltips/renderTooltipSelected';

const addStopMarker = (
	stop,
	filteredMeans,
	map,
	state = 2,
	setClickedMarker,
	stopMarker,
	isCityTicketMode,
	setClickedMarkerCityMode,
) => {
	const title = `${stop.desc}, ${stop.locality}`;
	// check if normal id or global id
	let isSelected = stopMarker?.id === stop.id || stopMarker?.id === stop.attrs?.STOP_GLOBAL_ID;
	// search function sets id to different value
	if (!isSelected && stopMarker?.properties?.stopId) {
		isSelected =
			stopMarker?.properties.stopId === stop.id ||
			stopMarker?.properties.stopId === stop.attrs?.STOP_GLOBAL_ID;
	}
	if (isSelected) {
		// eslint-disable-next-line no-param-reassign
		state = 3;
	}

	const marker = createMarker(stop, {
		type: 'stop',
		vvsId: stop.attrs?.STOP_GLOBAL_ID || stop.id,
		title,
		locationtype: 'stop',
		state,
		locality: stop.locality,
		canHover: true,
		canClick: true,
		onHover: () => {
			renderTooltip('stop', marker, map, stop, 'tooltip', state, [0, 0], null, null);
		},
		onClick: () => {
			if (isCityTicketMode) {
				renderTooltipSelected(marker, map, [0, 0], setClickedMarkerCityMode);
			} else {
				renderTooltipSelected(marker, map, [0, 0], setClickedMarker);
			}
		},
	});

	marker.vvsType = 'stop';
	marker.type = 'stop';
	marker.name = title;
	marker.vvsMeans = stop.attrs.STOP_MEANS_LIST;
	marker.vvsId = stop.attrs?.STOP_GLOBAL_ID || stop.id;
	marker.id = stop.attrs?.STOP_GLOBAL_ID || stop.id;
	marker.title = title;
	marker.desc = stop.desc;
	marker.locality = stop.locality;
	marker.vvsUpdate = false;
	marker.gisId = stop.gisID; // needed for disruptions

	if (isSelected) {
		// render selected tooltip if it isn't already rendered
		// set state = 2 because of state = 3 check
		renderTooltip('stop', marker, map, stop, 'tooltip-selected', 2, [0, 0], null, null);
		// if stopMarker is not set to actual marker set it once
		if (!stopMarker.vvsType) {
			if (isCityTicketMode) {
				setClickedMarkerCityMode(marker);
			} else {
				setClickedMarker(marker);
			}
		}
	}

	return marker;
};

let stopsFilter;
let lastStopMarker;
let fetchingParams = {
	lon: -1,
	lat: -1,
	radius: -1,
};
let lastCityTicketFilter = null;
let lastFilters = null;

export default async (
	map,
	filter = null,
	setStopMarkers = () => { },
	stopMarker,
	setClickedMarker = () => { },
	cityTicketFilter,
	currentFilters,
	setClickedMarkerCityMode = () => { },
) => {
	let usedFilter = filter;
	let isCityTicketMode = false;
	// stops can appear in city ticket filter check
	if (
		currentFilters?.filterType &&
		currentFilters?.filterType === 'cityticket' &&
		cityTicketFilter === 2
	) {
		usedFilter = cityTicketFilter;
		isCityTicketMode = true;
	} else {
		usedFilter = filter;
	}
	const filteredMeans = ['1', '2', '3', '6'];

	const extent = map.getView().calculateExtent();
	const [lon, lat] = toLonLat(map.getView().getCenter());
	const width = getWidth(extent);
	const height = getHeight(extent);
	const diameter = Math.max(width, height);
	const radius = Math.ceil(diameter / 2);
	const newFetchingParams = {
		lon,
		lat,
		radius,
	};

	// additional check for filter type as stops can appear in cityticket filter
	let isFilterTypeSame = false;
	if (currentFilters === null && lastFilters === null) {
		isFilterTypeSame = true;
	} else if (
		currentFilters &&
		lastFilters &&
		currentFilters.filterType === lastFilters.filterType
	) {
		isFilterTypeSame = true;
	}

	// check if any params or filters or markers were changed
	// if not don't fetch again and don't set the updated flag
	if (
		stopMarker === lastStopMarker &&
		newFetchingParams.lon === fetchingParams.lon &&
		newFetchingParams.lat === fetchingParams.lat &&
		newFetchingParams.radius === fetchingParams.radius &&
		filter === stopsFilter &&
		cityTicketFilter === lastCityTicketFilter &&
		isFilterTypeSame
	) {
		return;
	}
	lastCityTicketFilter = cityTicketFilter;
	lastFilters = currentFilters;
	stopsFilter = filter;
	fetchingParams = newFetchingParams;
	lastStopMarker = stopMarker;
	// remove markers if zoomed out
	if (map.getView().getZoom() < mapConfig.minZoomLevel.stops + mapConfig.minZoom) {
		const markersArray = [];
		// check if lastStopMarker is already generated marker
		// keep selected marker even if zoomed out
		if (lastStopMarker && lastStopMarker.vvsId) {
			markersArray.push(lastStopMarker);
			setStopMarkers(markersArray);
			return;
		}
		setStopMarkers(markersArray);
		return;
	}
	const stops = await fetchers.fetchCoords(lat, lon, radius);
	stops.pins = stops.pins || [];

	// Stop processing, if filter changed
	if (usedFilter === 0) {
		setStopMarkers([]);
		return;
	}
	const stopMarkers = [];
	stops.pins.forEach(stop => {
		const state = 2;
		stopMarkers.push(
			addStopMarker(
				stop,
				filteredMeans,
				map,
				state,
				setClickedMarker,
				stopMarker,
				isCityTicketMode,
				setClickedMarkerCityMode,
			),
		);
	});
	// as only fetches with changes reach here, set update flag for MarkerContext.js
	if (stopMarkers.length > 0) stopMarkers[0].vvsUpdate = true;

	// check again after load if stop markers are zoomed out and don't set
	if (map.getView().getZoom() < mapConfig.minZoomLevel.stops + mapConfig.minZoom) {
		return;
	}
	setStopMarkers(stopMarkers);
};
