import React, { useEffect, useRef } from 'react';
import Overlay from 'ol/Overlay';
import Cluster from 'ol/src/source/Cluster';
import { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';

// todo use markers from markerStyles
import disruptionsSrc from '../../assets/marker/disruptions.svg';
import disruptionsInfoSrc from '../../assets/marker/disruptions-info.svg';
import disruptionsElevatorSrc from '../../assets/marker/disruptions-elevator.svg';

import overlayTriangle from './ClusterOverlayIcons/triangle.svg';
import overlayTriangleRed from './ClusterOverlayIcons/triangle-red.svg';
import sbahnIcon from './ClusterOverlayIcons/sbahn.svg'; // 'sbahn'
import rbahnIcon from './ClusterOverlayIcons/rbahn.svg'; // 'rbahn'
import ubahnIcon from './ClusterOverlayIcons/ubahn.svg'; // 'ubahn'
import zackeIcon from './ClusterOverlayIcons/zacke.svg'; // 'zahnradbahn'
import seilbahnIcon from './ClusterOverlayIcons/seilbahn.svg'; // 'seilbahn'
import busIcon from './ClusterOverlayIcons/bus.svg'; // 'bus'
import taxiIcon from './ClusterOverlayIcons/taxi.svg'; // 'ruftaxi'

const OverlayTypes = {
	disruptions: {
		src: disruptionsSrc,
		label: {
			singular: 'Störung',
			plural: 'Störungen',
		},
		classList: 'disruptions',
	},
	disruptionsInfo: {
		src: disruptionsInfoSrc,
		label: {
			singular: 'Baustelle',
			plural: 'Baustellen',
		},
		classList: 'infos',
	},
	disruptionsElevator: {
		src: disruptionsElevatorSrc,
		label: {
			singular: 'Aufzug',
			plural: 'Aufzüge',
		},
		classList: 'elevators',
	},
};

const ProductIdIconMap = {
	0: sbahnIcon, // 'sbahn'
	1: rbahnIcon, // 'rbahn'
	2: ubahnIcon, // 'ubahn'
	3: zackeIcon, // 'zahnradbahn'
	4: seilbahnIcon, // 'seilbahn'
	5: busIcon, // 'bus'
	6: busIcon, // 'nachtbus'
	8: taxiIcon, // 'ruftaxi'
	16: rbahnIcon,
};

const ClusterOverlayStyles = createGlobalStyle`
	.ol-overlay-container {
		* {
			box-sizing: border-box;
		}
		// Put the overlay on top of everything when it is not collapsed
		// (collapsed class will be added programmatically by our JS)
		// This is necessary, because only the overlay is in the correct
		// place in the hierarchy for the z-index to have an effect
		// and we cannot use :has to check if children are collapsed,
		// because it is not supported by enough browser yet.
		&:not(.collapse),
		&:hover,
		.show {
			z-index: 9999;
		}
	}


	.cluster-overlay-wrapper {
		--open-width: 200px;
		--collapsed-width: 150px;

		width: var(--open-width);
		background: #fafafa;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.24);
		border-radius: 4px;
		z-index: 10;
		position: relative;

		transition: width 250ms ease-in-out;

		&.collapse {
			width: var(--collapsed-width);

			header, .item.infos {
				overflow: hidden;
				opacity: 0;
				padding-top: 0px;
				padding-bottom: 0px;
				height: 0px;
				transform: translateY(50%);
			}

			&:not(.preview) .cluster-overlay-triangle {
				display: none;
			}

			&.disruptions:not(.elevator) .cluster-overlay-triangle {
				background-image: url(${overlayTriangleRed});
			}
		}

		> div {
			overflow: hidden;
		}

		.cluster-overlay-triangle {
			width: 36px;
			height: 20px;
			background-image: url(${overlayTriangle});
			background-position: center;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -18px;
			margin: auto;
		}

		header, .item {
			transition: all 250ms ease-in-out;
		}

		header {
			padding: 0.5rem;
			padding-right: 0.75rem;
			display: grid;
			grid-template-columns: 3rem 1fr;
			align-items: center;
			overflow: hidden;
			gap: 0.5rem;

			max-width: var(--open-width);
			min-width: var(--open-width);
			z-index: 1;

			img {
				width: 3rem;
				height: 3rem;
			}

			h3 {
				word-break: break-word;
				span{
					display: block;
					font-size: inherit;
					font-weight: inherit;
				}
			}

			/* tooltip headers without icons */
			&.lite {
				grid-template-columns: 1fr;
			}
		}

		section {
			display: flex;
			flex-direction: column;
			border-radius: 4px;
			overflow: hidden;
			z-index: 2;
			background: inherit;

			.item {
				display: grid;
				grid-template-columns: 1.5rem 1fr;
				align-items: center;
				gap: 0.5rem;
				padding: 0.4rem;

				&.disruptions {
					background-color: #B03636;
					color: #fff;
				}

				img {
					width: 1.5rem;
					height: 1.5rem
				}

				p {
					font-size: 1rem;
					word-wrap: break-word;
				}
			}
		}

	}

	.hidden {
		display: initial;
		z-index: 1;
		.cluster-overlay-wrapper {
			header {
				display: none;
			}

			.item:not(.disruptions) {
				display: none;
			}
		}

		&.elevators .cluster-overlay-wrapper .item.disruptionsElevator {
			display: grid;
		}
	}
`;

function createOverlayItem(type, count) {
	if (!count) return '';

	const data = OverlayTypes[type];

	// TODO this is a possible vulnerability for html injection
	// because of the old node version this can't be fixed
	return `<div class="item ${data.classList}">
		<img src="${data.src}" />
		<p>${count} ${count > 1 ? data.label.plural : data.label.singular}</p>
	</div>`;
}

function getHeadline(values) {
	const value = values[0];
	if (!value) return null;
	const productId = value?.affectedLines?.[0]?.product?.id;
	let isSameProduct = true;
	let headlines = '';
	if (values.length >= 13) {
		return {
			headline: 'Mehrere Linien betroffen',
			icon: disruptionsInfoSrc,
		};
	}
	values.forEach((val) => {
		// TODO this is a possible vulnerability for html injection
		// because of the old node version this can't be fixed
		headlines += `<span>${val?.subject}</span>`;
		if (isSameProduct) {
			// nightbus and bus share the same product icon
			const isSameBus =
				(val?.affectedLines?.[0]?.product?.id === 5 ||
					val?.affectedLines?.[0]?.product?.id === 6) &&
				(productId === 5 || productId === 6);
			isSameProduct = productId === val?.affectedLines?.[0]?.product?.id || isSameBus;
		}
	});

	// if the headline is too long, we want to show a generic message
	if (headlines.length > 215) {
		return {
			headline: 'Mehrere Linien betroffen',
			icon: disruptionsInfoSrc,
		};
	}

	return {
		headline: headlines,
		...(isSameProduct && productId && { icon: productId && ProductIdIconMap[productId] }),
		...(!isSameProduct && { icon: disruptionsInfoSrc }),
	};
}

function createOverlayHeader(values) {
	const header = getHeadline(values);

	if (!header) return '';

	if (!header.icon) {
		// TODO this is a possible vulnerability for html injection
		// because of the old node version this can't be fixed
		return `<header class="lite">
	<h3>
		${header.headline}
	</h3>
</header>`;
	}

	return `<header>
	<img src="${header.icon}" />
	<h3>
		${header.headline}
	</h3>
</header > `;
}

function createOverlay(cluster) {
	const id = cluster.ol_uid;

	const coord = cluster.getGeometry().flatCoordinates;
	const features = cluster.get('features').map(({ values_ }) => values_);
	const { disruptions, disruptionsInfo, disruptionsElevator } = features.reduce(
		(red, feat) => ({
			...red,
			[feat.type]: [...red[feat.type], feat],
		}),
		{ disruptions: [], disruptionsInfo: [], disruptionsElevator: [] },
	);

	const div = document.createElement('div');
	const classNames = [
		'cluster-overlay-wrapper',
		'collapse',

		disruptions.length > 0 && 'disruptions',
		disruptionsInfo.length > 0 && 'info',
		disruptionsElevator.length > 0 && 'elevator',
	].filter(Boolean);
	div.classList.add(...classNames);
	// TODO this is a possible vulnerability for html injection
	// because of the old node version this can't be fixed
	div.innerHTML = `<div>
		${createOverlayHeader([...disruptions, ...disruptionsInfo, ...disruptionsElevator])}
		<section>
			${createOverlayItem('disruptions', disruptions?.length)}
			${createOverlayItem('disruptionsInfo', disruptionsInfo?.length)}
			${createOverlayItem('disruptionsElevator', disruptionsElevator?.length)}
		</section>
	<div>
<div class="cluster-overlay-triangle"></div>`;

	div.dataset.id = id;
	if (disruptions?.length > 0 || disruptionsElevator?.length > 0) div.classList.add('preview');

	return new Overlay({
		id,
		element: div,
		offset: [0, -40],
		position: coord,
		positioning: 'bottom-center',
	});
}

function findClusterLayer(layer) {
	return layer.get('name') === 'markers' && layer.getSource() instanceof Cluster;
}

function removeAllOverlays(overlays, map) {
	if (Array.isArray(overlays)) {
		overlays.forEach((overlay) => map.removeOverlay(overlay));
	}
}

export function ClusterOverlay({ map }) {
	const overlayRefs = useRef(null);
	const hoveredRef = useRef(null);

	useEffect(() => {
		if (!map) return () => {};
		let postRenderTimeout = null;

		function handlePostRender() {
			if (postRenderTimeout) clearTimeout(postRenderTimeout);

			postRenderTimeout = setTimeout(() => {
				// create new ones
				const features = map
					.getLayers()
					.getArray()
					.find(findClusterLayer)
					?.getSource()
					?.getFeatures();

				if (features && features?.length !== overlayRefs.current?.length) {
					const nextOverlays = features.map((feat) => {
						const overlay = createOverlay(feat, map);
						map.addOverlay(overlay);
						// To adjust z-index, add collapse class to parent.
						// See overlay container note above to why this is necessary
						// NOTE: We cannot get a ref on this element, so we use this
						// weird way of accessing it
						overlay.getElement().parentNode.classList.add('collapse');
						return overlay;
					});

					// remove all old overlays
					removeAllOverlays(overlayRefs.current, map);

					overlayRefs.current = nextOverlays;
				}

				// leaving the disruptions route
				if (!features) removeAllOverlays(overlayRefs.current, map);
			}, 100);
		}

		map.on('postrender', handlePostRender);

		function handlePointerMove(e) {
			const prevOverlay = hoveredRef.current;
			let nextOverlay = null;
			hoveredRef.current = null;

			map.forEachFeatureAtPixel(e.pixel, (feature) => {
				const overlay = overlayRefs.current?.find((ol) => ol.id === feature.ol_uid);

				if (!overlay) return false;
				nextOverlay = overlay;

				return true;
			});

			if (prevOverlay !== nextOverlay) {
				// reset prev overlay element (if there is one)
				if (prevOverlay) {
					prevOverlay.getElement().classList.add('collapse');
					// To adjust z-index add collapse class also to parent.
					// See overlay container note above to why this is necessary
					// NOTE: We cannot get a ref on this element, so we use this
					// weird way of accessing it
					prevOverlay.getElement().parentNode.classList.add('collapse');
				}

				if (nextOverlay) {
					nextOverlay.getElement().classList.remove('collapse');
					// To adjust z-index remove collapse class also from parent.
					// See overlay container note above to why this is necessary					// NOTE: We cannot get a ref on this element, so we use this
					// weird way of accessing it
					nextOverlay.getElement().parentNode.classList.remove('collapse');
					hoveredRef.current = nextOverlay;
				}
			} else {
				hoveredRef.current = prevOverlay;
			}
		}

		map.on('pointermove', handlePointerMove, { layerFilter: findClusterLayer });

		return () => {
			clearTimeout(postRenderTimeout);
			map.un('postrender', handlePostRender);
			map.un('pointermove', handlePointerMove);
			removeAllOverlays(overlayRefs.current, map);
		};
	}, [map]);

	return <ClusterOverlayStyles />;
}

ClusterOverlay.propTypes = {
	map: PropTypes.shape({
		removeOverlay: PropTypes.func,
		addOverlay: PropTypes.func,
		getLayers: PropTypes.func,
		forEachFeatureAtPixel: PropTypes.func,
		on: PropTypes.func,
		once: PropTypes.func,
		un: PropTypes.func,
	}),
};
