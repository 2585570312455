import React from 'react';
import PropTypes from 'prop-types';
import CategoryList from '.';

export default function StopList({
	selectedMarker,
	items,
	limit,
	setMarker,
	setLines,
	marker,
	isButtonVisible,
	hasCategoryMenuOnTop,
}) {
	let index = false;

	const adjustedItems = items.map((item, i) => {
		let { id } = item;
		if (typeof item.attrs !== 'undefined' && typeof item.attrs.STOP_GLOBAL_ID !== 'undefined') {
			id = item.attrs.STOP_GLOBAL_ID;
		}
		if (selectedMarker !== null && selectedMarker.type === 'stop' && selectedMarker.id === id) {
			index = i;
		}

		return {
			id,
			gisId: item.gisId, // needed for disruptions
			title: item.desc,
			subtitle: item.locality,
			icon: 'stop',
			type: 'stop',
		};
	});

	return (
		<CategoryList
			selectedIndex={index}
			listtype="stops"
			items={adjustedItems}
			limit={limit}
			setMarker={setMarker}
			setLines={setLines}
			marker={selectedMarker}
			isButtonVisible={isButtonVisible}
			hasCategoryMenuOnTop={hasCategoryMenuOnTop}
		/>
	);
}

StopList.propTypes = {
	selectedMarker: PropTypes.object,
	items: PropTypes.array,
	limit: PropTypes.number,
	setMarker: PropTypes.func.isRequired,
	setLines: PropTypes.func.isRequired,
	marker: PropTypes.object,
	isButtonVisible: PropTypes.bool,
	hasCategoryMenuOnTop: PropTypes.bool,
};

StopList.defaultProps = {
	limit: 5,
	isButtonVisible: false,
	hasCategoryMenuOnTop: false,
};
