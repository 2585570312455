import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { fonts, colors } from '../../utils/config';

import Icon from './icon';
import { onlyOnEnter } from '../../utils/events';

const StyledMenuItem = styled.div`
	position: relative;
	height: 100%;
	width: 56px;
	${media.lg`
		width: 76px;
	`}
	text-align: center;
	user-select: none;

	&:focus,
	&:focus-within {
		background-color: #e6e6e6;

		svg path {
			fill: #fff;
		}

		[data-icon='alle'] div,
		[data-icon='mehr'] div,
		[data-icon='heute'] div,
		[data-icon='datum'] div {
			background-color: ${colors.cyandark};
		}
		[data-icon='disruption'] div,
		[data-icon='timetableChange'] div,
		[data-icon='elevator'] div {
			background-color: ${colors.disruptionBlue};
		}
		[data-icon='regiorad'] div {
			background-color: #06507f;
		}
		[data-icon='stella'] div {
			background-color: #18ace9;
		}
		[data-icon='stadtmobil'] div,
		[data-icon='flinkster'] div {
			background-color: #e2000f;
		}
		[data-icon='car2go'] div,
		[data-icon='sharenow'] div {
			background-color: #1498e3;
		}
		[data-icon='rbahn'] svg path {
			fill: ${colors.grayblue};
		}
		[data-icon='sbahn'] svg path {
			fill: #52b331;
		}
		[data-icon='ubahn'] svg path {
			fill: #0aa1e2;
		}
	}
`;

const Title = styled.h2`
	height: ${props => (props.type === 'sharing' ? 'auto' : '24px')};
	margin-top: 4px;
	font-size: 12px;
	line-height: 1;
	font-family: ${fonts.default};
	font-weight: normal;
	color: ${colors.black};

	/* for long texts */
	max-width: 3.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const IconContainer = styled.div`
	height: 100%;
	width: 100%;
	flex: 0 1 16.66666%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${props =>
		!props.clickable &&
		'pointer-events: none;'} // Allow user to click through item on date picker, see Date Picker below for more information
	position: relative;


	&:hover {
		cursor: pointer;
	}

	&:hover {
		svg path {
			fill: #fff;
		}
	}

	${media.lg`
		height: 96px;
		width: 76px;
	`}

	&[data-icon='alle'] {
		div {
			background-color: ${props => (props.active ? `${colors.cyandark}` : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: ${colors.cyandark};
		}
	}

	&[data-icon='mehr'],
	&[data-icon='heute'],
	&[data-icon='datum'] {
		div {
			background-color: ${props => (props.active ? `${colors.cyandark}` : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: ${colors.cyandark};
		}
	}

	&[data-icon='disruption']	{
		div {
			background-color: ${props => (props.active ? `${colors.disruptionRed}` : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: ${colors.disruptionRed};
		}
	}

	&[data-icon='timetableChange'],
	&[data-icon='elevator']		{
		div {
			background-color: ${props => (props.active ? `${colors.disruptionBlue}` : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: ${colors.disruptionBlue};
		}
	}

	&[data-icon='regiorad'] {
		div {
			background-color: ${props => (props.active ? '#06507F' : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: #06507f;
		}
	}

	&[data-icon='stella'] {
		div {
			background-color: ${props => (props.active ? '#18ACE9' : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: #18ace9;
		}
	}

	&[data-icon='stadtmobil'] {
		div {
			background-color: ${props => (props.active ? '#E2000F' : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: #e2000f;
		}
	}

	&[data-icon='car2go'],
	&[data-icon='sharenow'] {
		div {
			background-color: ${props => (props.active ? '#1498E3' : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: #1498e3;
		}
	}

	&[data-icon='flinkster'] {
		div {
			background-color: ${props => (props.active ? '#E2000F' : `${colors.graylight}`)};
		}

		&:hover div {
			background-color: #e2000f;
		}
	}

	&[data-icon='rbahn']:hover svg path {
		fill: ${colors.grayblue};
	}
	${props =>
		props.active
			? `
			&[data-icon='rbahn'] svg path {
				fill: ${colors.grayblue};
			}`
			: ''}

	&[data-icon='sbahn']:hover svg path {
		fill: #52b331;
	}
	${props =>
		props.active
			? `
			&[data-icon='sbahn'] svg path {
				fill: #52b331;
			}`
			: ''}

	&[data-icon='ubahn']:hover svg path {
		fill: #0aa1e2;
	}
	${props =>
		props.active
			? `
			&[data-icon='ubahn'] svg path {
				fill: #0aa1e2;
			}`
			: ''}

	&[data-icon='bus']:hover svg path {
		fill: #c31924;
	}
	${props =>
		props.active
			? `
			&[data-icon='bus'] svg path {
				fill: #c31924;
			}`
			: ''}

	${props =>
		props.type === 'places' &&
		`
		&:hover div {
			background-color: ${colors.orange};
		}
	`}

	${props =>
		props.type === 'places' &&
		props.active &&
		`
		div {
			background-color: ${colors.orange};
		}
	`}
`;

/** Renders a datepicker. To make it invisible, move the icon that opens the date picker to cover 100% of the surface. */
const DatePicker = styled.input.attrs({
	type: 'date',
})`
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	font-size: 72px; // height of the menu
	width: 100%;
	padding: 0;
	cursor: pointer;
	opacity: 0;

	&::-webkit-calendar-picker-indicator {
		cursor: pointer;
	}
`;

export default function MenuItem({
	title,
	titlefilter,
	icon,
	type,
	/** Tells us, if the menu item is logically currently active */
	active,
	/**
	 * Tells us, if the menu item is visually currently active.
	 * This is helpful, when we want to the button to be active logically,
	 * but not visually (in disruptions filters for example).
	 */
	visuallyActive = active,
	onClick,
	buttonType,
	onChange,
	'data-tabindex': dataTabindex,
}) {
	const [pickedDate, setPickedDate] = useState(undefined);
	const isDatePicker = buttonType === 'datePicker';

	const handlePickDate = useCallback(
		event => {
			setPickedDate(event.target.value);
			if (onChange) {
				onChange(event);
			}
		},
		[setPickedDate],
	);

	/** Is "Heute" if the date is today, or otherwise a nicely formatted version of the selected date */
	const dateLabel = useMemo(() => {
		if (!pickedDate) {
			return 'Heute'; // Assumed default behavior is that no selection means today
		}
		const TODAY = new Date();
		if (
			new Date(pickedDate).toLocaleDateString('de-DE') === TODAY.toLocaleDateString('de-DE')
		) {
			return 'Heute';
		}
		return new Date(pickedDate).toLocaleDateString('de-DE', {
			dateStyle: 'short',
		});
	}, [pickedDate]);

	const handleFocus = useCallback(
		e => {
			if (!isDatePicker) return;
			const datePicker = e.target.querySelector('input[type="date"]');
			if (!datePicker) return;
			datePicker.showPicker();
			datePicker.focus();
		},
		[isDatePicker],
	);

	return (
		<StyledMenuItem
			data-tabindex={dataTabindex}
			onKeyDown={onlyOnEnter(onClick)}
			onFocus={handleFocus}
		>
			{/* To render the date picker, we render a native html5 date picker behind the Icon allow the user to click through the icon to hit the date picker. This allows for a custom look while also not having to import a custom date picker that needs to be maintained. */}
			{isDatePicker && (
				<DatePicker
					onChange={handlePickDate}
					onClick={onClick}
					onKeyDown={onlyOnEnter(onClick)}
				/>
			)}
			<IconContainer
				active={visuallyActive}
				type={type}
				data-icon={icon}
				clickable={!isDatePicker}
				onClick={onClick}
				titlefilter={titlefilter}
			>
				<Icon icon={icon} type={type} active={visuallyActive} />
				<Title type={type} title={title}>
					{isDatePicker ? dateLabel : title}
				</Title>
			</IconContainer>
		</StyledMenuItem>
	);
}

MenuItem.propTypes = {
	icon: PropTypes.oneOf([
		'alle',
		'freizeit',
		'essentrinken',
		'kultur',
		'sehenswuerdigkeiten',
		'rundumsrad',
		'bildungseinrichtungen',
		'krankenhaeuserkliniken',
		'verbaendevereine',
		'mehr',
		'stella',
		'car2go',
		'sharenow',
		'flinkster',
		'regiorad',
		'stadtmobil',
		'rbahn',
		'sbahn',
		'ubahn',
		'bus',
		'heute',
		'datum',
		'disruption',
		'timetableChange',
		'elevator',
	]).isRequired,
	title: PropTypes.string.isRequired,
	titlefilter: PropTypes.string,
	type: PropTypes.string,
	active: PropTypes.bool,
	visuallyActive: PropTypes.bool,
	onClick: PropTypes.func,
	onChange: PropTypes.func,
	buttonType: undefined || 'datePicker',
	'data-tabindex': PropTypes.number,
};
