import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledLabel } from './styled';
import { TEXT } from '../text';

const StyledDateFilter = styled.div`
	display: grid;
	grid-template-areas:
		'label'
		'select';
	gap: var(--size_x05, 8px);
`;

const StyledInput = styled.input`
	cursor: pointer;
	border: var(--border, 1px solid #d9d9d9);
	border-radius: var(--size_x025, 4px);
	height: 40px;
	display: flex;
	flex-direction: row-reverse;
	gap: var(--size_x05, 8px);
	padding: 0 var(--size_x05, 8px);

	color: var(--TrueBlack, #000);
	background-color: var(--GreyShade1, #fafafa);

	&::-webkit-calendar-picker-indicator {
		cursor: pointer;
		width: var(--size_x15, 24px);
		height: var(--size_x15, 24px);
	}
`;

export function DatePicker({ value, onChange }) {
	return (
		<StyledDateFilter>
			<StyledLabel>{TEXT.DATE_PICKER.LABEL}</StyledLabel>
			<StyledInput type="date" value={value} onChange={e => onChange(e.target.value)} />
		</StyledDateFilter>
	);
}

DatePicker.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Assuming value is a string, adjust as needed
	onChange: PropTypes.func.isRequired,
};
