import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ResultSettings } from './ResultSettings';
import { ResultDetail } from './ResultDetail';

const StyledResultListWrapper = styled.div`
	display: grid;
	height: 100%;
`;

const StyledResultList = styled.ul`
	padding: 0;
	margin: 0;
	display: grid;
	gap: var(--size_x1, 16px);

	overflow-y: scroll;
	scrollbar-width: thin;
	border-radius: var(--size_x025, 4px);

	::-webkit-scrollbar {
		width: 9px;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: rgba(155, 155, 155, 0.5);
		border-radius: 20px;
		border: transparent;
	}
`;

const StyledResultListItem = styled.li`
	margin: 0;
	padding: var(--size_x15, 24px);
	display: grid;
	grid-template-areas:
		'icon title'
		'text text';
	grid-template-columns: var(--size_x2, 32px) 1fr;
	gap: var(--size_x15, 24px);
	align-items: center;
	cursor: pointer;
	border-radius: var(--size_x025, 4px);
	background-color: var(--GreyShade2, #ececec);

	&.no-icon {
		grid-template-areas:
			'title title'
			'text text';
	}

	img {
		grid-area: icon;
		width: var(--size_x2, 32px);
		height: var(--size_x2, 32px);
	}
	h3 {
		grid-area: title;
	}
	.subtitle {
		grid-area: text;
	}
	.text-content {
		grid-area: text;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline;
		max-height: 38px;
		div {
			display: inline;
		}
	}
`;

export function ResultList({
	items,
	selectedSortOption,
	sortOptions,
	onSortChange,
	onResetClick,
	onSelectItem,
}) {
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);

	const handleNavigate = useCallback(
		direction => {
			setSelectedIndex(v => {
				const nextIndex = v + direction;

				if (nextIndex > items.length - 1) return 0;
				if (nextIndex < 0) return items.length - 1;
				return nextIndex;
			});
		},
		[items.length],
	);

	useEffect(() => {
		if (selectedIndex > items.length - 1) {
			setSelectedIndex(null);
			setSelectedItem(null);
		}
		onSelectItem(items[selectedIndex]);
		setSelectedItem(items[selectedIndex]);
	}, [onSelectItem, selectedIndex, items]);

	return (
		<StyledResultListWrapper>
			{selectedIndex !== null ? (
				<ResultDetail
					item={selectedItem}
					onBack={() => {
						setSelectedIndex(null);
						setSelectedItem(null);
					}}
					onNavigate={handleNavigate}
					itemsLength={items.length}
				/>
			) : (
				<>
					<ResultSettings
						selectedSortOption={selectedSortOption}
						sortOptions={sortOptions}
						onSortChange={onSortChange}
						onResetClick={onResetClick}
					/>
					<StyledResultList>
						{items.map(({ id, title, subtitle, text, icon }, index) => (
							<StyledResultListItem
								className={icon ? '' : 'no-icon'}
								key={id}
								onClick={() => {
									setSelectedItem(items[index]);
									setSelectedIndex(index);
								}}
							>
								{icon && <img src={icon} alt={title} />}
								<h3>{title}</h3>
								{subtitle && <strong className="subtitle">{subtitle}</strong>}
								{!subtitle && (
									<div
										className="text-content"
										dangerouslySetInnerHTML={{ __html: text }}
									/>
								)}
							</StyledResultListItem>
						))}
					</StyledResultList>
				</>
			)}
		</StyledResultListWrapper>
	);
}

ResultList.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			subtitle: PropTypes.string,
			text: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
		}),
	).isRequired,
	selectedSortOption: ResultSettings.propTypes.selectedSortOption,
	sortOptions: ResultSettings.propTypes.sortOptions,
	onSortChange: ResultSettings.propTypes.onSortChange,
	onResetClick: ResultSettings.propTypes.onResetClick,
	onSelectItem: PropTypes.func.isRequired,
};
