import { searchCategories } from '../../../utils/config';

export default (marker, markerContext) => {
	try {
		const selectedMarker = marker;

		// For disruptions, we offer a date based filter.
		// In this case, switching to the marker specific filters (like we do for Sharing)
		// in the category menu does not make sense here.
		// This is why we need to check, if a disruptions based filter is already present
		// and only switch to the marker specific context if that is not the case.
		const isAlreadyInDisruptions =
			markerContext.filters?.disruptions &&
			Object.values(markerContext.filters.disruptions).some(el => el > 0);
		const isAlreadyInDisruptionsAll =
			markerContext.filters?.disruptionsAll &&
			Object.values(markerContext.filters.disruptionsAll).some(el => el > 0);

		// TODO: Update if more disruption category menu categories are added

		if (!isAlreadyInDisruptions && !isAlreadyInDisruptionsAll) {
			// Not in any disruptions view yet, go into default disruptions filtered view
			const filters = searchCategories.disruptions.filter;
			markerContext.setContext({
				marker: selectedMarker,
				filters,
			});
		} else {
			// Is already in some kind of disruptions view,
			markerContext.setContext({
				marker: selectedMarker,
			});
		}

		document.querySelector('#searchinput').blur();
	} catch (e) {
		// Do nothing on errors
	}
};
