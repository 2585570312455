
import { fetchers, createMarker } from 'shared';
import { getBottomLeft, getTopRight } from 'ol/src/extent';
import { toLonLat } from 'ol/src/proj';

import { nameToIcon, mapConfig } from '../../../utils/config';
import renderTooltip from './Tooltips/renderTooltip';
import renderTooltipSelected from './Tooltips/renderTooltipSelected';

let lastBottomLeft = [-1, -1];
let lastTopRight = [-1, -1];
let lastSelectedMarker = null;
let lastState = -1;

export default async (
	map,
	state = 2,
	setTipMarkers = () => {},
	selectedMarker = null,
	setClickedMarker = () => {},
) => {
	let selectedMarkerId = null;
	if (selectedMarker !== null) {
		selectedMarkerId = selectedMarker.id;
	}

	const extent = map.getView().calculateExtent();

	const bottomLeft = toLonLat(getBottomLeft(extent));
	const topRight = toLonLat(getTopRight(extent));

	//check if any params or filters or markers were changed
	//if not don't fetch again and don't set the updated flag
	if (
		selectedMarker === lastSelectedMarker &&
		lastTopRight[0] === topRight[0] &&
		lastBottomLeft[0] === bottomLeft[0] &&
		lastTopRight[1] === topRight[1] &&
		lastBottomLeft[1] === bottomLeft[1] &&
		lastState === state
	) {
		return;
	}
	lastBottomLeft = bottomLeft;
	lastTopRight = topRight;
	lastSelectedMarker = selectedMarker;
	lastState = state;

	//do not fetch new markers if zoom level is out of range
	if (map.getView().getZoom() < mapConfig.minZoomLevel.tipps + mapConfig.minZoom || state === 0) {
		let markersArray = [];
		//check if selected marker isn't null and already generated marker
		//if true keep selected marker on map
		if (lastSelectedMarker && lastSelectedMarker.vvsId) {
			markersArray.push(lastSelectedMarker);
		}
		setTipMarkers(markersArray);
		return;
	}

	const tipps = await fetchers.fetchTippsInArea(
		bottomLeft[1],
		bottomLeft[0],
		topRight[1],
		topRight[0],
	);
	const tipMarkers = [];
	tipps.forEach(tip => {
		const markerId = nameToIcon(`${tip.id || 0}-${tip.title || 'notitle'}`, true);
		let markerState = state;
		if (selectedMarkerId === markerId || lastSelectedMarker?.vvsId === markerId) {
			markerState = 3;
		}
		tip.coords = tip.address.coords;
		const marker = createMarker(tip, {
			type: 'tip',
			vvsId: tip.id,
			state: markerState,
			canHover: true,
			canClick: true,
			onHover: () => {
				renderTooltip('tip', marker, map, tip, 'tooltip', markerState, [0, 0], null, null);
			},
			onClick: () => {
				renderTooltipSelected(marker, map, [0, 0], setClickedMarker);
			},
		});
		if (markerState === 3) {
			renderTooltip('tip', marker, map, tip, 'tooltip-selected', 2, [0, 0], null, null);
		}

		marker.vvsType = 'tip';
		marker.address = tip.address;
		marker.title = tip.title;
		marker.state = markerState;
		marker.id = tip.id;
		marker.vvsid = markerId;
		marker.image = tip.image;
		marker.introtext = tip.introtext;
		marker.link = tip.link;
		marker.longtext = tip.longtext;
		marker.locationtype = 'tip';

		tipMarkers.push(marker);
	});
	if (tipMarkers.length > 0) {
		tipMarkers[0].vvsUpdate = true;
	}
	setTipMarkers(tipMarkers);
};
