import { createMarker } from 'shared';

export default async (map, dotMarker, setDotMarker = () => {}) => {
	let dotMarkers = [];
	const marker = createMarker(dotMarker, {
		type: dotMarker.type,
		vvsId: dotMarker.id,
		state: 3,
		canHover: false,
	});

	marker.vvsType = dotMarker.type;
	marker.id = dotMarker.id;
	marker.vvsid = dotMarker.id;

	dotMarkers.push(marker);
	setDotMarker(dotMarkers);
};
