import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { boxshadow, colors } from '../../utils/config';

import { ReactComponent as LogoEU } from '../../assets/logo_eu.svg';
import { ReactComponent as LogoBW } from '../../assets/logo_bw.svg';

import Text from '../Text';

const InfoOverlayWrapper = styled.div`
	display: none;

	&.opened {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 90%;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		padding: 24px;
		box-shadow: ${boxshadow.tooltip};
		background-color: ${colors.white};
	}

	${media.md`
		padding: 32px;
	`}
`;

const Iconwrapper = styled.div`
	position: absolute;
	top: 16px;
	right: 16px;
	width: 36px;
	height: 36px;

	&:hover {
		cursor: pointer;
	}

	svg {
		margin: 8px 0 0 8px;
	}
`;

const Headline = styled.h2`
	margin-bottom: 24px;
	max-width: 90%;
`;

const LogoWrapper = styled.div`
	svg {
		height: 60px;
		width: auto;
		margin-right: 24px;
	}
`;

const InfoOverlay = React.forwardRef(({ onClose }, ref) => (
	<InfoOverlayWrapper ref={ref}>
		<Iconwrapper
			onClick={e => {
				if (onClose) {
					onClose(e);
				}
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
				<g fill="#097fac" fillRule="evenodd">
					<path d="M2.1214 0l16.9705 16.9706-2.1213 2.1213L.0001 2.1214z" />
					<path d="M16.9706 0L0 16.9707l2.1213 2.1214L19.092 2.1214z" />
				</g>
			</svg>
		</Iconwrapper>
		<Headline>
			Förderung im Europäischen Fonds <br />
			für regionale Entwicklung
		</Headline>
		<Text small marginBottom="large">
			Die Anzeige multimodaler Angebote innerhalb dieser Applikation wurde im Europäischen
			Fonds für regionale Entwicklung durch Mittel der Europäischen Union und des Landes
			Baden-Württemberg gefördert.
		</Text>
		<LogoWrapper>
			<LogoEU />
			<LogoBW />
		</LogoWrapper>
	</InfoOverlayWrapper>
));

InfoOverlay.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default InfoOverlay;
