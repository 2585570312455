import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 16px;
	width: 100%;
`;

export const ItemWrapper = styled.div`
	display: flex;
	overflow: hidden;
`;

export default Wrapper;
