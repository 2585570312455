import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import media from '../../utils/Breakpoints';
import { fonts, colors } from '../../utils/config';

import IconAddress from '../../assets/icons/address.svg';
import IconStation from '../../assets/icons/stop.svg';
import IconStations from '../../assets/icons/stops.svg';
import IconPoi from '../../assets/icons/poi.svg';
import IconAddressTip from '../../assets/icons/address-tip.svg';
//TODO add icon
import IconCityTicket from '../../assets/icons/Stadtticket_Icon_freistehend.svg';
import IconArrowRight from '../../assets/icons/arrow-right.svg';
import IconArrowRightBlue from '../../assets/icons/arrow-right--blue.svg';

const StyledSearchResultListItem = styled.li`
	list-style-type: none;
	width: 100%;
	max-width: 458px;
	min-height: 64px;
	background-color: #fff;
	padding: 14px 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${colors.graylight};

	&.active {
		background-color: ${colors.graylight};
	}

	&:hover,
	&:focus {
		cursor: pointer;
		background-image: url(${IconArrowRightBlue});
	}

	${media.lg`
		min-height: 72px;
		padding: 14px 20px;
	`}

	${({ slimLayout }) =>
		slimLayout
			? css`
					background: url(${IconArrowRight}) right 16px center no-repeat transparent;

					${media.lg`
						background-position: right 20px center;
					`}
			  `
			: css`
					background: url(${IconArrowRight}) right 24px center no-repeat transparent;

					${media.lg`
						background-position: right 32px center;
					`}
			  `}
`;

const TextWrapper = styled.div`
	overflow: hidden;
	width: 70%;

	${({ isRoute }) =>
		!isRoute &&
		css`
			max-height: 54px;
		`}

	${media.lg`
		max-height: 60px;
		width: 75%;
	`}
`;

const Title = styled.h2`
	font-family: ${fonts.default};
	font-weight: normal;
	font-size: 16px;
	line-height: 1.185;
	color: ${colors.black};
	word-break: break-word;

	${media.lg`
		font-size: 18px;
	`}

	strong {
		color: ${colors.black};
	}
`;

const Subtitle = styled.p`
	font-size: 16px;
	line-height: 1.185;
	color: ${colors.graydark};

	${media.lg`
		font-size: 18px;
	`}
`;

const SearchResultListItemIcon = styled.i`
	display: block;
	width: 32px;
	height: 32px;
	font-style: normal;
	margin-right: 16px;
	background-size: 32px;
	background: center center no-repeat transparent;

	background-image: url('${({ icon }) => {
		switch (icon) {
			case 'stop':
				return IconStation;
			case 'stops':
				return IconStations;
			case 'poi':
				return IconPoi;
			case 'tip':
				return IconAddressTip;
			case 'citytickets':
				return IconCityTicket;
			case 'disruptions':
				return IconStation;
			default:
				return IconAddress;
		}
	}}');

	${media.lg`
		margin-right: 22px;
	`}
`;

export default function SearchResultListItem({
	title,
	subtitle,
	icon,
	active,
	type,
	slimLayout,
	onClick,
	onMouseEnter,
}) {
	return (
		<StyledSearchResultListItem
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			className={active ? 'active' : ''}
			slimLayout={slimLayout}
		>
			<SearchResultListItemIcon icon={icon} />
			<TextWrapper isRoute={type === 'routeCache'}>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</TextWrapper>
		</StyledSearchResultListItem>
	);
}

SearchResultListItem.propTypes = {
	title: PropTypes.element.isRequired,
	subtitle: PropTypes.string,
	icon: PropTypes.oneOf([
		'stop',
		'poi',
		'address',
		'street',
		'singlehouse',
		'stops',
		'pois',
		'tipps',
		'bildungseinrichtungen',
		'freizeit',
		'krankenhaeuserkliniken',
		'kultur',
		'sehenswuerdigkeiten',
		'verbaendevereine',
		'essentrinken',
		'rundumsrad',
		'suburb',
		'sharing',
		'nextbike',
		'stella',
		'stadtmobil',
		'car2go',
		'flinkster',
		'tip',
		'routeCache',
		'citytickets',
	]),
	active: PropTypes.bool.isRequired,
	type: PropTypes.string,
	slimLayout: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
};

SearchResultListItem.defaultProps = {
	slimLayout: false,
	onClick: () => {},
	onMouseEnter: () => {},
};
