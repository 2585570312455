export default function renderTooltipSelected(marker, map, offset = [0, 0], setClickedMarker) {
	if (marker.get('state') === 3) {
		return;
	}
	marker.set('state', 3);
	marker.changed();

	const tooltip = document.getElementById('tooltip');
	const tooltipSelected = document.getElementById('tooltip-selected');
	let newChild = tooltip.firstChild;
	if (newChild) {
		tooltipSelected.innerHTML = '';
		tooltipSelected.appendChild(newChild);
		tooltip.innerHTML = '';
	}
	tooltip.style.display = 'none';

	map.getOverlayById('tooltip-selected').setOffset(offset);
	const tooltipPosition = marker.get('tooltipPos')
		? marker.get('tooltipPos')
		: marker.getGeometry().getCoordinates();
	map.getOverlayById('tooltip-selected').setPosition(tooltipPosition);
	tooltipSelected.style.display = '';
	setClickedMarker(marker);
}
