import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';
import { colors, nameToIcon } from '../../utils/config';
import Switch from '../Switch';

const StyledList = styled.ul`
	margin: 0 0 48px 0;
	padding: 0;
	${props =>
		props.disabled &&
		`
		opacity: 0.7;
	`}
`;

const Item = styled.li`
	border-bottom: 1px solid ${colors.graylight};
	list-style-type: none;
	padding: 0 32px;

	${media.lg`
		padding: 0 48px;
	`}
`;

export default class List extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allChecked: Object.keys(this.props.items).every(key => this.props.items[key] === 2),
		};
	}

	// Set all switches to false if disabled
	componentDidUpdate() {
		if (
			this.props.disabled &&
			// Only call setState if there actually is something to update to avoid unnecessary updates.
			Object.values(this.props.items).some(el => el === 2)
		) {
			const resetItems = { ...this.props.items };
			Object.keys(resetItems).forEach(itemName => {
				resetItems[itemName] = 0;
			});
			this.props.onUpdate(resetItems);
		}
	}

	render() {
		const {
			keyPrefix,
			titleMappings,
			disabled = false,
			'data-tabindex': tabindex = undefined,
		} = this.props;
		const { items } = this.props;
		let { allChecked } = this.state;

		return (
			<StyledList disabled={disabled}>
				{Object.keys(items).length > 1 && (
					<Item>
						<Switch
							id={`${keyPrefix}-toggle-all`}
							value="1"
							label="Alle ein/ausblenden"
							defaultChecked={allChecked}
							onChange={checked => {
								if (allChecked !== checked) {
									allChecked = checked;
									Object.keys(items).forEach(item => {
										items[item] = checked * 2;
									});
									this.setState({ allChecked }, () => {
										this.props.onUpdate(items);
									});
								}
							}}
							disabled={disabled}
							data-tabindex={tabindex}
						/>
					</Item>
				)}
				{Object.keys(items).map((item, i) => {
					const label = titleMappings[item] || item;
					const icon = nameToIcon(label);
					return (
						<Item key={`${keyPrefix}-toggle-${icon}`}>
							<Switch
								id={`${keyPrefix}-toggle-${icon}`}
								value="1"
								label={label}
								icon={icon}
								defaultChecked={items[item] === 2}
								onChange={checked => {
									items[item] = checked * 2;
									if (checked === false && allChecked === true) {
										allChecked = false;
									} else if (checked === true && allChecked === false) {
										allChecked = Object.keys(items).every(
											key => items[key] === 2,
										);
									}
									this.setState({ allChecked }, () => {
										this.props.onUpdate(items);
									});
								}}
								disabled={disabled}
								data-tabindex={tabindex + 1 + i}
							/>
						</Item>
					);
				})}
			</StyledList>
		);
	}
}

List.propTypes = {
	keyPrefix: PropTypes.string,
	items: PropTypes.object,
	titleMappings: PropTypes.object,
	onUpdate: PropTypes.func,
	disabled: PropTypes.bool, // If true, disables all the switches
	'data-tabindex': PropTypes.number,
};

List.defaultProps = {
	keyPrefix: 'list',
	titleMappings: {},
	onUpdate: () => {},
};
