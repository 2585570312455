import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button, { ButtonVariants } from '../../Button';
import ArrowBackIosRounded from '../../assets/icons/ArrowBackIosRounded.svg';
import ArrowForwardIosRounded from '../../assets/icons/ArrowForwardIosRounded.svg';
import ArrowBackRounded from '../../assets/icons/ArrowBackRounded.svg';
import { TEXT } from '../text';

const StyledResultDetailWrapper = styled.div`
	display: grid;
	gap: var(--size_x1, 16px);

	overflow: hidden;
`;

const StyledResultListDetail = styled.article`
	margin: 0;
	padding: var(--size_x15, 24px);
	display: grid;
	grid-template-areas:
		'icon title'
		'text text';
	grid-template-columns: var(--size_x2, 32px) 1fr;
	gap: var(--size_x15, 24px);
	align-items: center;

	border-radius: var(--size_x025, 4px);
	background-color: var(--GreyShade2, #ececec);

	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 9px;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: rgba(155, 155, 155, 0.5);
		border-radius: 20px;
		border: transparent;
	}

	img {
		grid-area: icon;
		width: var(--size_x15, 24px);
		height: var(--size_x15, 24px);
	}
	h3 {
		grid-area: title;
	}
	div {
		grid-area: text;
		.subtitle{
			font-weight: bold;
			margin-bottom: var(--size_x05, 8px);
		}
	}
`;

const StyledResultDetailNavigation = styled.nav`
	display: grid;
	grid-template-areas: 'back . prev next';
	grid-template-columns: auto 1fr min-content min-content;
	gap: var(--size_x05, 8px);
	position: sticky;
	top: 0;
	background-color: var(--GreyShade1, #fafafa);

	/* padding-bottom: var(--size_x1, 16px); */

	button {
		border-color: var(--GreyShade2, #ececec);
		background-color: var(--GreyShade2, #ececec);
		&:nth-child(1) {
			grid-area: back;
		}
		&:nth-child(2) {
			grid-area: prev;
		}
		&:nth-child(3) {
			grid-area: next;
		}
	}
`;

export function ResultDetail({ item, onNavigate, onBack, itemsLength }) {
	return (
		<StyledResultDetailWrapper>
			<StyledResultDetailNavigation>
				<Button type="button" onClick={onBack} variant={ButtonVariants.GHOST}>
					<img src={ArrowBackRounded} alt={TEXT.RESULT_DETAIL.NAV.BACK} />
					{TEXT.RESULT_DETAIL.NAV.BACK}
				</Button>
				{itemsLength > 1 && (
					<>
						<Button
							type="Button"
							onClick={() => onNavigate(-1)}
							variant={ButtonVariants.GHOST}
						>
							<img src={ArrowBackIosRounded} alt={TEXT.RESULT_DETAIL.NAV.PREV} />
						</Button>
						<Button
							type="Button"
							onClick={() => onNavigate(1)}
							variant={ButtonVariants.GHOST}
						>
							<img src={ArrowForwardIosRounded} alt={TEXT.RESULT_DETAIL.NAV.NEXT} />
						</Button>
					</>
				)}
			</StyledResultDetailNavigation>

			<StyledResultListDetail>
				{item.icon && <img src={item.icon} alt={item.title} />}
				<h3>{item.title}</h3>
				<div>
					{item.subtitle && <div className='subtitle'>{item.subtitle}</div>}
					<div dangerouslySetInnerHTML={{ __html: item.text }} />
				</div>
				{item.link && (
					<a target="_blank" href={item.link.href} rel="noopener noreferrer">
						<Button type="button" variant={ButtonVariants.PRIMARY}>
							{item.link.text}
						</Button>
					</a>
				)}
			</StyledResultListDetail>
		</StyledResultDetailWrapper>
	);
}

ResultDetail.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		text: PropTypes.string.isRequired,
		icon: PropTypes.string.isRequired,
		link: PropTypes.shape({
			href: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	}).isRequired,

	onNavigate: PropTypes.func.isRequired,
	onBack: PropTypes.func.isRequired,
	itemsLength: PropTypes.number.isRequired,
};
