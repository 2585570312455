import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import styled from 'styled-components';

import SearchRounded from '../../assets/icons/SearchRounded.svg';
import Close from '../../assets/icons/close.svg';
import { StyledLabel } from './styled';
import { TEXT } from '../text';

import {
	IndicatorSeparator,
	SingleValue,
	StyledOption,
	StyledSelectImage,
	IndicatorsContainer,
} from './StyledSelectComponents';

const StyledSearchBar = styled.div`
	display: grid;
	grid-template-areas:
		'label'
		'select';
	gap: var(--size_x05, 8px);
	grid-area: search;
`;

const StyledImage = styled.img`
	margin: var(--size_x05, 8px);
	width: var(--size_x15, 24px);
	height: var(--size_x15, 24px);
	transition: opacity 250ms ease-in-out;
`;

const StyledSelect = styled(ReactAsyncSelect)`
	margin: 0;

	[class$='control'] {
		border: var(--border, 1px solid #d9d9d9);
		background-color: var(--GreyShade1, #fafafa);
	}
	[class$='IndicatorsContainer'] {
		display: none;
	}
	[class$='placeholder'] {
		color: var(--TrueBlack_02, #00000033);
	}
	:focus-within [class$='SingleValue'] {
		opacity: 0;
	}
`;

// the extra class is here for a weird bug in the builded app
// this class needs to overwrite default styling
// in the build process sometimes class names from the react-select vanished this is the solution
const StyledControl = styled(components.Control)`
	&.styled-control {
		cursor: pointer;
		max-width: calc(380px - 24px); // width - padding
		@media (min-width: 1280px) {
			max-width: calc(460px - 24px); // width - padding
		}
	}
`;

/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
function Control({ children, ...props }) {
	const style = useMemo(
		() => ({
			opacity: props?.hasValue || props?.selectProps?.inputValue ? 1 : 0,
			pointerEvents: props?.hasValue || props?.selectProps?.inputValue ? 'initial' : 'none',
		}),
		[props?.hasValue, props?.selectProps?.inputValue],
	);

	return (
		<StyledControl {...props} className="styled-control">
			<StyledImage src={SearchRounded} alt={TEXT.SEARCH_BAR.BUTTON.SEARCH} />
			{children}
			<StyledImage
				src={Close}
				alt={TEXT.SEARCH_BAR.BUTTON.RESET}
				style={style}
				onClick={props.clearValue}
			/>
		</StyledControl>
	);
}

export function Option({ children, ...props }) {
	return (
		<StyledOption {...props} isSelected className="styled-option">
			{props.data.icon && <StyledSelectImage src={props.data.icon} alt={props.data.label} />}
			{children}
		</StyledOption>
	);
}
/* eslint-enable react/prop-types */

/**
 * @typedef {Object} SearchOption
 * @property {string} key
 * @property {string} label
 * @property {string} value
 * @property {string} icon
 */

/**
 * @param {Object} props
 * @param {function(SearchOption): void} props.onChange
 * @param {function(string): Promise<Array<SearchOption>>} props.loadOptions
 * @returns {React.Element<{}>}
 */
export function SearchBar({ onChange, loadOptions }) {
	return (
		<StyledSearchBar>
			<StyledLabel>{TEXT.SEARCH_BAR.LABEL}</StyledLabel>
			<StyledSelect
				placeholder={TEXT.SEARCH_BAR.PLACE_HOLDER}
				loadOptions={loadOptions}
				onChange={onChange}
				onSelect={onChange}
				noOptionsMessage={() => null}
				loadingMessage={() => TEXT.SEARCH_BAR.LOADING}
				components={{
					Control,
					Option,
					SingleValue,
					IndicatorSeparator,
					IndicatorsContainer,
				}}
			/>
		</StyledSearchBar>
	);
}

SearchBar.propTypes = {
	loadOptions: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};
