import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
	grid-column: 1 / 2;
	grid-row: 3 / 4;
	text-align: center;
`;

const Button = styled.button`
	background-color: #fff;
	color: #4f5b5f;
	padding: 4px 6px;
	text-decoration: none;

	${({ noBorder }) =>
		noBorder
			? css`
					border: 0;
			  `
			: css`
					border: 1px solid #4f5b5f;
					border-radius: 3px;
			  `}

	&:active,
	&:focus,
	&:hover {
		border-color: #000;
		color: #000;
		cursor: pointer;
	}
`;

export default Button;
