import React, { useContext } from 'react';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

import vvsLogo from '../../assets/vvs_logo.svg';
import MarkerContext from '../../contexts/MarkerContext';

const IconLink = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 40px;
	background-color: #fff;
	z-index: 100;

	${media.md`
		width: 64px;
	`}

	${media.lg`
		width: 80px;
		height: 60px;
	`}
`;

const IconImg = styled.img`
	display: block;
	width: auto;
	height: 32px;

	${media.lg`
		height: 40px;
	`}
`;

export default function() {
	const markerContext = useContext(MarkerContext);
	return (
		<IconLink
			href="#"
			onClick={e => {
				e.preventDefault();
				markerContext.setContext({
					filters: null,
					marker: null,
				});
			}}
			tabIndex={20}
		>
			<IconImg src={vvsLogo} alt="VVS Icon" />
		</IconLink>
	);
}
