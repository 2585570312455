import React from 'react';
import ReactDOM from 'react-dom';
import { fetchers, IconParkAndRide } from 'shared';
import TextBlockLoadingIndicator from '../../../TextBlockLoadingIndicator';

export default function renderParkAndRideTooltip(marker, tooltip) {
	try {
		// replace inner content of Tooltip with loaded data
		fetchers.fetchParkObjectById(marker.vvsId).then(details => {
			if (details === false) {
				return;
			}

			// tooltip.classList.add('pr-data-loaded');

			const {
				realtime,
				chargeTimes,
				dataSet,
				NumberOfParkingPlaces,
				occupancy,
				cd, // Parking price per day
				cm, // Parking price per month
				chy, // Parking price per half a year
				stopPdf,
			} = details.properties;

			// trim 1-1-1- -> otherwise false id link to fornsbach
			// Only use last number of id as details id
			const furtherDetailsId = details.id.match(/\d+$/)[0];

			const hasFreePlacesInfo =
				realtime === true && dataSet && typeof dataSet.freePlaces !== 'undefined';

			const innerContent = document.getElementById(
				`pr-tooltip-inner-content-${marker.vvsId}`,
			);
			if (!innerContent) {
				return;
			}
			const innerLoadedContent = document.createDocumentFragment();
			innerContent.innerHTML = '';

			ReactDOM.render(
				<>
					<p>
						<strong>{hasFreePlacesInfo && 'Freie '}Plätze:</strong>{' '}
						{hasFreePlacesInfo && (
							<>
								<strong>{dataSet.freePlaces}</strong>
								{' von '}
							</>
						)}
						{NumberOfParkingPlaces}
					</p>
					{occupancy && (
						<p>
							<strong>Auslastung zu Spitzenzeiten</strong>
							<br />
							{occupancy} %
						</p>
					)}
					{chargeTimes && (
						<p>
							<strong>Gebührenpflichtige Zeiten</strong>
							<br />
							{chargeTimes}
						</p>
					)}
					{(cd || cm || chy) && (
						<table>
							<thead>
								<tr>
									<th colSpan="2">Gebühren</th>
								</tr>
							</thead>
							<tbody>
								{cd && (
									<tr>
										<td>Tagesmarke</td>
										<td dangerouslySetInnerHTML={{ __html: cd }} />
									</tr>
								)}
								{cm && (
									<tr>
										<td>Monatsmarker</td>
										<td dangerouslySetInnerHTML={{ __html: cm }} />
									</tr>
								)}
								{chy && (
									<tr>
										<td>Halbjahresmarke</td>
										<td dangerouslySetInnerHTML={{ __html: chy }} />
									</tr>
								)}
							</tbody>
						</table>
					)}
					<hr />
					<p>
						{stopPdf && (
							<>
								<a
									href={`https://www3.vvs.de/Download/EnvMaps/vvs/${stopPdf}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									Haltestellenkarte
								</a>
								<br />
							</>
						)}
						<a
							href={`https://www.vvs.de/parkride/?cp=${furtherDetailsId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							Weitere Informationen
						</a>
					</p>
				</>,
				innerLoadedContent,
			);
			innerContent.appendChild(innerLoadedContent);
		});
	} catch (error) {
		// console.log('error', error);
	}
	ReactDOM.render(
		<div className="marker--pr">
			<div className="marker__tooltip" data-id={marker.vvsId}>
				<h2 className="tooltipcontent__title">
					<IconParkAndRide />
					{marker.vvsTitle}
				</h2>
				<div
					id={`pr-tooltip-inner-content-${marker.vvsId}`}
					className="tooltipcontent__content"
				>
					<TextBlockLoadingIndicator rows={1} />
					<TextBlockLoadingIndicator rows={1} />
				</div>
			</div>
		</div>,
		tooltip,
	);
}
