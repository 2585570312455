import { fetchers, createMarker, getLayerByName } from 'shared';

import { containsCoordinate } from 'ol/extent';
import { fromLonLat, toLonLat } from 'ol/src/proj';
import Point from 'ol/geom/Point';

import { getBottomRight, getTopLeft, buffer } from 'ol/src/extent';
import { getVectorContext } from 'ol/render';

import { calculateDistance } from 'shared';
import LineString from 'ol/geom/LineString';

import renderTooltip from './Tooltips/renderTooltip';
import { mapConfig } from '../../../utils/config';

let vehicleMarkers = [];
let positionsTimeout;
let positionFilters;
let intervals = [];
let isAnimating = false;
const markerAnimations = {};
const markerTimeouts = {};
let performanceLast = 0;
let isFpsCounting = false;
let counter = 0;
const twoMinsInMilliseconds = 120000;

export const clearPositionsTimeout = () => {
	clearTimeout(positionsTimeout);
};

export const setPositionsTimeout = (map, filters, setMarkers, time) => {
	clearPositionsTimeout();
	positionsTimeout = setTimeout(() => {
		updatePositions(map, filters, setMarkers, true);
	}, time);
};

// marker styles are set in ../index.js transportationLayer.setStyle
// text and icon styles
const updatePositions = async (map, filters = {}, setMarkers = () => {}, fromTimeout = false) => {
	const transportationLayer = getLayerByName(map, 'transportation');
	clearPositionsTimeout();

	positionFilters = filters;
	// only start fps counter once
	if (!isFpsCounting) {
		animate();
	}
	let fps = 60;

	function animate() {
		isFpsCounting = true;
		const t0 = performanceLast;
		const t1 = performance.now();
		// console.log('fps', fps);
		counter += 1;
		// only get fps every 100 miliseconds for mor stable numbers
		if (counter > 100) {
			counter = 0;
			fps = (1 / (t1 - t0)) * 1000;
			fps = Math.round(fps);
		}
		performanceLast = t1;
		requestAnimationFrame(animate);
	}
	// stop animation after zoomlevel 14.3
	// stop animation if fps is too low
	if (map.getView().getZoom() < 14.3 || fps < 10) {
		isAnimating = false;
		Object.keys(markerAnimations).forEach(key => {
			if (markerAnimations[key]) {
				transportationLayer.un('postrender', markerAnimations[key].listener);
				delete markerAnimations[key];
			}
		});
	} else {
		isAnimating = true;
	}

	// if zoomed out reset all markers
	if (map.getView().getZoom() < mapConfig.minZoomLevel.positions + mapConfig.minZoom) {
		setMarkers([]);
		return;
	}

	const extent = map.getView().calculateExtent();
	// get vehicles just outside for smoother in and out animation
	const bufferedExtent = buffer(extent, 1000, [0, 0, 0, 0]);
	const topLeft = toLonLat(getTopLeft(bufferedExtent));
	const bottomRight = toLonLat(getBottomRight(bufferedExtent));
	let positions;

	if (Object.keys(positionFilters).filter(entry => positionFilters[entry] > 0).length) {
		const projectedExtend = {
			top: topLeft[1],
			left: topLeft[0],
			bottom: bottomRight[1],
			right: bottomRight[0],
		};
		positions = await fetchers.fetchPositions(projectedExtend, positionFilters);
		if (positions.length <= 2 || !fromTimeout) {
			setPositionsTimeout(map, positionFilters, setMarkers, 1000);
		} else {
			setPositionsTimeout(map, positionFilters, setMarkers, 5000);
		}
	} else {
		positions = [];
	}

	if (fromTimeout && positions.length === 0) {
		return;
	}

	vehicleMarkers = vehicleMarkers.filter((marker, index) => {
		// check old markers, if they are still in visible area
		let isVisible = containsCoordinate(bufferedExtent, marker.lonlat);
		// check if marker wasn't updated the last 2 mins
		if (Date.now() - marker.lastMoved > twoMinsInMilliseconds) {
			isVisible = false;
		}
		// remove markers if filter for type is not set
		if (positionFilters[marker.vvsPositionType] === 0) {
			isVisible = false;
		}
		// remove animation function if set and marker got removed
		if (!isVisible && markerAnimations[index]) {
			transportationLayer.un('postrender', markerAnimations[index].listener);
			delete markerAnimations[index];
		}
		return isVisible;
	});

	// Stop further processing, if filters are all disabled
	if (Object.keys(positionFilters).filter(entry => positionFilters[entry] > 0).length === 0) {
		setMarkers([]);
		return;
	}

	const changes = {
		old: 0,
		new: 0,
	};

	intervals.forEach(interval => {
		clearInterval(interval);
	});
	intervals = [];
	positions.forEach(position => {
		const existingMarkerIndex = vehicleMarkers.findIndex(
			marker => marker.id === position.journeyIdentifier,
		);
		const isExistingMarker = existingMarkerIndex > -1;
		let marker;
		const coords = fromLonLat([position.longitude, position.latitude]);
		if (isExistingMarker) {
			changes.old += 1;
			marker = vehicleMarkers[existingMarkerIndex];

			// update marker position with new data
			const newPos = new Point(marker.lonlat);
			marker.setGeometry(newPos);
			const oldPos = marker.lonlat;
			const newLonLat = fromLonLat([position.longitude, position.latitude]);
			const startTime = Date.now();
			// use LineString to get coordinates between new and old pos
			const route = new LineString([oldPos, newLonLat]);
			if (markerAnimations[existingMarkerIndex]) {
				// transportationLayer -> markerAnimations[existingMarkerIndex].target
				// moveFeature -> markerAnimations[existingMarkerIndex].listener
				transportationLayer.un(
					'postrender',
					markerAnimations[existingMarkerIndex].listener,
				);
				delete markerAnimations[existingMarkerIndex];
			}
			if (markerTimeouts[existingMarkerIndex]) {
				clearTimeout(markerTimeouts[existingMarkerIndex]);
				delete markerTimeouts[existingMarkerIndex];
			}
			if (!isAnimating) {
				// eslint-disable-next-line prefer-const
				let frac = 1;
				startTimeout();
				// eslint-disable-next-line no-inner-declarations
				function startTimeout() {
					const lerpedPos = route.getCoordinateAt(frac);
					marker.setGeometry(new Point(lerpedPos));
					marker.lonlat = lerpedPos;
				}
			} else {
				const animationKey = transportationLayer.on('postrender', moveFeature);
				markerAnimations[existingMarkerIndex] = animationKey;
				// console.log('markerAnimations', markerAnimations);
				const timeout = 10000; // 10s in miliseconds
				let animationLimit = 0;
				if (map.getView().getZoom() < 16) {
					animationLimit = 300;
				}
				let lastTime = Date.now();
				let lastLerpedPos = oldPos;
				// animation moves feature to new location smoothly
				// eslint-disable-next-line no-inner-declarations
				function moveFeature(event) {
					const currentTime = Date.now();
					const timer = currentTime - startTime;
					//skip animation lerp if animationLimit ist set
					//less smooth animation but better performances
					if (currentTime - lastTime < animationLimit) {
						// tell OpenLayers to continue the postrender animation
						newPos.setCoordinates(lastLerpedPos);
						const vectorContext = getVectorContext(event);
						vectorContext.drawGeometry(newPos);
						map.render();
						return;
					}
					const frac = timer / timeout;
					// if the lerp reaches its end stop -> otherwise markers move further away
					if (frac > 1) {
						transportationLayer.un(
							'postrender',
							markerAnimations[existingMarkerIndex].listener,
						);
						delete markerAnimations[existingMarkerIndex];
						return;
					}
					//check if lerp is too far from original pos and skip
					const lerpedPos = route.getCoordinateAt(frac);
					const lerpedPosLonLat = toLonLat(lerpedPos);
					const oldPosLonLat = toLonLat(oldPos);
					const lerpedDistance = calculateDistance(
						lerpedPosLonLat[1],
						lerpedPosLonLat[0],
						oldPosLonLat[1],
						oldPosLonLat[0],
					);
					//if last regular pos and new pos are more than 0.6 km skip lerp
					//set pos to newPos directly
					if (lerpedDistance > 0.6) {
						newPos.setCoordinates(newLonLat);
						const vectorContext = getVectorContext(event);
						vectorContext.drawGeometry(newPos);
						marker.lonlat = newLonLat;
						transportationLayer.un(
							'postrender',
							markerAnimations[existingMarkerIndex].listener,
						);
						delete markerAnimations[existingMarkerIndex];
						return;
					}
					lastLerpedPos = lerpedPos;
					newPos.setCoordinates(lerpedPos);
					const vectorContext = getVectorContext(event);
					vectorContext.drawGeometry(newPos);
					marker.lonlat = lerpedPos;
					// move tooltip as well
					if (marker.get('hovered') === true) {
						const tooltipName = marker.get('tooltip');
						if (tooltipName !== '') {
							map.getOverlayById(tooltipName).setPosition(
								marker.getGeometry().getCoordinates(),
							);
						}
					}
					lastTime = Date.now();
					// tell OpenLayers to continue the postrender animation
					map.render();
				}
			}
			// set marker data to new updated delay
			marker.set('delay', position.delay);
			marker.lastMoved = Date.now();
		} else {
			if (!containsCoordinate(bufferedExtent, coords)) {
				return;
			}

			changes.new += 1;
			let state = 2;
			state = filters[`${position.type}`];
			// eslint-disable-next-line no-param-reassign
			position.coords = [position.longitude, position.latitude];
			marker = createMarker(position, {
				type: position.type,
				id: position.journeyIdentifier,
				vvsId: `position-${position.journeyIdentifier}`,
				state,
				canHover: true,
				canClick: true,
				tooltip: '',
				delay: position.delay,
				realtime: position.realtime,
				onHover: () => {
					renderTooltip(
						'position',
						marker,
						map,
						position,
						'tooltip',
						state,
						[21, -15],
						null,
						null,
					);
				},
				onClick: () => {
					if (marker.get('state') === 3) {
						// return;
					}
				},
			});
			marker.vvsType = 'position';
			marker.vvsPositionType = position.type;
			marker.vvsId = `position-${position.journeyIdentifier}`;
			marker.id = position.journeyIdentifier;
			marker.oldid = position.id;
			marker.lonlat = fromLonLat([position.longitude, position.latitude]);
			marker.lastMoved = Date.now();
			vehicleMarkers.push(marker);
		}
	});
	setMarkers(vehicleMarkers);
};

export default updatePositions;
