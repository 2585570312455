/**
 * A class that manages tabbing through tabbable elements in an application.
 */
class TabManager {
	/**
	 * Constructs a new TabManager instance.
	 */
	constructor() {
		/**
		 * @private
		 * @type {HTMLElement[]}
		 * */
		this.tabbableElements = [];
		/**
		 * @private
		 * @type {Element | Document}
		 * */
		this.tabRoot = document;
		/**
		 * @private
		 * @type {number}
		 * */
		this.currentTabIndex = -1;
		/**
		 * @private
		 * @type {(event: InputEvent) => void}
		 * */
		this.handleTabKey = this.handleTabKey.bind(this);
		/**
		 * @private
		 * @type {(event: FocusEvent) => void}
		 * */
		this.handleBlur = this.handleBlur.bind(this);
		/**
		 * @private
		 * @type {(event: FocusEvent) => void} #
		 * */
		this.handleFocus = this.handleFocus.bind(this);

		// Initialize tabbing and prevent default tab behavior after the class is created
		this.reinitialize();
		document.addEventListener('keydown', this.handleTabKey);
	}

	/**
	 * Checks if an element is currently visible in the viewport.
	 * @private
	 * @param {HTMLElement} element - The element to check for visibility.
	 * @returns {boolean} - Returns true if the element is visible, otherwise false.
	 */
	isElementVisible(element, scrolled = false) {
		const rect = element.getBoundingClientRect();
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;
		const windowWidth = window.innerWidth || document.documentElement.clientWidth;
		const isVisible =
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= windowHeight &&
			rect.right <= windowWidth;

		// if it is invisible, try to scroll it into the view
		if (!isVisible && !scrolled) {
			// eslint-disable-next-line
			element?.scrollIntoViewIfNeeded?.();
			return isVisible;
		}

		return isVisible;
	}

	/**
	 * Handles the keydown event for the Tab key, focusing on the next visible tabbable element.
	 * @private
	 * @param {KeyboardEvent} event - The keydown event.
	 */
	handleTabKey(event) {
		if (event.key === 'Tab') {
			event.preventDefault();

			if (this.tabbableElements.length === 0) return;

			const isShiftPressed = event.shiftKey;

			if (isShiftPressed) {
				this.focusPreviousVisibleElement();
			} else {
				this.focusNextVisibleElement();
			}
		}
	}

	/**
	 * Focuses on the next visible tabbable element.
	 * @private
	 */
	focusNextVisibleElement() {
		this.currentTabIndex = (this.currentTabIndex + 1) % this.tabbableElements.length;
		this.focusVisibleElement();
	}

	/**
	 * Focuses on the previous visible tabbable element.
	 * @private
	 */
	focusPreviousVisibleElement() {
		this.currentTabIndex =
			(this.currentTabIndex - 1 + this.tabbableElements.length) %
			this.tabbableElements.length;
		this.focusVisibleElement();
	}

	/**
	 * Focuses on the next visible tabbable element by checking visibility.
	 * @private
	 */
	focusVisibleElement() {
		let nextVisibleElement = this.tabbableElements[this.currentTabIndex];
		const initialTabIndex = this.currentTabIndex;

		while (nextVisibleElement && !this.isElementVisible(nextVisibleElement)) {
			this.currentTabIndex = (this.currentTabIndex + 1) % this.tabbableElements.length;
			nextVisibleElement = this.tabbableElements[this.currentTabIndex];

			// If we looped through all elements and none are visible, break the loop.
			if (this.currentTabIndex === initialTabIndex) break;
		}

		// If a visible element is found, focus on it.
		if (nextVisibleElement) {
			nextVisibleElement.focus();
		}
	}

	/**
	 * Handles the blur event for tabbable elements, resetting the `currentTabIndex`.
	 * @private
	 */
	handleBlur() {
		this.currentTabIndex = -1;
	}

	/**
	 * Handles the focus event for tabbable elements, setting the `currentTabIndex` to the focused element.
	 * @private
	 */
	handleFocus(event) {
		const focusedElement = event.target;
		this.currentTabIndex = this.tabbableElements.indexOf(focusedElement);
	}

	/**
	 * Reinitializes tabbing by finding tabbable elements and registering the event listeners for the Tab key, blur, and focus events.
	 * @param {Element | Document} [tabRoot=document] - The root of the tabs, defaults to document
	 */
	reinitialize(tabRoot) {
		this.clearTabbableElements();

		if (tabRoot) this.setTabRoot(tabRoot);
		this.findTabbableElements();
		this.setupTabbableElements();

		this.currentTabIndex = -1;
	}

	/**
	 * Clears the existing tabbable elements and removes event listeners.
	 * @private
	 */
	clearTabbableElements() {
		this.tabbableElements.forEach(element => {
			element.removeEventListener('blur', this.handleBlur);
			element.removeEventListener('focus', this.handleFocus);
		});
		this.tabbableElements = [];
	}

	/**
	 * Finds tabbable elements in the DOM based on the `data-tabindex` attribute and populates the `tabbableElements` array.
	 * @private
	 */
	findTabbableElements() {
		const elements = this.tabRoot.querySelectorAll('[data-tabindex]');
		this.tabbableElements = Array.from(elements).sort((a, b) => {
			const indexA = parseInt(a.getAttribute('data-tabindex'), 10);
			const indexB = parseInt(b.getAttribute('data-tabindex'), 10);
			return indexA - indexB;
		});
	}

	/**
	 * Sets up tabindex and event listeners for the tabbable elements.
	 * @private
	 */
	setupTabbableElements() {
		this.tabbableElements.forEach((element, index) => {
			element.setAttribute('tabindex', index + 1);
			element.addEventListener('blur', this.handleBlur);
			element.addEventListener('focus', this.handleFocus);
		});
	}

	/**
	 * Stops tabbing by removing the event listeners for the Tab key, blur, and focus events.
	 * @private
	 */
	stopTabbing() {
		document.removeEventListener('keydown', this.handleTabKey);
		this.clearTabbableElements();
		this.currentTabIndex = -1;
	}

	/**
	 * Set the tabRoot element
	 * @param {Element | Document} [tabRoot=document] - The root of the tabs, defaults to document
	 */
	setTabRoot(tabRoot) {
		if (!tabRoot || !(tabRoot instanceof Element || tabRoot instanceof Document)) {
			throw Error('tabRoot is not an instance of HTMLElement');
		}

		this.tabRoot = tabRoot;
		this.currentTabIndex = -1;
	}
}

export const tabManager = new TabManager();
window.tabManager = tabManager;
