import { getBottomLeft, getTopRight } from 'ol/src/extent';
import { toLonLat } from 'ol/src/proj';
import { fetchers, createMarker, nameToIcon } from 'shared';

import { poiCategories, mapConfig } from '../../../utils/config';
import renderTooltipSelected from './Tooltips/renderTooltipSelected';
import renderTooltip from './Tooltips/renderTooltip';

let latestCategories;

let lastBottomLeft = [-1, -1];
let lastTopRight = [-1, -1];
let lastActiveMarker;

export default async (
	map,
	categories = null,
	setPoiMarkers = () => {},
	activeMarker = null,
	setClickedMarker = () => {},
) => {
	const extent = map.getView().calculateExtent();
	const bottomLeft = toLonLat(getBottomLeft(extent));
	const topRight = toLonLat(getTopRight(extent));

	let isCategoriesChanged = false;
	//check if any key in last categories differs from new categories
	if (latestCategories) {
		Object.keys(categories).forEach(key => {
			if (categories[key] !== latestCategories[key]) {
				isCategoriesChanged = true;
			}
		});
	} else {
		isCategoriesChanged = true;
	}
	//check if any params or filters or markers were changed
	//if not don't fetch again and don't set the updated flag
	if (
		activeMarker === lastActiveMarker &&
		lastTopRight[0] === topRight[0] &&
		lastBottomLeft[0] === bottomLeft[0] &&
		lastTopRight[1] === topRight[1] &&
		lastBottomLeft[1] === bottomLeft[1] &&
		!isCategoriesChanged
	) {
		return;
	}
	lastTopRight = topRight;
	lastBottomLeft = bottomLeft;
	lastActiveMarker = activeMarker;
	latestCategories = categories;

	//check if zoomed out
	if (map.getView().getZoom() < mapConfig.minZoomLevel.pois + mapConfig.minZoom) {
		let markersArray = [];
		//check if selected marker isn't null and already generated marker
		//if true keep selected marker on map
		if (lastActiveMarker && lastActiveMarker.vvsId) {
			markersArray.push(lastActiveMarker);
		}
		setPoiMarkers(markersArray);
		return;
	}

	const usedCategories = Object.keys(latestCategories)
		.filter(category => latestCategories[category] > 0)
		.map(category => poiCategories[category]);

	//if no used categories return
	if (usedCategories.length <= 0) {
		setPoiMarkers([]);
		return;
	}

	let pois = await fetchers.fetchPOIsByArea(
		bottomLeft[1],
		bottomLeft[0],
		topRight[1],
		topRight[0],
		usedCategories,
	);
	let activeMarkerId = null;
	if (lastActiveMarker !== null) {
		activeMarkerId = lastActiveMarker.id;
	}

	pois = pois.filter(poi => parseInt(latestCategories[poi.category], 10) > 0);
	const poiMarkers = [];

	pois.forEach(poi => {
		let state = parseInt(categories[poi.category], 10);
		//if a marker is active
		if (activeMarkerId === poi.id) {
			state = 3;
		}
		const marker = createMarker(poi, {
			type: `poi_${nameToIcon(poi.category)}`,
			vvsId: poi.id,
			id: poi.id,
			state: state,
			name: poi.name,
			canHover: true,
			canClick: true,
			onHover: () => {
				renderTooltip('poi', marker, map, poi, 'tooltip', state, [0, 0], null, null);
			},
			onClick: () => {
				renderTooltipSelected(marker, map, [0, 0], setClickedMarker);
			},
		});
		if (activeMarkerId === poi.id) {
			renderTooltip('poi', marker, map, poi, 'tooltip-selected', 2, [0, 0], null, null);
		}

		marker.vvsType = 'poi';
		marker.vvsCategory = poi.category;
		marker.category = poi.category;
		marker.vvsId = poi.id;
		marker.id = poi.id;
		marker.name = poi.name;
		marker.parent = poi.parent;
		marker.coord = poi.coord;
		poiMarkers.push(marker);
	});
	if (poiMarkers.length > 0) poiMarkers[0].vvsUpdate = true;
	setPoiMarkers(poiMarkers);
};
