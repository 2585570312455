import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MenuHeader } from './MenuHeader';
import { SearchBar } from './Inputs/SearchBar';
import { DatePicker } from './Inputs/DatePicker';
import { Select } from './Inputs/Select';
import { Result } from './Result';
import { MultiSelect } from './Inputs/MultiSelect';
import { TEXT } from './text';

const StyledMenu = styled.div`
	--size_x1: 16px;
	--size_x025: calc(var(--size_x1) * 0.25); // 4px
	--size_x05: calc(var(--size_x1) * 0.5); // 8px
	--size_x075: calc(var(--size_x1) * 0.75); // 12px
	--size_x0875: calc(var(--size_x1) * 0.875); // 12px
	--size_x15: calc(var(--size_x1) * 1.5); // 24px
	--size_x2: calc(var(--size_x1) * 2); // 32px

	--GreyShade3_02: #d9d9d933;
	--TrueBlack_01: #0000001a;
	--TrueBlack_02: #00000033;
	--TrueBlack_03: #0000004d;

	--border: 1px solid var(--GreyShade3, #d9d9d9);

	border-radius: var(--size_x025);
	width: 100%;

	display: grid;

	> *:not(:last-child) {
		border-bottom: var(--border);
	}

	background-color: var(--GreyShade1, #fafafa);
	box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
	font-size: var(--size_x0875, 14px);
`;

const StyledSearchAndFilter = styled.div`
	display: grid;
	grid-template-areas:
		'search search'
		'date type'
		'transport .';
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	gap: var(--size_x075);
	padding: var(--size_x075);
	position: relative;
`;

export function Menu({
	title,
	onCloseClick,
	loadOptions,
	onSearchChange,
	dateValue,
	dateOnChange,
	typeValue,
	typeOptions,
	typeOnChange,
	transportValue,
	transportOptions,
	transportOnChange,
	items,
	onSelectItem,
	onSortChange,
	onResetClick,
	selectedSortOption,
	sortOptions,
	className = '',
}) {
	return (
		<StyledMenu className={className}>
			<MenuHeader title={title} onCloseClick={onCloseClick} />
			<StyledSearchAndFilter>
				<SearchBar loadOptions={loadOptions} onChange={onSearchChange} />
				<DatePicker value={dateValue} onChange={dateOnChange} />
				<MultiSelect
					label={TEXT.TYPE_SELECT.LABEL}
					value={typeValue}
					options={typeOptions}
					onChange={typeOnChange}
				/>
				<MultiSelect
					label={TEXT.TRANSPORT_SELECT.LABEL}
					value={transportValue}
					options={transportOptions}
					onChange={transportOnChange}
				/>
			</StyledSearchAndFilter>
			{items.length > 0 && (
				<Result
					items={items}
					selectedSortOption={selectedSortOption}
					sortOptions={sortOptions}
					onSortChange={onSortChange}
					onResetClick={onResetClick}
					onSelectItem={onSelectItem}
				/>
			)}
		</StyledMenu>
	);
}

Menu.propTypes = {
	className: PropTypes.string,

	title: PropTypes.string.isRequired,
	onCloseClick: PropTypes.func.isRequired,
	loadOptions: SearchBar.propTypes.loadOptions,
	onSearchChange: SearchBar.propTypes.onChange,

	dateValue: DatePicker.propTypes.value,
	dateOnChange: DatePicker.propTypes.onChange,

	typeValue: Select.propTypes.value,
	typeOptions: Select.propTypes.options,
	typeOnChange: Select.propTypes.onChange,

	transportValue: Select.propTypes.value,
	transportOptions: Select.propTypes.options,
	transportOnChange: Select.propTypes.onChange,

	items: Result.propTypes.items,
	onSelectItem: Result.propTypes.onSelectItem,
	onSortChange: Result.propTypes.onSortChange,
	onResetClick: Result.propTypes.onResetClick,
	selectedSortOption: Result.propTypes.selectedSortOption,
	sortOptions: Result.propTypes.sortOptions,
};
