/* 3rd party imports */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* Custom imports */
import { Icons, disruptionsClassifier } from 'shared';
import media from '../../utils/Breakpoints';
import { colors, boxshadow } from '../../utils/config';

export const StyledCategoryItemDisruptionDetail = styled.div`
	display: flex;
	width: 100%;
	margin: 8px auto 8px auto;
	padding: 32px;
	padding-left: 0;
	border-radius: 5px;
	z-index: 20;

	${media.md`
		position: relative;
		top: auto;
		left: auto;
		width: 358px;
		margin-top: 0;
		border: 1px solid ${colors.graylight};
		box-shadow: ${boxshadow.default};
		background-color: ${colors.white};
	`}

	${media.lg`
		width: 458px;
		margin-bottom: 48px;
	`}
`;

const BackButton = styled.button`
	display: block;
	width: 62px;
	height: 62px;
	border: 0;
	user-select: none;
	background-image: url("data:image/svg+xml,%3Csvg width='15' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.377 2.63L2.13 12.878l10.246 10.246' stroke='%23097fac' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;

	&:hover {
		cursor: pointer;
	}
`;
const PreviousButton = styled.button`
	display: block;
	width: 16px;
	height: 16px;
	border: 0;
	user-select: none;
	background-image: url("data:image/svg+xml,%3Csvg width='15' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.377 2.63L2.13 12.878l10.246 10.246' stroke='%23097fac' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;

	&:hover {
		cursor: pointer;
	}
`;
const NextButton = styled.button`
	display: block;
	width: 16px;
	height: 16px;
	border: 0;
	user-select: none;
	background-image: url("data:image/svg+xml,%3Csvg width='15' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.377 2.63L2.13 12.878l10.246 10.246' stroke='%23097fac' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
	transform: rotate(180deg);
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;

	&:hover {
		cursor: pointer;
	}
`;

const PreviousNextMarkerCounter = styled.p`
	width: 24px;
	text-align: center;
`;

const PreviousNextMarkerMenu = styled.div`
	display: flex;
	float: right;
	justify-items: center;
	align-items: center;
`;

const CardContentWrapper = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	flex-grow: 1;
	flex-shrink: 1;
`;

const StyledLineListItem = styled.span`
	display: inline-flex;
	align-items: center;

	> svg {
		flex-shrink: 0;
	}

	> span {
		font-size: 18px;
		font-weight: 700;
		margin-left: 4px;
		margin-right: 6px;
	}
`;

/** Renders an info card with details about a disruption */
function CategoryItemDisruptionDetail(props) {
	const {
		// isDisruption,
		// isTimetableChange,
		// icon,
		attachments,
		originalDisruption,
		subtitle,
		validFrom,
		validTo,
		lastModified,
		content,
		affectedLines,
		affectedStops,
		onClose,
		switchToPreviousMarker,
		switchToNextMarker,
		featureInClusterIndex,
	} = props;

	/**
	 * Util to render the lines
	 *
	 * Rendering the lines is complicated, because they need to be grouped
	 * by product and then sorted by number. Also, there can be duplicate
	 * entries.
	 */
	const LinesList = ({ lines = [] }) => {
		// First, group by product
		const linesByProduct =
			lines?.reduce((linesByProductAccumulator, line) => {
				const linesByProductUpdatedAccumulator = linesByProductAccumulator;
				// Create a list for each product
				// Add to existing list or create a new one
				// Do not add duplicates
				if (linesByProductUpdatedAccumulator[line.product.id]) {
					if (!linesByProductUpdatedAccumulator[line.product.id].includes(line.number)) {
						linesByProductUpdatedAccumulator[line.product.id].push(line.number);
					}
				} else {
					linesByProductUpdatedAccumulator[line.product.id] = [line.number];
				}
				// Sort the updated list (the lists are short, so sorting it multiple times doesn't cost much)
				linesByProductUpdatedAccumulator[line.product.id].sort();
				return linesByProductUpdatedAccumulator;
			}, {}) || {};

		/** Maps product Icons to Components */
		const productIdIconMap = {
			0: Icons.sbahn, // 'sbahn'
			1: Icons.rbahn, // 'rbahn'
			2: Icons.ubahn, // 'ubahn'
			3: Icons.zacke, // 'zahnradbahn'
			4: Icons.seilbahn, // 'seilbahn'
			5: Icons.bus, // 'bus'
			6: Icons.bus, // 'nachtbus'
			8: Icons.taxi, // 'ruftaxi'
			16: Icons.rbahn, // 'ic'
		};

		/** Helper component that wraps the Icon component to render a product icon by product id */
		const ProductIcon = ({ productId, height, width }) =>
			React.createElement(productIdIconMap[productId], { height, width });
		ProductIcon.propTypes = {
			productId: PropTypes.string,
			height: PropTypes.number,
			width: PropTypes.number,
		};

		const products = Object.keys(linesByProduct);

		return (
			<p>
				{products.map(productId => (
					<StyledLineListItem key={productId}>
						<ProductIcon height={18} width={18} productId={productId} />
						<span
							style={{ fontSize: 18, fontWeight: 700, marginLeft: 4, marginRight: 6 }}
						>
							{linesByProduct[productId].join(', ')}
						</span>
					</StyledLineListItem>
				))}
			</p>
		);
	};
	LinesList.propTypes = {
		lines: PropTypes.arrayOf(PropTypes.object),
	};

	const makeDateFromToString = (from, to) => {
		if (from && to) {
			// If the end date is 31.12.2500, use "bis auf Weiteres" instead
			if (
				to.toLocaleDateString('de-DE', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				}) === '31.12.2500'
			) {
				return (
					<span>
						{`Vom ${validFrom.toLocaleDateString('de-DE', {
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
						})} bis auf Weiteres`}
					</span>
				);
			}
			return (
				<span>
					{`Vom ${validFrom.toLocaleDateString('de-DE', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					})} bis ${validTo.toLocaleDateString('de-DE', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					})}`}
				</span>
			);
		}
		return '';
	};

	return (
		<StyledCategoryItemDisruptionDetail>
			<BackButton onClick={onClose} />
			<CardContentWrapper>
				<PreviousNextMarkerMenu>
					{switchToPreviousMarker !== undefined && (
						<PreviousButton onClick={switchToPreviousMarker} />
					)}
					{(switchToPreviousMarker !== undefined || switchToNextMarker !== undefined) && (
						<PreviousNextMarkerCounter>
							{featureInClusterIndex + 1}
						</PreviousNextMarkerCounter>
					)}
					{switchToNextMarker !== undefined && (
						<NextButton onClick={switchToNextMarker} />
					)}
				</PreviousNextMarkerMenu>
				<p
					style={{
						fontSize: 14,
						marginBottom: 28,
					}}
				>
					{disruptionsClassifier.classify(originalDisruption)}
				</p>
				<LinesList lines={affectedLines} />
				{affectedStops && (
					<p
						style={{
							fontSize: 18,
							fontWeight: 700,
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}
					>
						<Icons.stop style={{ marginRight: 4 }} height={18} width={18} />{' '}
						{affectedStops.map(({ name }, index, { length }) => (
							<span key={`${name}-${index}`}>
								{name}
								{index < length - 1 ? ', ' : ''}
							</span>
						))}
					</p>
				)}
				<p style={{ fontSize: 18, marginTop: 12 }}>
					{makeDateFromToString(validFrom, validTo)}
					<br />
					<br />
					{subtitle}
				</p>
				<br />
				<br />
				{/* WARNING: We depend on the server giving us clean data. This cannot be safely sanitized on the client side. */}
				<div
					dangerouslySetInnerHTML={{
						__html: content,
					}}
				/>
				<br />
				<br />
				{attachments?.length > 0 && (
					<>
						{attachments?.map(({ linkText, path }, index) => (
							<a
								key={`${linkText}-${index}`}
								href={path}
								target="_blank"
								rel="noopener noreferrer"
							>
								{linkText}
							</a>
						))}
						<br />
						<br />
					</>
				)}
				<p style={{ fontSize: 18 }}>{`Stand: ${lastModified.toLocaleDateString('de-DE', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				})}`}</p>
			</CardContentWrapper>
		</StyledCategoryItemDisruptionDetail>
	);
}

CategoryItemDisruptionDetail.propTypes = {
	isDisruption: PropTypes.bool,
	isTimetableChange: PropTypes.bool,
	icon: PropTypes.string,
	subtitle: PropTypes.string,
	validFrom: PropTypes.instanceOf(Date),
	validTo: PropTypes.instanceOf(Date),
	lastModified: PropTypes.instanceOf(Date),
	content: PropTypes.string,
	affectedLines: PropTypes.arrayOf(PropTypes.object),
	affectedStops: PropTypes.arrayOf(PropTypes.object),
	onClose: PropTypes.func,
	originalDisruption: PropTypes.object,
	switchToPreviousMarker: PropTypes.func,
	switchToNextMarker: PropTypes.func,
	featureInClusterIndex: PropTypes.number,
	attachments: PropTypes.arrayOf(PropTypes.object),
};

export default CategoryItemDisruptionDetail;
